import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { User } from '../types/user.type';
import { AGENCY_URLS, BUILDING_URLS, CLIENT_TYPES, CLIENT_URLS } from "../constants/constants";
import { StorageService } from "./storage.service";
import {ApiService} from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  private userInfoSource = new BehaviorSubject<User | null>(null);
  private userPermissionsSource = new BehaviorSubject<any | null>(null);

  userInfo$ = this.userInfoSource.asObservable();
  userPermissions$ = this.userPermissionsSource.asObservable();

  constructor(private router: Router, private storage: StorageService, private apiService: ApiService) { }

  /**
   * Function to check if user is logged
   */
  public isAuthenticated(): boolean {
    const token = localStorage.getItem('jwtToken');
    return !!token;
  }

  /**
   * Function to determines if a route can be activated
   */
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (state.url === '/confirmation' || state.url === '/login' ) {
      return true;
    }

    const requiredSection = route.data['requiredSection'];
    const requiredPermission = route.data['requiredPermission'];

    this.loadUserState();

    if (!this.isAuthenticated()) {
      this.router.navigate(['login']);
      return false;
    }

    if (requiredSection && !this.hasPermission(requiredSection, requiredPermission)) {
      this.redirectToAppropriatePage();
      return false;
    }

    return true;
  }

  /**
   * Function to save user permissions
   * @param permissions
   */
  public setUserPermissions(permissions: any): void {
    this.userPermissionsSource.next(permissions);
    this.storage.setItem('userPermissions', JSON.stringify(permissions));
  }

  /**
   * Function to set user info
   * @param user - user object
   * @param clientType - client type
   * @param clientPid - client pid linked to user
   * @param agencyPid - agency pid linked to user
   */
  public setUserInfos(user: User, clientType?: string, clientPid?: string, agencyPid?: string): void {
    const userInfo = {
      ...user,
      client_type: clientType || user.client_type,
      client_pid: clientPid || user.client_pid,
      agency_pid: agencyPid || user.agency_pid
    }
    this.userInfoSource.next(userInfo);
    this.storage.setItem('userInfo', JSON.stringify(userInfo));
  }

  /**
   * Function to get user info
   */
  public getUserInfos(): any {
    return this.userInfoSource.value;
  }

  /**
   * Function to get the user permissions
   */
  public getUserPermissions(): any {
    return this.userPermissionsSource.value;
  }

  /**
   * Function to clear user infos
   */
  public clearUserInfos(): void {
    this.userInfoSource.next(null);
    this.userPermissionsSource.next(null);
  }

  /**
   * Function to check if user has permission to read or write in a section
   * @param section
   */
  public hasPermission(section: string, permission?: string): boolean {
    const permissions = this.getUserPermissions();
    if (!permissions || !permissions[section]) {
      return false;
    }
    if (permission) {
      return permissions[section] === permission;
    }
    return permissions[section] !== 'NONE';
  }

  /**
   * Function to redirect user to appropriate page
   */
  public redirectToAppropriatePage(): void {
    const userInfo = this.getUserInfos();

    if (!userInfo) {
      this.router.navigate(['/login']);
      return;
    }

    const { client_pid, client_type, agency_pid } = userInfo;

    // Si l'utilisateur est lié à des immeubles sans agence
    this.apiService.get(`users/${userInfo.user_pid}/buildings`).subscribe(response => {
      const buildings = response.data;

      if (buildings.length > 0) {
        // Si l'utilisateur est rattaché à des immeubles, redirige vers la page des immeubles
        const building = buildings[0]; // On prend le premier immeuble pour simplifier
        this.router.navigate([BUILDING_URLS.list], { queryParams: { client: building.client_pid, agency: building.agency_pid || null } });
      } else if (agency_pid) {
        this.router.navigate([BUILDING_URLS.list], { queryParams: { client: client_pid, agency: agency_pid } });
      } else if (client_pid && (client_type === CLIENT_TYPES.SYNDIC || client_type === CLIENT_TYPES.SOCIETE)) {
        this.router.navigate([AGENCY_URLS.list], { queryParams: { client: client_pid } });
      } else if (client_pid && client_type === CLIENT_TYPES.PARTICULIER) {
        this.router.navigate([BUILDING_URLS.list], { queryParams: { client: client_pid } });
      } else {
        this.router.navigate([CLIENT_URLS.list]);
      }
    });
  }


  /**
   * Function to load user state
   * @private
   */
  public loadUserState(): void {
    const userInfo = this.storage.getItem('userInfo');
    const userPermissions = this.storage.getItem('userPermissions');

    if (userInfo) {
      this.userInfoSource.next(JSON.parse(userInfo));
    }

    if (userPermissions) {
      this.userPermissionsSource.next(JSON.parse(userPermissions));
    }
  }
}
