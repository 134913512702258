<div class="account-container">
  <Sidebar></Sidebar>
  <div class="account-page padding">

  <!-- ACCOUNT PAGE TITLE -->
    <div>
      <TitleAtom size="large" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Mon compte"></TitleAtom>
    </div>

    <div class="account-create-container">
      <TitleAtom size="medium" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Modifier mes informations"></TitleAtom>

      <ul>
        <li>
          <div>
            <ParagraphAtom text="Prénom" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <input type="text" nbInput placeholder="Prénom..." [(ngModel)]="formData.user_firstName">
          </div>
          <div>
            <ParagraphAtom text="Nom" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <input type="text" nbInput placeholder="Nom..." [(ngModel)]="formData.user_lastName">
          </div>
        </li>
        <li class="form-email">
          <ParagraphAtom text="Email" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
          <input type="text" nbInput fullWidth placeholder="Email..." [(ngModel)]="formData.user_email">
          <ParagraphAtom *ngIf="!isEmailValid" text="L'email n'est pas valide" color="red" size="nano" weight="semi-bold"></ParagraphAtom>
        </li>
        <li>
          <button nbButton fullWidth (click)="this.router.navigate([ACCOUNT_URLS.password])">Modifier mon mot de passe</button>
        </li>
        <li>
          <button nbButton fullWidth status="primary" [disabled]="!isFormEmpty()" (click)="verifyForm()">Sauvegarder</button>
        </li>
        <li>
          <button nbButton fullWidth outline status="danger" (click)="signOut()">Déconnexion</button>
        </li>
      </ul>
    </div>
  </div>
</div>
