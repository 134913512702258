<button *ngIf="canViewComments()" class="comments-modal-open-btn" nbButton status="primary" (click)="openModal()">Voir les commentaires ({{ comments.length }})</button>

<ng-template #dialog let-data>
  <nb-card class="comments-modal">
    <nb-card-header class="comments-modal-header">
      <button nbButton outline status="danger" (click)="closeModal()">Fermer</button>
    </nb-card-header>
    <nb-card-body>
      <div class="comments-section" #commentsSection>
        <div *ngIf="comments.length > 0; else noComments">
          <div *ngFor="let comment of comments; let i = index" [attr.data-index]="i" #commentElement>
            <p><strong>{{ comment.user_firstName }} {{ comment.user_lastName }}</strong>: {{ comment.message }}</p>
            <small>{{ comment.created_at | date: 'short' }}</small>
          </div>
        </div>
        <ng-template #noComments>
          <p>Aucun commentaire</p>
        </ng-template>
      </div>
    </nb-card-body>
    <nb-card-footer class="comments-modal-footer" *ngIf="canWriteComments()">
      <input fullWidth nbInput placeholder="Votre commentaire" [(ngModel)]="newComment" />
      <button [disabled]="newComment === ''" nbButton status="primary" (click)="submitComment()">Envoyer</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
