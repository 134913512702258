import {Component, Input, OnInit} from '@angular/core';
import {APPOINTMENT_HEADERS, APPOINTMENT_URLS, DIAGNOSIS_TYPES} from "../../constants/constants";
import {Router} from "@angular/router";
import {AuthGuard} from "../../services/auth.guard";
import {Appointment} from "../../types/appointment.type";
import {ApiService} from "../../services/api.service";
import {NbDialogService} from "@nebular/theme";
import {DialogComponent} from "../../molecule/dialog/dialog.component";
import {User} from "../../types/user.type";
import { DarkModeService } from "../../services/darkmode.service";

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss']
})
export class AppointmentsComponent implements OnInit {

  // Path constant
  public readonly APPOINTMENT_URLS = APPOINTMENT_URLS;

  // Constant for appointment header
  public readonly APPOINTMENT_HEADERS = APPOINTMENT_HEADERS;

  // Constant for diagnoses type
  public readonly DIAGNOSIS_TYPES = DIAGNOSIS_TYPES;

  // Appointment list object
  public filteredAppointmentList: Appointment[] = [];

  // User to know if his admin or not
  private selectedUser: User | null = null;

  /* The appointments permissions */
  public appointmentsPermissions: boolean = false;

  // Appointments list objects
  @Input() appointmentList: Appointment[] = [];

  // Init filters
  public clientFilter: string = '';
  public buildingFilter: string = '';
  public appointmentDateFilter: Date | null = null;
  public emailFilter: string = '';
  public appointmentTypeFilter: string = '';
  public selectedStatus: string = '';

  constructor(public router: Router,
              public authGuard: AuthGuard,
              private dialogService: NbDialogService,
              private apiService: ApiService,
              public darkmodeService: DarkModeService,
  ) { }

  ngOnInit(): void {
    /* Get user permission to know if an admin */
    this.appointmentsPermissions = this.hasPermission('appointments');

    this.selectedUser = this.authGuard.getUserInfos();

    /* Get all appointments if an admin or user appointments  */
    if (this.appointmentsPermissions) {
      this.apiService.get('appointments').subscribe((returnedList) => {
        this.appointmentList = returnedList.data;
        this.filteredAppointmentList = this.appointmentList;
      })
    } else {
      this.apiService.get(`users/${this.selectedUser?.user_pid}/appointments`).subscribe((returnedList) => {
        this.appointmentList = returnedList.data;
        this.filteredAppointmentList = this.appointmentList;
      })
    }
  }

  /**
   * Function to apply filters
   */
  public applyFilters(): void {
    const trimmedClientFilter = this.clientFilter.trim();
    const trimmedBuildingFilter = this.buildingFilter.trim();
    const trimmedEmailFilter = this.emailFilter.trim();
    this.filteredAppointmentList = this.appointmentList.filter(appointment =>
      (!trimmedClientFilter || appointment.client_name.toLowerCase().includes(trimmedClientFilter.toLowerCase())) &&
      (!trimmedBuildingFilter || appointment.building_name.toLowerCase().includes(trimmedBuildingFilter.toLowerCase())) &&
      (!this.appointmentDateFilter || new Date(appointment.appointment_date).toDateString() === this.appointmentDateFilter.toDateString()) &&
      (!trimmedEmailFilter || appointment.user_email.some(email => email.toLowerCase().includes(trimmedEmailFilter.toLowerCase()))) &&
      (!this.appointmentTypeFilter || appointment.diagnosis_type.includes(this.appointmentTypeFilter)) &&
      (!this.selectedStatus || appointment.status === this.selectedStatus)
    );
  }

  /**
   * Function to reset filters
   */
  public resetFilters(): void {
    this.clientFilter = '';
    this.buildingFilter = '';
    this.appointmentDateFilter = null;
    this.emailFilter = '';
    this.appointmentTypeFilter = '';
    this.applyFilters();
  }

  /**
   * Function to navigate to edit appointment
   * @param appointment
   */
  public editAppointment(appointment: Appointment) {
    this.router.navigate([APPOINTMENT_URLS.edit], { queryParams: { appointment: JSON.stringify(appointment) }})
  }

  /**
   * Function to navigate to generate appointment
   * @param appointment
   */
  public generatePDF(appointment: Appointment) {
    this.router.navigate([APPOINTMENT_URLS.stepper], { queryParams: { appointment: JSON.stringify(appointment) }})
  }

  /**
   * Function to search appointments
   * @param event
   */
  public onAppointmentSearch(input: string) {
    this.filteredAppointmentList = this.appointmentList.filter(appointment =>
      appointment.client_name.toLowerCase().includes(input) ||
      appointment.building_name.toLowerCase().includes(input) ||
      appointment.appointment_date.toLowerCase().includes(input)
    );
  }

  /**
   * Function to delete an appointment, will first open a modal to confirm the deletion
   * @param appointment
   */
  public deleteAppointment(appointment: Appointment): void {
    const dialogRef = this.dialogService.open(DialogComponent, {
      context: {
        content: "Êtes-vous sûr de supprimer ce rendez-vous ?",
        title: "Confirmer la suppression",
        confirmButton: "Supprimer",
      },
      closeOnBackdropClick: true,
    });

    dialogRef.onClose.subscribe((result: string) => {
      if (result === 'action') {
        this.apiService.delete(`appointments/${appointment.appointment_pid}`).subscribe()
        this.filteredAppointmentList.splice(this.filteredAppointmentList.indexOf(appointment), 1);
      }
    })
  }

  /**
   * Check if user has permissions
   * @param permissionKey
   */
  public hasPermission(permissionKey: string): boolean {
    const userPermissions = this.authGuard.getUserPermissions();

    if (userPermissions) {
      return userPermissions[permissionKey] && userPermissions[permissionKey] === 'WRITE';
    } else {
      return false;
    }
  }
}
