<div class="breadcrumb">
  <ul>
    <!-- Links -->
    <ng-container *ngFor="let link of links; let i = index">
      <li *ngIf="i !== 0"><ImageAtom type="icon" image="arrow-right-grey" width="12px"></ImageAtom></li>
      <li *ngIf="link.link !== undefined && hasPermission(link.permission)">
        <ImageAtom *ngIf="link.icon" type="icon" [image]="link.icon" width="14px"></ImageAtom>
        <a [routerLink]="link.link" [queryParams]="link.queryParams">{{ link.title }}</a>
      </li>
      <li *ngIf="link.link === undefined || !hasPermission(link.permission)">
        <span>
          <ImageAtom *ngIf="link.icon" type="icon" [image]="link.icon" width="14px"></ImageAtom>
          {{ link.title }}
        </span>
      </li>
    </ng-container>
  </ul>
</div>
