import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import { Input } from '@angular/core';
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'ParagraphAtom',
  templateUrl: './paragraph.component.html',
  styleUrls: ['./paragraph.component.scss']
})
export class ParagraphComponent implements OnInit {

  /** Text in Paragraph */
  @Input() text: string | null = '';

  /** Size of Paragraph */
  @Input()  size: string = '';

  /** Color of Paragraph */
  @Input() color: string = '';

  /** Weight of Paragraph */
  @Input() weight: string = '';

  /** Style of the paragraph : underline  */
  @Input() style: string = '';

  /** Text align of Paragraph: center, left, right */
  @Input() align: string = 'left';

  /** mobile size */
  @Input() mobileSize: number = 0;

  /** The width and height of the window user */
  public isMobile = false;

  public get classes(): Array<string> {
    return [`paragraph paragraph-style ${this.color} ${this.size} ${this.weight} ${this.align}`];
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit(): void {
    // Execute code only on browser
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth < 1000;

      if (this.isMobile && this.mobileSize == 0) {
        this.mobileSize = 14;
      }
    }
  }

  rem(pxValue: number): string {
    const fontSize = 16;
    return `${pxValue / fontSize}rem`;
  }

}
