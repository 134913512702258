import {Component, Input, HostListener} from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {

  // Input for the action you want to set for the modal
  @Input() confirmButton: string = '';

  // Input for the title you want to set for the modal
  @Input() title!: String;

  // Input for the content you want to set for the modal
  @Input() content!: String;

  constructor(private dialogRef: NbDialogRef<DialogComponent>) {}

  // Prevent the default behavior (closing the dialog) when escape key is pressed
  @HostListener('document:keydown.escape', ['$event'])
  onEscape() {
    this.dialogRef.close('close');
  }

  // Close the modal
  closeDialog() {
    this.dialogRef.close('close');
  }

  // Confirm the action
  confirmAction() {
    this.dialogRef.close('action');
  }
}
