<div class="confirmation-container">
  <div class="confirmation-prodiag-logo">
    <ImageAtom type="image" image="prodiag-logo" width="200px" mobileWidth="120px"></ImageAtom>
  </div>
  <div class="confirmation-page">
    <!-- Loader Section -->
    <div *ngIf="isLoading" class="confirmation-loader">
      <ImageAtom type="icon" image="puff" width="150px" mobileWidth="120px"></ImageAtom>
      <TitleAtom size="large" weight="semi-bold" color="dark" text="Chargement des diagnostics... Merci de patienter..."></TitleAtom>
    </div>
    <div *ngIf="!isLoading && !message" class="confirmation-form">
      <TitleAtom size="large" weight="semi-bold" color="dark" text="Merci de confirmer les diagnostics suivants :"></TitleAtom>
      <form (ngSubmit)="onSubmit()">
        <div *ngFor="let diagnostic of diagnostics" class="diagnostic-item">
          <p class="confirmation-p"><strong>Immeuble:</strong> {{ diagnostic.building_name }}, <strong>Diagnostic:</strong> {{ diagnostic.diagnosis_name }}</p>
          <nb-radio-group class="diagnostic-item-radio" [(ngModel)]="diagnostic.response" name="response_{{ diagnostic.diagnosis_pid }}">
            <nb-radio [value]="'accord_mission'">Accord mission</nb-radio>
            <nb-radio [value]="'annulation_mission'">Annulation mission</nb-radio>
          </nb-radio-group>
        </div>
        <div class="diagnostic-item-confirm-btn-container">
            <ButtonAtom text="Confirmer" [style]="'basic'" color="green" (click)="onSubmit()"></ButtonAtom>
        </div>
      </form>
    </div>
    <div *ngIf="!isLoading && message" class="confirmation-message">
      <ImageAtom type="icon" image="validation" width="200px" mobileWidth="120px"></ImageAtom>
      <TitleAtom size="large" align="center" weight="semi-bold" color="dark" [text]="message"></TitleAtom>
    </div>
  </div>
</div>
