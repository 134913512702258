<div class="users-container">
  <Sidebar></Sidebar>
  <div class="users-page padding">

    <!-- USERS PAGE TITLE -->
    <div class="users-title">
      <Breadcrumb [links]="[{title: 'Utilisateurs', link: USER_URLS.list, icon: darkmodeService.getDarkModeState() ? 'avatar-light' : 'avatar-dark'}]"></Breadcrumb>
    </div>

    <ul class="users-filter-container">
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par nom"></ParagraphAtom>
        <input [(ngModel)]="lastNameFilter" nbInput placeholder="Nom" />
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par prénom"></ParagraphAtom>
        <input [(ngModel)]="firstNameFilter" nbInput placeholder="Prénom" />
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par email"></ParagraphAtom>
        <input [(ngModel)]="emailFilter" nbInput placeholder="Email" />
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par date de création"></ParagraphAtom>
        <input nbInput [nbDatepicker]="creationDatePicker" placeholder="Date de création" [(ngModel)]="creationDateFilter">
        <nb-datepicker #creationDatePicker></nb-datepicker>
      </li>
      <li>
        <button nbButton outline status="warning" (click)="resetFilters()">Réinitialiser</button>
      </li>
      <li>
        <button nbButton outline status="primary" (click)="applyFilters()">Filtrer</button>
      </li>
    </ul>

    <!-- USERS LIST -->
    <div class="users-list-container">
      <table-header [searchBar]="false" *ngIf="hasPermission('users')" addButtonContent="Ajouter un utilisateur" tableTitle="Utilisateurs" [headers]="USER_HEADERS.permission" [dataList]="filteredUsersList" [params]="['user_lastName', 'user_firstName', 'user_email', 'created_at', 'actions']" (editClicked)="editUser($event)" (deleteClicked)="deleteUser($event)" (addClicked)="this.router.navigate([USER_URLS.new])" (searchInputEvent)="onUserSearch($event)"></table-header>
      <table-header [searchBar]="false" *ngIf="!hasPermission('users')" [headers]="USER_HEADERS.noPermission" tableTitle="Utilisateurs" [dataList]="filteredUsersList" [params]="['user_lastName', 'user_firstName', 'user_email', 'created_at',]" ></table-header>
    </div>

  </div>
</div>
