<div class="buildings-container">
  <Sidebar></Sidebar>
  <div class="buildings-page padding">

    <!-- BUILDING PAGE TITLE -->
    <div>
      <TitleAtom size="large" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Modifier l'immeuble"></TitleAtom>
    </div>

    <!-- EDIT BUILDING SECTION -->

    <!-- BUILDING HEADER -->
    <div class="create-header">
      <button nbButton (click)="returnToBuildings()"><nb-icon icon="arrow-ios-back-outline"></nb-icon>Retour</button>
    </div>

    <!-- EDIT FORM -->
    <div class="buildings-create-container">
      <TitleAtom size="medium" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Modifier les informations de l'immeuble"></TitleAtom>

      <ul>
        <li>
          <div>
            <ParagraphAtom text="Nom de l'immeuble" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <input type="text" nbInput placeholder="Nom de l'immeuble..." [(ngModel)]="this.selectedBuilding!.building_name">
          </div>
        </li>
        <li class="form-email">
          <ParagraphAtom text="Adresse" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
          <input type="text" nbInput fullWidth [(ngModel)]="this.selectedBuilding!.building_address" placeholder="Adresse...">
        </li>
        <li>
          <div>
            <ParagraphAtom text="Code Postal" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <input type="text" nbInput fullWidth [(ngModel)]="this.selectedBuilding!.building_postalCode" placeholder="Code Postal...">
          </div>
          <div>
            <ParagraphAtom text="Ville" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <input type="text" nbInput fullWidth [(ngModel)]="this.selectedBuilding!.building_city" placeholder="Ville...">
          </div>
        </li>
        <li>
          <div>
            <ParagraphAtom text="Nom du gardien ou employé d'immeuble" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <input type="text" nbInput fullWidth [(ngModel)]="this.selectedBuilding!.building_employeeName" placeholder="Nom Prénom...">
          </div>
        </li>
        <li class="form-tel">
          <ParagraphAtom text="Numéro de téléphone du gardien ou employé d'immeuble" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
          <input type="tel" nbInput fullWidth [(ngModel)]="this.selectedBuilding!.building_tel" placeholder="Tel..." (ngModelChange)="verifyPhoneNumber()">
          <span *ngIf="!isPhoneNumberValid" class="error-message">Numéro de téléphone non valide</span>
        </li>
        <li *ngIf="this.selectedAgency">
          <div>
            <ParagraphAtom text="Sélectionnez le gestionnaire" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <nb-select fullWidth placeholder="Gestionnaire..." [(selected)]="selectedUser">
              <nb-select-label>
                {{ selectedUser?.user_firstName }} {{ selectedUser?.user_lastName }}
              </nb-select-label>

              <nb-option *ngFor="let user of managerList" [value]="user">
                {{ user.user_firstName }} {{ user.user_lastName }}
              </nb-option>
            </nb-select>
          </div>
        </li>
        <li class="form-email">
          <button nbButton fullWidth status="primary" [disabled]="!isFormEmpty() || isLoading" (click)="verifyForm()">Sauvegarder</button>
        </li>
      </ul>
    </div>

  </div>
</div>
