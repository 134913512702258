import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {
  AGENCY_URLS,
  BUILDING_URLS,
  CLIENT_HEADERS,
  CLIENT_TYPES,
  CLIENT_URLS,
  DIAGNOSIS_TYPES, MONTHS_LIST
} from '../../constants/constants';
import { Router } from '@angular/router';
import { Client } from 'src/app/types/client.type';
import { ApiService } from 'src/app/services/api.service';
import { DialogComponent } from "../../molecule/dialog/dialog.component";
import { NbDialogService } from "@nebular/theme";
import { AuthGuard } from "../../services/auth.guard";
import { DarkModeService } from "../../services/darkmode.service";
import { DataService } from 'src/app/services/data.service';
import {TableHeaderComponent} from "../../molecule/table-header/table-header.component";

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  public readonly CLIENT_URLS = CLIENT_URLS;
  public readonly CLIENT_HEADERS = CLIENT_HEADERS
  public readonly MONTHS_LIST = MONTHS_LIST;

  // Clients list objects
  @Input() clientsList: Client[] = [];

  // Table header component
  @ViewChild('tableHeaderComponent') tableHeaderComponent!: TableHeaderComponent;

  // To know which client is selected
  public selectedClient: Client | null = null;

  // Search value
  public filteredClientsList: Client[] = [];

  // Init Filters
  nameFilter: string = '';
  phoneFilter: string = '';
  filterType: string[] = [];
  diagnosisTypeFilter: string[] = [];
  filterYear: number | null = null;
  filterMonth: number | null = null;
  clientTypesArray = Object.entries(CLIENT_TYPES).map(([key, value]) => ({ label: key, value }));
  diagnosisTypesArray = DIAGNOSIS_TYPES;
  diagnosisNameFilter: string = '';

  constructor(public router: Router,
              private apiService: ApiService,
              private dialogService: NbDialogService,
              private authGuard: AuthGuard,
              public darkmodeService: DarkModeService,
              private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.dataService.setSelectedClient(null);
    this.dataService.setSelectedAgency(null);
    this.dataService.setSelectedBuilding(null);

    this.apiService.get('clients').subscribe(response => {
      this.clientsList = response.data;
      // Sort alphabetically
      this.clientsList.sort((a, b) => a.client_name.localeCompare(b.client_name));
      this.filteredClientsList = [...this.clientsList];
    })
  }

  /**
   * Function to search a client
   * @param input
   */
  public onClientSearch(input: string): void {
    this.filteredClientsList = this.clientsList.filter(client =>
      client.client_name.toLowerCase().includes(input) ||
      client.client_type!.toLowerCase().includes(input) ||
      client.created_at!.toLowerCase().includes(input)
    );
  }

  /**
   * Function to apply filters
   */
  public applyFilters(): void {
    const trimmedNameFilter = this.nameFilter.trim();
    const trimmedPhoneFilter = this.phoneFilter.trim();

    // Define filters
    const filters = {
      nameFilter: trimmedNameFilter || '',
      phoneFilter: trimmedPhoneFilter || '',
      filterType: this.filterType.length ? this.filterType.join(',') : '',
      diagnosisTypeFilter: this.diagnosisTypeFilter.length ? this.diagnosisTypeFilter.join(',') : '',
      filterYear: this.filterYear ? this.filterYear.toString() : '',
      filterMonth: this.filterMonth ? this.filterMonth.toString() : '',
      diagnosisNameFilter: this.diagnosisNameFilter || '',
    };

    // Convert filters to query params
    const queryParams = Object.entries(filters)
      .filter(([_, value]) => value)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');

    // Call the API to get the filtered clients
    this.apiService.get(`clients?${queryParams}`).subscribe(response => {
      this.filteredClientsList = response.data || [];

      // Call reloadDiagnostics function from table header component to reload the diagnostics
      this.tableHeaderComponent.reloadDiagnostics();
    }, error => {
      console.error('Error fetching filtered clients:', error);
      this.filteredClientsList = [];
    });
  }

  /**
   * Function to reset filters
   */
  public resetFilters(): void {
    this.nameFilter = '';
    this.phoneFilter = '';
    this.filterType = [];
    this.diagnosisTypeFilter = [];
    this.diagnosisNameFilter = '';
    this.filterYear = null;
    this.filterMonth = null;
    this.applyFilters();
  }

  /**
   * Function to get the client selected
   * @param client
   */
  public editClient(client: Client): void {
    this.router.navigate([CLIENT_URLS.edit], { queryParams: { client: JSON.stringify(client) } });
  }

  /**
   * Function to delete a client, will first open a modal to confirm the deletion
   * @param client
   */
  public deleteClient(client: Client): void {
    const dialogRef = this.dialogService.open(DialogComponent, {
      context: {
        content: "Êtes-vous sûr de supprimer ce client ?",
        title: "Confirmer la suppression",
        confirmButton: "Supprimer",
      },
      closeOnBackdropClick: true,
    });

    dialogRef.onClose.subscribe((result: string) => {
      if (result === 'action') {
        this.apiService.delete(`clients/${client.client_pid}`).subscribe()
        this.filteredClientsList.splice(this.filteredClientsList.indexOf(client), 1);
      }
    })
  }

  /**
   * Function to navigate to the buildings or agencies page with the client selected
   * @param client
   */
  public navigateToBuildings(client: Client): void {
    // If client is 'syndic' or 'societe' redirect to agencies page
    if (client.client_type === CLIENT_TYPES.SYNDIC || client.client_type === CLIENT_TYPES.SOCIETE) {
      this.router.navigate([AGENCY_URLS.list], { queryParams: { client: client.client_pid } });
    } else {
      this.router.navigate([BUILDING_URLS.list], { queryParams: { client: client.client_pid } });
    }
  }

  /**
   * Check if user has permissions
   * @param permissionKey
   */
  public hasPermission(permissionKey: string): boolean {
    const userPermissions = this.authGuard.getUserPermissions();

    if (userPermissions) {
      return userPermissions[permissionKey] && userPermissions[permissionKey] === 'WRITE';
    } else {
      return false;
    }
  }

  protected readonly DIAGNOSIS_TYPES = DIAGNOSIS_TYPES;
  protected readonly CLIENT_TYPES = CLIENT_TYPES;
}
