<div class="users-container">
  <Sidebar></Sidebar>
  <div class="users-page padding">

    <!-- USERS PAGE TITLE -->
    <div class="users-title">
      <TitleAtom size="large" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Créer un nouvel utilisateur"></TitleAtom>
    </div>

    <!-- CREATE USER SECTION -->
      <!-- USERS HEADER -->
      <div class="create-header">
          <button nbButton (click)="returnToUser()"><nb-icon icon="arrow-ios-back-outline"></nb-icon>Retour</button>
      </div>

      <!-- CREATE FORM -->
      <div class="users-create-container">
        <TitleAtom size="medium" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Entrez les informations du nouvel utilisateur"></TitleAtom>

        <ul>
          <li>
            <div>
              <ParagraphAtom text="Prénom"[color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
              <input type="text" nbInput [(ngModel)]="formData.firstName" placeholder="Prénom...">
            </div>
            <div>
              <ParagraphAtom text="Nom" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
              <input type="text" nbInput [(ngModel)]="formData.lastName" placeholder="Nom...">
            </div>
          </li>
          <li class="form-email">
            <ParagraphAtom text="Email" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <input type="text" nbInput fullWidth [(ngModel)]="formData.email" placeholder="Email...">
            <ParagraphAtom *ngIf="!isEmailValid" text="L'email n'est pas valide" color="red" size="nano" weight="semi-bold"></ParagraphAtom>
          </li>
          <li class="form-email">
            <ParagraphAtom text="Mot de passe" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <input type="text" nbInput fullWidth [(ngModel)]="formData.password" placeholder="Mot de passe...">
          </li>
          <li class="form-email select">
            <ParagraphAtom text="Lier l'utilisateur à des immeubles"
                           [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'"
                           size="medium" weight="semi-bold">
            </ParagraphAtom>
            <ng-select
              [items]="buildingsList"
              bindLabel="building_name"
              bindValue="building_pid"
              [(ngModel)]="formData.buildings"
              [multiple]="true"
              [searchable]="true"
              placeholder="Sélectionnez les immeubles...">
              <ng-template ng-label-tmp let-item="item" let-clear="clear">
                <span>{{ item.building_name }}</span>
                <span class="ng-value__remove" (click)="clear(item)">&times;</span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item">
                <div>{{ item.building_name }}</div>
                <small>{{ item.building_address }}, {{ item.building_city }}</small>
              </ng-template>
            </ng-select>
          </li>
          <li class="permissions-header">
            <ParagraphAtom text="Sélectionnez les permissions de l'utilisateur pour:" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <div>
              <div>
                <ParagraphAtom text="- Page des clients" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
                <nb-select [(selected)]="formData.user_permissions.clients">
                  <nb-select-label>
                    {{ getPermissionLabel(formData.user_permissions.clients) }}
                  </nb-select-label>

                  <nb-option *ngFor="let type of permissionOptions" [value]="type.value">
                    {{ type.label }}
                  </nb-option>
                </nb-select>
              </div>
              <div>
                <ParagraphAtom text="- Page des utilisateurs" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
                <nb-select [(selected)]="formData.user_permissions.users">
                  <nb-select-label>
                    {{ getPermissionLabel(formData.user_permissions.users) }}
                  </nb-select-label>

                  <nb-option *ngFor="let type of permissionOptions" [value]="type.value">
                    {{ type.label }}
                  </nb-option>
                </nb-select>
              </div>
            </div>
          </li>

          <li>
            <div>
              <ParagraphAtom text="- Page des notifications" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
              <nb-select [(selected)]="formData.user_permissions.notifications">
                <nb-select-label>
                  {{ getPermissionLabel(formData.user_permissions.notifications) }}
                </nb-select-label>

                <nb-option *ngFor="let type of permissionOptions" [value]="type.value">
                  {{ type.label }}
                </nb-option>
              </nb-select>
            </div>
            <div>
              <ParagraphAtom text="- Page des agences" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
              <nb-select [(selected)]="formData.user_permissions.agencies">
                <nb-select-label>
                  {{ getPermissionLabel(formData.user_permissions.agencies) }}
                </nb-select-label>

                <nb-option *ngFor="let type of permissionOptions" [value]="type.value">
                  {{ type.label }}
                </nb-option>
              </nb-select>
            </div>
          </li>

          <li>
            <div>
              <ParagraphAtom text="- Page des immeubles" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
              <nb-select [(selected)]="formData.user_permissions.buildings">
                <nb-select-label>
                  {{ getPermissionLabel(formData.user_permissions.buildings) }}
                </nb-select-label>

                <nb-option *ngFor="let type of permissionOptions" [value]="type.value">
                  {{ type.label }}
                </nb-option>
              </nb-select>
            </div>
            <div>
              <ParagraphAtom text="- Page des diagnostiques" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
              <nb-select [(selected)]="formData.user_permissions.diagnosis">
                <nb-select-label>
                  {{ getPermissionLabel(formData.user_permissions.diagnosis) }}
                </nb-select-label>

                <nb-option *ngFor="let type of permissionOptions" [value]="type.value">
                  {{ type.label }}
                </nb-option>
              </nb-select>
            </div>
          </li>
          <li>
            <div>
              <ParagraphAtom text="- Page des fichiers" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
              <nb-select [(selected)]="formData.user_permissions.files">
                <nb-select-label>
                  {{ getPermissionLabel(formData.user_permissions.files) }}
                </nb-select-label>

                <nb-option *ngFor="let type of permissionOptions" [value]="type.value">
                  {{ type.label }}
                </nb-option>
              </nb-select>
            </div>
            <div>
              <ParagraphAtom text="- Page des rendez-vous" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
              <nb-select [(selected)]="formData.user_permissions.appointments">
                <nb-select-label>
                  {{ getPermissionLabel(formData.user_permissions.appointments) }}
                </nb-select-label>

                <nb-option *ngFor="let type of permissionOptions" [value]="type.value">
                  {{ type.label }}
                </nb-option>
              </nb-select>
            </div>
          </li>
          <li>
            <div>
              <ParagraphAtom text="- Page des commentaires" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
              <nb-select [(selected)]="formData.user_permissions.comments">
                <nb-select-label>
                  {{ getPermissionLabel(formData.user_permissions.comments) }}
                </nb-select-label>
                <nb-option *ngFor="let type of permissionOptions" [value]="type.value">
                  {{ type.label }}
                </nb-option>
              </nb-select>
            </div>
            <div>
              <ParagraphAtom text="- Page des adresses" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
              <nb-select [(selected)]="formData.user_permissions.addresses">
                <nb-select-label>
                  {{ getPermissionLabel(formData.user_permissions.addresses) }}
                </nb-select-label>

                <nb-option *ngFor="let type of permissionOptions" [value]="type.value">
                  {{ type.label }}
                </nb-option>
              </nb-select>
            </div>
          </li>
          <li class="form-email">
            <button nbButton fullWidth [disabled]="!isFormEmpty() || isLoading" status="primary" (click)="verifyForm()">Sauvegarder</button>
          </li>
        </ul>
      </div>

  </div>
</div>
