import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Client } from 'src/app/types/client.type';
import { Agency } from 'src/app/types/agency.type';
import { Building } from 'src/app/types/building.type';
import { Diagnose } from '../types/diagnosis.type';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private selectedClientSubject = new BehaviorSubject<Client | null>(this.getFromLocalStorage<Client>('selectedClient'));
  private selectedAgencySubject = new BehaviorSubject<Agency | null>(this.getFromLocalStorage<Agency>('selectedAgency'));
  private selectedBuildingSubject = new BehaviorSubject<Building | null>(this.getFromLocalStorage<Building>('selectedBuilding'));
  private selectedDiagnosisSubject = new BehaviorSubject<Diagnose | null>(this.getFromLocalStorage<Diagnose>('selectedDiagnosis'));

  constructor() { }

  /**
   * Function to save the selected client
   * @param client to save
   */
  setSelectedClient(client: Client | null) {
    this.selectedClientSubject.next(client);
    this.saveToLocalStorage('selectedClient', client);
  }

  /**
   * Function to get the selected client
   */
  getSelectedClient() {
    return this.selectedClientSubject.asObservable();
  }

  /**
   * Function to save selected agency
   * @param agency to save
   */
  setSelectedAgency(agency: Agency | null) {
    this.selectedAgencySubject.next(agency);
    this.saveToLocalStorage('selectedAgency', agency);
  }

  /**
   * Function to get selected agency
   */
  getSelectedAgency() {
    return this.selectedAgencySubject.asObservable();
  }

  /**
   * Function to save selected building
   * @param building to save
   */
  setSelectedBuilding(building: Building | null) {
    this.selectedBuildingSubject.next(building);
    this.saveToLocalStorage('selectedBuilding', building);
  }

  /**
   * Function to get selected building
   */
  getSelectedBuilding() {
    return this.selectedBuildingSubject.asObservable();
  }

  /**
   * Function to save selected diagnosis
   * @param diagnosis to save
   */
  setSelectedDiagnosis(diagnosis: Diagnose | null) {
    this.selectedDiagnosisSubject.next(diagnosis);
    this.saveToLocalStorage('selectedDiagnosis', diagnosis);
  }

  /**
   * Function to get the diagnosis selected
   */
  getSelectedDiagnosis() {
    return this.selectedDiagnosisSubject.asObservable();
  }

  /**
   * Function to save on local storage
   * @param key - key name
   * @param value - value to save
   * @private
   */
  private saveToLocalStorage(key: string, value: any) {
    if (value) {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.removeItem(key);
    }
  }

  /**
   * Function to get item from localstorage
   * @param key - key to get
   * @private
   */
  private getFromLocalStorage<T>(key: string): T | null {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  }
}
