import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DiagnosticsService {
  private diagnosticsListSubject = new BehaviorSubject<{ [key: string]: any[] }>({});
  diagnosticsList$ = this.diagnosticsListSubject.asObservable();

  setDiagnostics(buildingPid: string, diagnostics: any[]) {
    const currentDiagnostics = this.diagnosticsListSubject.value;
    currentDiagnostics[buildingPid] = diagnostics;
    this.diagnosticsListSubject.next(currentDiagnostics);
  }

  getDiagnostics(buildingPid: string): any[] {
    return this.diagnosticsListSubject.value[buildingPid] || [];
  }
}
