import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {
  HOME_URLS,
  ADDRESSES_URLS,
  CLIENT_URLS,
  AGENCY_URLS,
  USER_URLS,
  NOTIFICATION_URLS,
  DIAGNOSIS_URLS,
  BUILDING_URLS,
  ACCOUNT_URLS,
  FILE_URLS,
  APPOINTMENT_URLS
} from "../../constants/constants";
import { User } from 'src/app/types/user.type';
import { AuthGuard } from "../../services/auth.guard";
import { DarkModeService } from "../../services/darkmode.service";

@Component({
  selector: 'Sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {

  // Path variable for sidebar's pages
  public readonly HOME_URLS = HOME_URLS;
  public readonly USER_URLS = USER_URLS;
  public readonly ADDRESSES_URLS = ADDRESSES_URLS;
  public readonly CLIENT_URLS = CLIENT_URLS;
  public readonly NOTIFICATION_URLS = NOTIFICATION_URLS;
  public readonly ACCOUNT_URLS = ACCOUNT_URLS;
  public readonly FILE_URLS = FILE_URLS;
  public readonly APPOINTMENT_URLS = APPOINTMENT_URLS;

  // Check the current page
  public isHomePage: boolean = false;
  public isClientPage: boolean = false;
  public isAddressPage: boolean = false;
  public isUserPage: boolean = false;
  public isNotificationPage: boolean = false;
  public isAccountPage: boolean = false;
  public isAppointmentPage: boolean = false;

  // User info
  public userInfo: User | null = null;
  public user_firstName: string = '';
  public user_lastName: string = '';

  constructor(public router: Router,
              private authGuard: AuthGuard,
              public darkmodeService: DarkModeService,
  ) {
    darkmodeService.loadInitialTheme();
  }

  ngOnInit(): void {
    const url = this.router.url.split('?')[0];

    /* Get user infos */
    this.authGuard.userInfo$.subscribe(user => {
      if (user) {
        this.userInfo = user;
        this.user_firstName = user.user_firstName;
        this.user_lastName = user.user_lastName;
      }
    });

    /* Get user permissions */
    this.authGuard.userPermissions$.subscribe(permissions => {
      if (permissions) {
        const user_permissions = permissions;
      }
    });

    /* Define url for activate class btn section */
    this.isHomePage = Object.values(HOME_URLS).includes(url);
    this.isAddressPage = Object.values(ADDRESSES_URLS).includes(url);
    this.isClientPage = Object.values(CLIENT_URLS).includes(url) || Object.values(BUILDING_URLS).includes(url) || Object.values(DIAGNOSIS_URLS).includes(url) || Object.values(AGENCY_URLS).includes(url) || Object.values(FILE_URLS).includes(url)
    this.isUserPage = Object.values(USER_URLS).includes(url);
    this.isNotificationPage = Object.values(NOTIFICATION_URLS).includes(url);
    this.isAccountPage = Object.values(ACCOUNT_URLS).includes(url);
    this.isAppointmentPage = Object.values(APPOINTMENT_URLS).includes(url);
  }

  /**
   * Function to change state of the theme
   */
  toggleDarkMode() {
    this.darkmodeService.toggleDarkMode();
  }

  /**
   * Function to know if darkmode is active
   */
  isDarkMode(): boolean {
    return this.darkmodeService.getDarkModeState();
  }

  /**
   * Check if user has permissions
   * @param permissionKey
   */
  public hasPermission(permissionKey: string): boolean {
    const userPermissions = this.authGuard.getUserPermissions();

    if (userPermissions) {
      return userPermissions[permissionKey] && userPermissions[permissionKey] !== 'NONE';
    } else {
      return false;
    }
  }

  /**
   * Redirect to the correct client(s) page
   */
  public redirectToClients(): void {
    if (this.userInfo && this.userInfo.client_type) {
      if (this.userInfo.client_type !== 'particulier') {
        this.router.navigate([AGENCY_URLS.list], { queryParams: { client: this.userInfo.client_pid } });
      } else if (this.userInfo.client_type === 'particulier') {
        this.router.navigate([BUILDING_URLS.list], { queryParams: { client: this.userInfo.client_pid } });
      }
    } else {
      this.router.navigate(['clients']);
    }
  }
}
