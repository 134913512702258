<div class="agencies-container">
  <Sidebar></Sidebar>
  <div class="agencies-page padding">

    <!-- AGENCIES PAGE TITLE -->
    <div class="agencies-title">
      <Breadcrumb [links]="[{title: this.selectedClient?.client_name || '', link: CLIENT_URLS.list, icon: 'client-grey'}, {title: 'Agences', link: AGENCY_URLS.list, icon: darkmodeService.getDarkModeState() ? 'agency-light' : 'agency-dark'}]"></Breadcrumb>
      <div>
        <button *ngIf="hasPermission('clients')" nbButton (click)="this.router.navigate([CLIENT_URLS.list])"><nb-icon icon="arrow-ios-back-outline"></nb-icon>Retour</button>
        <app-comments-modal *ngIf="selectedClient?.client_pid" [resourcePid]="selectedClient?.client_pid!"></app-comments-modal>
      </div>
    </div>

    <ul>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par nom"></ParagraphAtom>
        <input [(ngModel)]="nameFilter" nbInput placeholder="Nom" />
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par email"></ParagraphAtom>
        <input [(ngModel)]="emailFilter" nbInput placeholder="Email" />
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par code postal"></ParagraphAtom>
        <input [(ngModel)]="postalCodeFilter" nbInput placeholder="Code Postal" />
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par ville"></ParagraphAtom>
        <input [(ngModel)]="cityFilter" nbInput placeholder="Ville" />
      </li>
      <li>
        <button nbButton outline status="warning" (click)="resetFilters()">Réinitialiser</button>
      </li>
      <li>
        <button nbButton outline status="primary" (click)="applyFilters()">Filtrer</button>
      </li>
    </ul>

    <!-- AGENCIES LIST -->
    <div class="agencies-list-container">
      <table-header [searchBar]="false" *ngIf="hasPermission('agencies')" addButtonContent="Ajouter une agence" tableTitle="Agences" [isClickable]="true" (rowClicked)="navigateToBuildings(this.selectedClient?.client_pid!, $event)" [headers]="AGENCY_HEADERS.permission" [dataList]="filteredAgenciesList" [params]="['agency_name', 'agency_email', 'agency_address', 'agency_postalCode', 'agency_city', 'actions']" (editClicked)="editAgency($event, this.selectedClient!)" (deleteClicked)="deleteAgency($event)" (addClicked)="navigateToCreateAgency()" (searchInputEvent)="onAgencySearch($event)"></table-header>
      <table-header [searchBar]="false" *ngIf="!hasPermission('agencies')" [isClickable]="true" (rowClicked)="navigateToBuildings(this.selectedClient?.client_pid!, $event)" [headers]="AGENCY_HEADERS.noPermission" [dataList]="filteredAgenciesList" [params]="['agency_name', 'agency_email', 'agency_address', 'agency_postalCode', 'agency_city']" (editClicked)="editAgency($event, this.selectedClient!)" (deleteClicked)="deleteAgency($event)"></table-header>
    </div>

  </div>
</div>
