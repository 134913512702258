<button *ngIf="href === ''" [ngClass]="classes" [disabled]="isDisabled">
  <ng-container>
    <img *ngIf="icon && !href" [src]="'../../../assets/images/icons/' + icon + '.svg'" alt="{{icon}}" >
    {{text}}
    <img *ngIf="iconRight && !href" [src]="'../../../assets/images/icons/' + iconRight + '.svg'" alt="{{iconRight}}" >
  </ng-container>
</button>

<ng-container *ngIf="target === '_blank'">
  <a *ngIf="href !== ''" [target]="target"  [ngClass]="classes" [href]="href">
    <img *ngIf="icon" [src]="'../../../assets/images/icons/' + icon + '.svg'" alt="{{icon}}" >
    {{text}}
    <img *ngIf="iconRight" [src]="'../../../assets/images/icons/' + iconRight + '.svg'" alt="{{iconRight}}" >
  </a>
</ng-container>

<ng-container *ngIf="target !== '_blank'">
  <a *ngIf="href !== '' && !href.startsWith('tel:')"  [ngClass]="classes" [routerLink]="'/' + href" [queryParams]="queryParams">
    <img *ngIf="icon" [src]="'../../../assets/images/icons/' + icon + '.svg'" alt="{{icon}}" >
    {{text}}
    <img *ngIf="iconRight" [src]="'../../../assets/images/icons/' + iconRight + '.svg'" alt="{{iconRight}}" >
  </a>
  <a *ngIf="href !== '' && href.startsWith('tel:')"  [ngClass]="classes" [href]="href">
    <img *ngIf="icon" [src]="'../../../assets/images/icons/' + icon + '.svg'" alt="{{icon}}" >
    {{text}}
    <img *ngIf="iconRight" [src]="'../../../assets/images/icons/' + iconRight + '.svg'" alt="{{iconRight}}" >
  </a>
</ng-container>
