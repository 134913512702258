import { Component, OnInit } from '@angular/core';
import { CLIENT_URLS } from '../../constants/constants';
import { Router, ActivatedRoute } from '@angular/router';
import { Client } from 'src/app/types/client.type';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import {User} from "../../types/user.type";
import { DarkModeService } from "../../services/darkmode.service";

@Component({
  selector: 'app-client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.scss']
})
export class ClientEditComponent implements OnInit {

  public readonly CLIENT_URLS = CLIENT_URLS;

  // To know which client is selected
  public selectedClient: Client | null = null;

  // If loading
  public isLoading: boolean = false;

  // List to store every user
  public userList: User[] = [];

  // Value to store the selected user
  public selectedUser: User | null = null;

  // Stores an empty user
  public defaultUser: User = {
    user_firstName: "AUCUN",
    user_lastName: "",
    user_email: "",
  }

  constructor(public router: Router,
              private route: ActivatedRoute,
              private apiService: ApiService,
              private helperService: HelperService,
              public darkmodeService: DarkModeService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.selectedClient = JSON.parse(params['client']);
    });

    this.apiService.get('users').subscribe((userList) => {
      this.userList = userList.data;
      this.userList.unshift(this.defaultUser)
      this.selectedUser = this.userList.find(user => user.user_pid === this.selectedClient?.user_pid) || this.userList[0];
    })
  }

  /**
   * Function to send data to edit client
   */
  public editClient(): void {
    this.isLoading = true;
    const updatedBody = {
      client_name: this.selectedClient?.client_name,
      client_phone: this.selectedClient?.client_phone,
      user_pid: this.selectedUser?.user_pid,
    }

    this.apiService.patch(`clients/${this.selectedClient?.client_pid}`, updatedBody).subscribe(() => {
      this.router.navigate([CLIENT_URLS.list]);
      this.isLoading = false;
    })
  }

  /**
   * Check if the form is valid
   */
  public isFormEmpty(): boolean {
    return this.selectedClient?.client_name !== '';
  }

}
