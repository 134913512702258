import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import {USER_HEADERS, USER_URLS} from "../../constants/constants";
import { User } from 'src/app/types/user.type';
import {ApiService} from "../../services/api.service";
import { NbDialogService } from "@nebular/theme";
import { DialogComponent } from "../../molecule/dialog/dialog.component";
import { AuthGuard } from "../../services/auth.guard";
import { DarkModeService } from "../../services/darkmode.service";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  public readonly USER_URLS = USER_URLS;

  public readonly USER_HEADERS = USER_HEADERS;

  // Users list objects
  @Input() usersList: User[] = [];

  // To know which user is selected
  public selectedUser: User | null = null;

  // Search value
  public filteredUsersList: User[] = [];

  // Init filters
  public lastNameFilter: string = '';
  public firstNameFilter: string = '';
  public emailFilter: string = '';
  public creationDateFilter: Date | null = null;

  constructor(public router: Router,
              private readonly apiService: ApiService,
              private readonly dialogService: NbDialogService,
              private authGuard: AuthGuard,
              public darkmodeService: DarkModeService,
  ) { }

  ngOnInit(): void {
    this.apiService.get('users').subscribe((listOfUser) => {
      this.usersList = listOfUser.data
      // Sort alphabetically
      this.usersList.sort((a, b) => a.user_lastName.localeCompare(b.user_lastName));
      // Init the filtered list
      this.filteredUsersList = [...this.usersList];
    })
  }

  /**
   * Function to search a user
   * @param input
   */
  public onUserSearch(input: string): void {
    this.filteredUsersList = this.usersList.filter(user =>
      user.user_firstName.toLowerCase().includes(input) ||
      user.user_lastName.toLowerCase().includes(input) ||
      user.user_email.toLowerCase().includes(input)
    );
  }

  /**
   * Function to apply filters
   */
  public applyFilters(): void {
    const trimmedLastNameFilter = this.lastNameFilter.trim();
    const trimmedFirstNameFilter = this.firstNameFilter.trim();
    const trimmedEmailFilter = this.emailFilter.trim();
    this.filteredUsersList = this.usersList.filter(user => {
      const userCreatedAt = user.created_at ?? '';
      return (
        (!trimmedLastNameFilter || user.user_lastName.toLowerCase().includes(trimmedLastNameFilter.toLowerCase())) &&
        (!trimmedFirstNameFilter || user.user_firstName.toLowerCase().includes(trimmedFirstNameFilter.toLowerCase())) &&
        (!trimmedEmailFilter || user.user_email.toLowerCase().includes(trimmedEmailFilter.toLowerCase())) &&
        (!this.creationDateFilter || new Date(userCreatedAt).toDateString() === new Date(this.creationDateFilter).toDateString())
      );
    });
  }

  /**
   * Function to reset filters
   */
  public resetFilters(): void {
    this.lastNameFilter = '';
    this.firstNameFilter = '';
    this.emailFilter = '';
    this.creationDateFilter = null;
    this.applyFilters();
  }

  /**
   * Function to edit a user
   * @param user
   */
  public editUser(user: User): void {
    this.selectedUser = user;
    this.router.navigate([USER_URLS.edit], { queryParams: { user: JSON.stringify(user) } });
  }

  /**
   * Function to delete an user, will first open a modal to confirm the deletion
   * @param user
   */
  public deleteUser(user: User): void {
    const dialogRef = this.dialogService.open(DialogComponent, {
      context: {
        content: "Êtes-vous sûr de supprimer cet utilisateur ?",
        title: "Confirmer la suppression",
        confirmButton: "Supprimer",
      },
      closeOnBackdropClick: true,
    });

    dialogRef.onClose.subscribe((result: string) => {
      if (result === 'action') {
        this.apiService.delete(`users/${user.user_pid}`).subscribe()
        this.filteredUsersList.splice(this.filteredUsersList.indexOf(user), 1);
      }
    })
  }

  /**
   * Check if user has permissions
   * @param permissionKey
   */
  public hasPermission(permissionKey: string): boolean {
    const userPermissions = this.authGuard.getUserPermissions();

    if (userPermissions) {
      return userPermissions[permissionKey] && userPermissions[permissionKey] === 'WRITE';
    } else {
      return false;
    }
  }
}
