<ng-container *ngIf="image !== ''">
  <div class="image-component" [ngClass]="classes">
    <img loading="lazy" [ngClass]="classes" *ngIf="type === 'image'" [src]="'../../../assets/images/' + image + '.png'" alt="{{alt !== '' ? alt : image }}" [ngStyle]='this.windowWidth > 1024 ? { width : width } : { width: mobileWidth }'>
    <img loading="lazy" [ngClass]="classes" *ngIf="type === 'icon'" [src]="'../../../assets/images/icons/' + image + '.svg'" alt="{{alt !== '' ? alt : image }}" [ngStyle]='this.windowWidth > 1024 ? { width : width } : { width: mobileWidth }'>
  </div>
</ng-container>

<ng-container *ngIf="imageExternal !== ''">
  <div class="image-component" [ngClass]="classes">
    <img loading="lazy" [ngClass]="classes" [src]="imageExternal" alt="{{alt !== '' ? alt : image }}" [ngStyle]='this.windowWidth > 1024 ? { width : width } : {width: mobileWidth}'>
  </div>
</ng-container>
