import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { DarkModeService } from '../../services/darkmode.service';
import { isPlatformBrowser } from "@angular/common";
import {APPOINTMENT_URLS} from "../../constants/constants";

interface Diagnostic {
  diagnosis_pid: string;
  diagnosis_name: string;
  building_name: string;
  notification_pid: string;
  response?: string;
}

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  public message: string | null = null;
  public isLoading: boolean = false;
  public isMobile: boolean = false;

  public diagnostics: Diagnostic[] = [];

  private clientPid: string = '';
  private notificationPids: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    public darkmodeService: DarkModeService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth < 1000;
    }

    this.route.queryParams.subscribe(params => {
      this.clientPid = params['client_pid'];
      const notificationPidsParam = params['notification_pids'];

      if (this.clientPid && typeof notificationPidsParam === 'string' && notificationPidsParam) {
        this.notificationPids = notificationPidsParam.split(',').map((pid: string) => pid.trim());
        this.isLoading = true;

        // Get diagnostics
        const notificationPidsEncoded = encodeURIComponent(this.notificationPids.join(','));
        const clientPidEncoded = encodeURIComponent(this.clientPid);

        this.apiService.get(`diagnoses/${clientPidEncoded}/confirmation?notification_pids=${notificationPidsEncoded}`)
          .subscribe(
            (response: any) => {
              let parsedResponse;
              if (typeof response === 'string') {
                try {
                  parsedResponse = JSON.parse(response);
                } catch (e) {
                  console.error('Erreur lors de l\'analyse de la réponse JSON :', e);
                  this.message = 'Erreur lors du traitement de la réponse du serveur.';
                  this.isLoading = false;
                  return;
                }
              } else {
                parsedResponse = response;
              }
              this.diagnostics = parsedResponse.diagnostics.map((diagnostic: Diagnostic) => {
                diagnostic.response = 'accord_mission'; // Réponse par défaut
                return diagnostic;
              });
              this.isLoading = false;
            },
            (error) => {
              console.error('Erreur API:', error);
              this.message = 'Une erreur est survenue lors du chargement des diagnostics.';
              this.isLoading = false;
            }
          );
      } else {
        this.message = 'Paramètres manquants dans l\'URL.';
      }
    });
  }

  onSubmit(): void {
    this.isLoading = true;
    const formData = {
      responses: this.diagnostics.map((diagnostic: Diagnostic) => ({
        diagnosisPid: diagnostic.diagnosis_pid,
        notificationPid: diagnostic.notification_pid,
        response: diagnostic.response
      }))
    };

    this.apiService.post('answers/update', { formData }).subscribe(
      (response: any) => {
        this.message = 'Réponses bien prises en compte, vous pouvez fermer la page.';
        this.isLoading = false;
      },
      (error) => {
        console.error('Erreur API:', error);
        this.message = 'Une erreur est survenue lors de la sauvegarde de vos réponses.';
        this.isLoading = false;
      }
    );
  }

  protected readonly APPOINTMENT_URLS = APPOINTMENT_URLS;
}
