<nb-layout>

  <nb-layout-column>
    <div class="wrapper">
        <router-outlet></router-outlet>
    </div>

  </nb-layout-column>

</nb-layout>
