import { Component, ElementRef, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  BUILDING_URLS,
  CLIENT_URLS,
  AGENCY_URLS,
  DIAGNOSIS_URLS,
  FILE_URLS, FILE_HEADERS,
} from "../../constants/constants";
import { Client } from 'src/app/types/client.type';
import { Agency } from 'src/app/types/agency.type';
import { Building } from 'src/app/types/building.type';
import { Diagnose } from 'src/app/types/diagnosis.type';
import { File } from 'src/app/types/file.type';
import { NbDialogService } from "@nebular/theme";
import { DialogComponent } from "../../molecule/dialog/dialog.component";
import { AuthGuard } from "../../services/auth.guard";
import { DataService } from "../../services/data.service";
import { ApiService } from "../../services/api.service";
import {S3} from 'aws-sdk';
import { HttpClient } from '@angular/common/http';
import {HttpResponse} from '@angular/common/http';
import { DarkModeService } from "../../services/darkmode.service";
import * as moment from 'moment';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit {

  // Path constant
  public readonly CLIENT_URLS = CLIENT_URLS;
  public readonly AGENCY_URLS = AGENCY_URLS;
  public readonly BUILDING_URLS = BUILDING_URLS;
  public readonly DIAGNOSIS_URLS = DIAGNOSIS_URLS;
  public readonly FILE_URLS = FILE_URLS;
  public readonly FILE_HEADERS = FILE_HEADERS;

  // To get the file
  @ViewChild('diagnosisFile') diagnosisFile!: ElementRef;
  @ViewChild('errorDialog') errorDialog!: ElementRef;

  public filesList: File[] = [];

  public uploading: boolean = false;

  // To know which client is selected
  public selectedClient: Client | null = null;

  // To know which agency is selected
  public selectedAgency: Agency | null = null;

  // To know which building is selected
  public selectedBuilding: Building | null = null;

  // To know which building is selected
  public selectedDiagnosis: Diagnose | null = null;

  // To know which diagnostic is selected
  public diagnosisPid: string | undefined = '';

  // User info
  public user_pid: string | undefined = "";
  public user_email: string = "";

  // Search value
  public filteredFilesList: File[] = [];

  // Init filters
  public fileNameFilter: string = '';
  public addedByFilter: string = '';
  public addDateFilter: Date | null = null;

  constructor(public router: Router,
              private route: ActivatedRoute,
              private dialogService: NbDialogService,
              private authGuard: AuthGuard,
              private dataService: DataService,
              private apiService: ApiService,
              private httpClient: HttpClient,
              public darkmodeService: DarkModeService,
  ) {
  }

  ngOnInit(): void {

    /* Get user infos */
    this.authGuard.userInfo$.subscribe(user => {
      if (user) {
        this.user_pid = user.user_pid;
        this.user_email = user.user_email;
      }
    });

    this.route.queryParams.subscribe(params => {
      this.diagnosisPid = params['diagnosis'];

      this.dataService.getSelectedClient().subscribe(client => {
        this.selectedClient = client;
      });

      this.dataService.getSelectedAgency().subscribe(agency => {
        this.selectedAgency = agency;
      });

      this.dataService.getSelectedBuilding().subscribe(building => {
        this.selectedBuilding = building;
      });

      this.dataService.getSelectedDiagnosis().subscribe(diagnosis => {
        this.selectedDiagnosis = diagnosis;
      });

    });

    this.apiService.get(`/diagnoses/${this.diagnosisPid}/files`).subscribe(response => {
      this.filesList = response.data.map((file: File) => {
        file.clients_download = file.clients_download ? moment(file.clients_download).format('DD/MM/YYYY HH:mm') : null;
        return file;
      });

      this.filesList.sort((a, b) => a.file_name.localeCompare(b.file_name));
      this.filteredFilesList = [...this.filesList];
    });

  }

  /**
   * Function to upload when files is selected by user
   * @param event
   */
  public async onFileSelect(event: Event): Promise<void> {
    const input = event.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }

    const files = Array.from(input.files);

    try {
      this.uploading = true;
      await this.uploadMultipleFiles(files);
    } catch (error) {
      this.showErrorModal("Impossible de télécharger le fichier");
    } finally {
      this.uploading = false;
    }
  }

  /**
   * Function to filter
   */
  public applyFilters(): void {
    const trimmedNameFilter = this.fileNameFilter.trim();
    const trimmedAddedByFilter = this.addedByFilter.trim();
    this.filteredFilesList = this.filesList.filter(file => {
      const fileCreatedAt = file.created_at ?? '';
      return (
        (!trimmedNameFilter || file.file_name.toLowerCase().includes(trimmedNameFilter.toLowerCase())) &&
        (!trimmedAddedByFilter || file.user_email!.toLowerCase().includes(trimmedAddedByFilter.toLowerCase())) &&
        (!this.addDateFilter || new Date(fileCreatedAt).toDateString() === new Date(this.addDateFilter).toDateString())
      )
    });
  }

  /**
   * Function to reset filters
   */
  public resetFilters(): void {
    this.fileNameFilter = '';
    this.addedByFilter = '';
    this.addDateFilter = null;
    this.applyFilters();
  }

  /**
   * Function to upload mutliple files on S3
   * @param files
   * @private
   */
  private async uploadMultipleFiles(files: object[]): Promise<void> {
    try {
      const uploadPromises = files.map(async (file) => {
        return this.uploadFile(file);
      });

      await Promise.all(uploadPromises);

      this.getUpdatedFilesList();

    } catch (error) {
      this.showErrorModal("Impossible de télécharger le fichier");
    }
  }

  /**
   * Function to upload file on S3
   * @param file to upload
   * @private
   */
  private async uploadFile(file: any): Promise<HttpResponse<Object>> {
    try {
      // Encode the filename for consistent storage and operations
      const fileName = encodeURIComponent(file.name);
      const response = await this.apiService.get(`files/upload/${fileName}`).toPromise();
      const responseObject = JSON.parse(response);
      const signedUrl = responseObject.uploadUrl;

      const uploadResult = await this.httpClient.put(signedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
        observe: 'response'
      }).toPromise();

      // Store the encoded file name in the database
      if (uploadResult?.status === 200) {
        const filesData = {
          file_name: fileName, // Store the encoded name
          diagnosis_pid: this.diagnosisPid,
          user_pid: this.user_pid,
          user_email: this.user_email
        };

        this.apiService.post('files', filesData).subscribe(() => {
          this.getUpdatedFilesList();
        });
      }

      return uploadResult!;
    } catch (error) {
      throw new Error('Échec de l\'upload du fichier');
    }
  }

  /**
   * Function to search a diagnosis
   * @param input
   */
  public onFilesSearch(input: string): void {
    this.filteredFilesList = this.filesList.filter(file =>
      file.file_name.toLowerCase().includes(input),
    );
  }

  /**
   * Function to delete the selected file, will first open a modal to confirm the deletion
   * @param file
   */
  public deleteFile(file: File): void {
    const dialogRef = this.dialogService.open(DialogComponent, {
      context: {
        content: "Êtes-vous certain de vouloir supprimer le fichier ?",
        title: "Confirmer la suppression",
        confirmButton: "Supprimer",
      },
      closeOnBackdropClick: true,
    });

    dialogRef.onClose.subscribe((result: string) => {
      if (result === 'action') {
        // Use the file name directly as it is stored (already encoded correctly)
        const fileName = file.file_name;  // No further encoding
        this.apiService.delete(`files/${fileName}`).subscribe(() => {
          this.filesList = this.filesList.filter(f => f !== file);
          this.filteredFilesList.splice(this.filteredFilesList.indexOf(file), 1);
        });
      }
    });
  }

  /**
   * Function to download file from s3
   * @param file
   */
  public downloadFile(file: File): void {
    const encodedFileName = file.file_name;
    const userPid = this.user_pid;
    this.apiService.get(`files/${encodedFileName}?user_pid=${userPid}`).subscribe((response) => {
      const downloadUrl = response.downloadUrl;
      window.open(downloadUrl, '_blank');
    }, (error) => {
      console.error('Error downloading file:', error);
    });
  }

  /**
   * Function to back to the diagnoses page with the query params
   */
  public backToDiagnoses(): void {
    if (this.selectedAgency) {
      this.router.navigate([DIAGNOSIS_URLS.list], { queryParams: {client: this.selectedClient?.client_pid, agency:this.selectedAgency.agency_pid, building: this.selectedBuilding?.building_pid } })
    } else {
      this.router.navigate([DIAGNOSIS_URLS.list], { queryParams: { building: this.selectedBuilding?.building_pid } });
    }
  }

  /**
   * Function to stringify a JSON object
   */
  public stringify(object: Client | Agency | Building | Diagnose | null): string {
    return JSON.stringify(object);
  }

  /**
   * Check if user has permissions
   * @param section The section for the permissions
   * @param permission The permission for this section
   */
  public hasPermission(section: string, permission: string): boolean {
    const userPermissions = this.authGuard.getUserPermissions();
    return userPermissions[section] === permission || userPermissions[section] === 'WRITE';
  }

  /**
   * Function to updated the files list
   * @private
   */
  private getUpdatedFilesList() {
    this.apiService.get(`/diagnoses/${this.diagnosisPid}/files`).subscribe(response => {
      this.filesList = response.data.map((file: File) => {
        file.clients_download = file.clients_download ? moment(file.clients_download).format('DD/MM/YYYY HH:mm') : null;
        return file;
      });
      this.filteredFilesList = [...this.filesList];
    });
  }

  /**
   * Function to display error modal
   * @param message
   */
  public showErrorModal(message: string): void {
    this.dialogService.open(DialogComponent, {
      context: {
        content: "Une erreur s'est produite lors du téléchargement du fichier",
        title: "Erreur lors du téléchargement",
      }
    });
  }

  /**
   * Handle drag over event
   */
  @HostListener('dragover', ['$event'])
  public onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    // Optionally, you can add visual feedback here
  }

  /**
   * Handle drag leave event
   */
  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    // Optionally, you can remove visual feedback here
  }

  /**
   * Handle drop event
   */
  @HostListener('drop', ['$event'])
  public async onDrop(event: DragEvent): Promise<void> {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer?.files.length) {
      const files = Array.from(event.dataTransfer.files);
      this.uploading = true;
      try {
        await this.uploadMultipleFiles(files);
      } catch (error) {
        this.showErrorModal("Impossible de télécharger le fichier");
      } finally {
        this.uploading = false;
      }
    }
  }
}
