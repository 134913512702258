<div class="diagnosis-container">
  <Sidebar></Sidebar>
  <div class="diagnosis-page padding">

    <!-- DIAGNOSIS PAGE TITLE -->
    <div class="diagnosis-title">
      <TitleAtom size="large" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Ajouter un diagnostic"></TitleAtom>
    </div>

    <!-- CREATE DIAGNOSIS SECTION -->
    <!-- DIAGNOSIS HEADER -->
    <div class="create-header">
      <button nbButton (click)="returnToDiagnoses()"><nb-icon icon="arrow-ios-back-outline"></nb-icon>Retour</button>
    </div>

    <!-- CREATE FORM -->
    <div class="diagnosis-create-container">
      <TitleAtom size="medium" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Renseignez les informations du diagnostic"></TitleAtom>

      <div class="diagnosis-content-container">
        <div class="diagnosis-info-container">
          <div class="diagnosis-info-title">
            <ParagraphAtom text="INFORMATION" [color]="darkmodeService.getDarkModeState() ? 'light' : 'light'" size="large" weight="semi-bold"></ParagraphAtom>
          </div>
          <ul>
            <li>
              <ParagraphAtom [text]="'Client : ' + selectedClient!.client_name" [color]="darkmodeService.getDarkModeState() ? 'light' : 'light'" size="large" weight="semi-bold"></ParagraphAtom>
            </li>
            <li *ngIf="selectedAgency">
              <ParagraphAtom [text]="'Agence :' + selectedAgency!.agency_name" [color]="darkmodeService.getDarkModeState() ? 'light' : 'light'" size="large" weight="semi-bold"></ParagraphAtom>
            </li>
            <li>
              <ParagraphAtom [text]="'Immeuble : ' + selectedBuilding!.building_name" [color]="darkmodeService.getDarkModeState() ? 'light' : 'light'" size="large" weight="semi-bold"></ParagraphAtom>
            </li>
          </ul>
        </div>

        <ul class="diagnosis-create-form">
          <li>
            <div>
              <ParagraphAtom text="Numéro dossier" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
              <input fullWidth type="text" nbInput [(ngModel)]="formData.diagnosis_name" placeholder="Numéro...">
            </div>
          </li>
          <li class="diagnosis-date">
            <div>
              <ParagraphAtom text="Date de début de contrat" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
              <input fullWidth nbInput placeholder="Début de contrat..." [nbDatepicker]="datepickerStart" [(ngModel)]="formData.startOfContract" (ngModelChange)="verifyForm()" readOnly>
              <nb-datepicker #datepickerStart ></nb-datepicker>
            </div>
            <div>
              <ParagraphAtom text="Date de fin de contrat" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
              <input fullWidth nbInput placeholder="Fin de contrat..." [nbDatepicker]="datepickerEnd" [(ngModel)]="formData.endOfContract" (ngModelChange)="verifyForm()" readOnly>
              <nb-datepicker #datepickerEnd [min]="minDate"></nb-datepicker>
            </div>
          </li>
          <li>
            <div>
              <ParagraphAtom text="Sélectionnez le type du diagnostic" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
              <nb-select fullWidth [(selected)]="formData.diagnosisType">
                <nb-select-label>
                  {{ formData.diagnosisType }}
                </nb-select-label>

                <nb-option *ngFor="let type of diagnosisType" [value]="type.value">
                  {{ type.label }}
                </nb-option>
              </nb-select>
            </div>
          </li>
          <li>
          <div>
            <ParagraphAtom text="Statut de progression" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <nb-select fullWidth placeholder="Statut..." [(ngModel)]="formData.progress_status">
              <nb-option value="non défini">Non défini</nb-option>
              <nb-option value="message">Message</nb-option>
              <nb-option value="rdv pris">RDV pris</nb-option>
              <nb-option value="en rédaction">En rédaction</nb-option>
              <nb-option value="litige">Litige</nb-option>
              <nb-option value="validé">Validé</nb-option>
            </nb-select>
          </div>
        </li>        
          <li class="form-btn">
            <ParagraphAtom *ngIf="showDateError" text="La date de fin de contrat ne peut être inférieure à celle du début de contrat" color="red" size="medium" weight="semi-bold"></ParagraphAtom>
            <button nbButton fullWidth status="primary" [disabled]="!isFormValid || isLoading || isFormEmpty()" (click)="createDiagnosis()">Sauvegarder</button>
          </li>
        </ul>
      </div>

    </div>
  </div>
</div>
