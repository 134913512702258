<div class="clients-container">
  <Sidebar></Sidebar>
  <div class="clients-page padding">

    <!-- CLIENTS PAGE TITLE -->
    <div class="clients-title">
      <Breadcrumb [links]="[{title: 'Clients', link: CLIENT_URLS.list, icon: darkmodeService.getDarkModeState() ? 'client-light' : 'client-dark' }]"></Breadcrumb>
    </div>

    <!-- FILTERS -->
    <ul>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par nom"></ParagraphAtom>
        <input [(ngModel)]="nameFilter" nbInput placeholder="Nom" />
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par téléphone"></ParagraphAtom>
        <input [(ngModel)]="phoneFilter" nbInput placeholder="Téléphone" />
      </li>
      <li>
        <ParagraphAtom
          size="small"
          weight="semi-bold"
          [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'"
          text="Par type">
        </ParagraphAtom>
        <nb-select placeholder="Type" [(selected)]="filterType" multiple>
          <nb-option *ngFor="let type of clientTypesArray" [value]="type.value">
            {{ type.label }}
          </nb-option>
        </nb-select>
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par type de diagnostic"></ParagraphAtom>
        <nb-select placeholder="Type de diagnostic" [(selected)]="diagnosisTypeFilter" multiple>
          <nb-option *ngFor="let diagnosisType of diagnosisTypesArray" [value]="diagnosisType.value">
            {{ diagnosisType.label }}
          </nb-option>
        </nb-select>
      </li>
    </ul>
    <ul>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par numéro de dossier"></ParagraphAtom>
        <input [(ngModel)]="diagnosisNameFilter" nbInput placeholder="Numéro de dossier" />
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par mois"></ParagraphAtom>
        <nb-select placeholder="Mois" [(selected)]="filterMonth">
          <nb-option *ngFor="let month of MONTHS_LIST" [value]="month.value">
            {{ month.label }}
          </nb-option>
        </nb-select>
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par année"></ParagraphAtom>
        <input type="number" nbInput placeholder="Année (ex. 2023)" [(ngModel)]="filterYear">
      </li>
      <li>
        <button nbButton outline status="warning" (click)="resetFilters()">Réinitialiser</button>
      </li>
      <li>
        <button nbButton outline status="primary" (click)="applyFilters()">Filtrer</button>
      </li>
    </ul>

    <!-- CLIENTS LIST -->
    <div class="clients-list-container">
      <table-header #tableHeaderComponent
                    [diagnosisTypeFilter]="diagnosisTypeFilter"
                    [diagnosisNameFilter]="diagnosisNameFilter"
                    [filterYear]="filterYear"
                    [filterMonth]="filterMonth" [searchBar]="false" *ngIf="hasPermission('clients')" [isClickable]="true" addButtonContent="Ajouter un client" tableTitle="Clients" (rowClicked)="navigateToBuildings($event)" [headers]="CLIENT_HEADERS.permission" [dataList]="filteredClientsList" [params]="['client_name', 'client_phone', 'created_at', 'client_type', 'actions']" (editClicked)="editClient($event)" (deleteClicked)="deleteClient($event)" (addClicked)="this.router.navigate([CLIENT_URLS.new])" (searchInputEvent)="onClientSearch($event)"></table-header>
      <table-header #tableHeaderComponent
                    [diagnosisTypeFilter]="diagnosisTypeFilter"
                    [diagnosisNameFilter]="diagnosisNameFilter"
                    [filterYear]="filterYear"
                    [filterMonth]="filterMonth" [searchBar]="false" *ngIf="!hasPermission('clients')" [isClickable]="true" tableTitle="Clients" (rowClicked)="navigateToBuildings($event)" [headers]="CLIENT_HEADERS.noPermission" [dataList]="filteredClientsList" [params]="['client_name', 'client_phone', 'created_at', 'client_type']"></table-header>
    </div>
  </div>
</div>
