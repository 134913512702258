import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BUILDING_URLS } from "../../constants/constants";
import { Client } from 'src/app/types/client.type';
import { Agency } from 'src/app/types/agency.type';
import { ApiService } from "../../services/api.service";
import { HelperService } from "../../services/helper.service";
import { User } from "../../types/user.type";
import { Building } from "../../types/building.type";
import { DarkModeService } from "../../services/darkmode.service";

interface FormData {
  name: string,
  /*email: string,*/
  tel: string,
  employeeName: string,
  address: string,
  postalCode: string,
  city: string,
  progress_status: string 
}

@Component({
  selector: 'app-building-create',
  templateUrl: './building-create.component.html',
  styleUrls: ['./building-create.component.scss']
})
export class BuildingCreateComponent implements OnInit {

  // If loading
  public isLoading: boolean = false;

  // Building datas
  public formData: FormData = {
    name: '',
    /*email: '',*/
    address: '',
    postalCode: '',
    city: '',
    employeeName: '',
    tel: '',
    progress_status: 'non défini' 
  }

  // To know which client is selected
  public selectedClient: Client | null = null;

  // To know wich agency is selected
  public selectedAgency: Agency | null = null;

  // Boolean to know if the email follow the regex norms
  public isEmailValid: boolean = true;

  // Boolean to check if phone is valid
  public isPhoneNumberValid: boolean = true;

  // Variable to store which user is selected
  public selectedUser: User | null = null;

  // Stores an empty user
  public defaultUser: User = {
    user_firstName: "AUCUN",
    user_lastName: "",
    user_email: "",
  }

  // List of users
  public managerList: User[] = [];

  constructor(public router: Router,
              private route: ActivatedRoute,
              private apiService: ApiService,
              private helperService: HelperService,
              public darkmodeService: DarkModeService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['client']) {
        this.selectedClient = JSON.parse(params['client']);
      }
      if (params['agency']) {
        this.selectedAgency = JSON.parse(params['agency']);
      }
    });

    if (this.selectedAgency) {
      this.apiService.get('users').subscribe((userList) => {
        this.managerList = userList.data;
        this.managerList.unshift(this.defaultUser);
        this.selectedUser = this.managerList[0]
      })
    }
  }

  /**
   * Function to verify phone
   */
  public verifyPhoneNumber(): void {
    this.isPhoneNumberValid = this.formData.tel === '' || this.helperService.telRegex.test(this.formData.tel);
  }

  /**
   * Function to check if the form is valid
   */
  public isFormEmpty(): boolean {
    return this.formData.name !== '' && this.formData.address !== '' && this.formData.city !== '' && this.formData.postalCode !== '';
  }

  /**
   * Function to return to buildings page with the client selected
   */
  public returnToBuildings(): void {
    if (this.selectedAgency && this.selectedClient) {
      this.router.navigate([BUILDING_URLS.list], { queryParams: { client: this.selectedClient.client_pid, agency: this.selectedAgency.agency_pid } });
    } else if (this.selectedClient && this.selectedAgency === null) {
      this.router.navigate([BUILDING_URLS.list], { queryParams: { client: this.selectedClient.client_pid } });
    }
  }

  /**
   * Function to create a new building
   * @private
   */
  private createBuilding(): void {
    this.isLoading = true;
    if (this.selectedAgency && this.selectedClient) {
      const buildingData: Building = {
        building_name: this.formData.name,
        //building_email: this.formData.email,
        building_address: this.formData.address,
        building_city: this.formData.city,
        building_postalCode: this.formData.postalCode,
        gestionnaire_pid: this.selectedUser?.user_pid,
        gestionnaire_email: this.selectedUser?.user_email,
        client_pid: this.selectedClient?.client_pid,
        agency_pid: this.selectedAgency?.agency_pid,
        building_tel: this.formData.tel,
        building_employeeName: this.formData.employeeName,
        progress_status: this.formData.progress_status
      };

      this.apiService.post(`agencies/${this.selectedAgency.agency_pid}/buildings`, buildingData).subscribe(() => {
        this.returnToBuildings();
        this.isLoading = false;
      })

    } else if (this.selectedClient && this.selectedAgency === null) {
      const buildingData: Building = {
        building_name: this.formData.name,
        //building_email: this.formData.email,
        building_address: this.formData.address,
        building_city: this.formData.city,
        building_postalCode: this.formData.postalCode,
        client_pid: this.selectedClient?.client_pid,
        building_tel: this.formData.tel,
        building_employeeName: this.formData.employeeName,
        progress_status: this.formData.progress_status
      };

      this.apiService.post(`clients/${this.selectedClient.client_pid}/buildings`, buildingData).subscribe(() => {
        this.returnToBuildings();
        this.isLoading = false;
      })
    }
  }

  /**
   * Function to check if the email is valid
   */
  public verifyForm(): void {
/*
    this.isEmailValid = !this.formData!.email || this.helperService.emailRegex.test(this.formData.email);
*/
    this.verifyPhoneNumber();
    if (this.isEmailValid && this.isPhoneNumberValid)
      this.createBuilding()
  }
}
