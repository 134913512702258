import {Component, Input, OnInit, HostListener} from '@angular/core';
import { NbDialogRef } from "@nebular/theme";

interface formationInterface {
  formation: string,
  numberOfPerson: string,
}

@Component({
  selector: 'app-dialog-formation',
  templateUrl: './dialog-formation.component.html',
  styleUrls: ['./dialog-formation.component.scss']
})
export class DialogFormationComponent implements OnInit {

  // Input for the title you want to set for the modal
  @Input() title!: string;

  // Form Data value
  @Input() formData: any;

  public tmpFormData: formationInterface = {
    formation: '',
    numberOfPerson: '',
  }

  constructor(private dialogRef: NbDialogRef<DialogFormationComponent>) {}

  ngOnInit(): void {
    if (this.formData) {
      this.tmpFormData = {...this.formData};
    }
  }

  // Prevent the default behavior (closing the dialog) when escape key is pressed
  @HostListener('document:keydown.escape', ['$event'])
  onEscape() {
    this.dialogRef.close('close');
  }

  /**
   * Function that check if every input isn't empty
   */
  public isFormEmpty(): boolean {
    return !!(this.tmpFormData.formation && this.tmpFormData.numberOfPerson);
  }

  // Close the modal
  public closeDialog(): void {
    this.dialogRef.close('close');
  }

  // Confirm the action
  public confirmAction(): void {
    this.dialogRef.close(this.tmpFormData);
  }

  // Delete action
  public deleteDialog(): void {
    this.dialogRef.close('delete')
  }
}
