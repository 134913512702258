import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { ACCOUNT_URLS } from 'src/app/constants/constants';
import {ApiService} from "../../services/api.service";
import {User} from "../../types/user.type";
import {NbGlobalPhysicalPosition, NbToastrService} from "@nebular/theme";
import {AuthGuard} from "../../services/auth.guard";
import { DarkModeService } from "../../services/darkmode.service";

interface formData {
  password: string;
  confirmPassword: string;
}

@Component({
  selector: 'app-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss']
})
export class EditPasswordComponent implements OnInit {

  public readonly ACCOUNT_URLS = ACCOUNT_URLS;

  // Variable to store the current user
  private selectedUser: User | null = null;

  /**
   * Form to store form data in this case both password input
   */
  public formData: formData = {
    password: '',
    confirmPassword: ''
  }

  constructor(
    public readonly router: Router,
    private readonly apiService: ApiService,
    private readonly authGuard: AuthGuard,
    private toastrService: NbToastrService,
    public darkmodeService: DarkModeService,
  ) { }

  ngOnInit(): void {
    /* Get user infos */
    this.authGuard.userInfo$.subscribe(user => {
      if (user) {
        this.selectedUser = user;
      }
    });
  }

  /**
   * This function check if the user inputs are the same and not empty
   * @returns boolean
   */
  public isSamePassword(): boolean {
    return this.formData.password === this.formData.confirmPassword && this.formData.password !== '' && this.formData.confirmPassword !== '';
  }

  // Function to call the edit-user api
  public editPassword(): void {
    const newPassword = {
      user_password: this.formData.confirmPassword
    }

    this.apiService.patch(`users/${this.selectedUser?.user_pid}`, newPassword).subscribe(() => {
      this.toastrService.show(
        'Votre mot de passe a été mis à jour',
        'Succès',
        { position: NbGlobalPhysicalPosition.BOTTOM_RIGHT, status: 'success' }
      );
      this.router.navigate([ACCOUNT_URLS.account])
    }, () => {
      this.toastrService.show(
        'Votre mot de passe n\'a pas pu être mis à jour',
        'Attention',
        { position: NbGlobalPhysicalPosition.BOTTOM_RIGHT, status: 'danger' }
      );
    })
  }
}
