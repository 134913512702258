<div class="header-grid">
  <div class="table-head">
    <div>
      <ParagraphAtom text="{{tableTitle}}" align="left" color="dark" weight="semi-bold" size="x-large"></ParagraphAtom>
      <input *ngIf="searchBar" (input)="onSearch($event)" type="text" nbInput shape="semi-round" placeholder="Rechercher un élément...">
    </div>
    <div>
      <div class="table-last-update">
        <nb-toggle *ngIf="shouldShowToggle()" [(checked)]="showUpdatedAt" (checkedChange)="toggleUpdatedAtColumn()"></nb-toggle>
        <ParagraphAtom *ngIf="shouldShowToggle()" size="medium" weight="regular" color="dark" text="Afficher dernière modification"></ParagraphAtom>
      </div>
      <div class="table-last-update">
        <nb-toggle *ngIf="shouldShowDownloadToggle()" [(checked)]="showDownloadAt" (checkedChange)="toggleDownloadAtColumn()"></nb-toggle>
        <ParagraphAtom *ngIf="shouldShowDownloadToggle()" size="medium" weight="regular" color="dark" text="Afficher dernier téléchargement client"></ParagraphAtom>
      </div>
      <button *ngIf="addButtonContent" nbButton status="primary" (click)="onAddClick()" size="medium">{{addButtonContent}}</button>
    </div>
  </div>

  <table>
    <thead>
    <tr>
      <th *ngFor="let header of headers">
        <span>{{ header }}</span>
        <button class="orderBtn" *ngIf="header === 'Réactualisation'" nbButton outline size="tiny" status="info" (click)="toggleSortOrder()">
          <nb-icon [icon]="sortOrder === 'asc' ? 'arrow-up-outline' : 'arrow-down-outline'"></nb-icon>
        </button>
      </th>
    </tr>
    </thead>
    <tbody>
    <!-- ngFor to display the data for clients and buildings -->
    <ng-container *ngFor="let clientOrBuilding of handlePage()[selectedPageNumber]">
      <tr>
        <!-- Cell for the "+" button -->
        <td *ngIf="isClientPage() || isAddressPage()">
          <button nbButton ghost size="small"
                  *ngIf="isClientPage()"
                  (click)="toggleClientDiagnostics(clientOrBuilding.client_pid)">
            <nb-icon [icon]="expandedClients[clientOrBuilding.client_pid] ? 'minus-outline' : 'plus-outline'"></nb-icon>
          </button>
          <button nbButton ghost size="small"
                  *ngIf="isAddressPage()"
                  (click)="toggleBuildingDiagnostics(clientOrBuilding.building_pid)">
            <nb-icon [icon]="expandedBuildings[clientOrBuilding.building_pid] ? 'minus-outline' : 'plus-outline'"></nb-icon>
          </button>
        </td>

        <!-- Other cells -->
        <td *ngFor="let param of params" [ngClass]="isClickable ? 'clickable' : ''" (click)="checkHeader(param, clientOrBuilding)">
          <ng-container *ngIf="param !== 'actions'; else actionButtons">
            {{ clientOrBuilding[param] }}
          </ng-container>
          <ng-template #actionButtons>
            <div class="btn-actions">
              <button nbButton [nbContextMenu]="parameters" (click)="setCurrentItem(clientOrBuilding)"><nb-icon icon="more-horizontal"></nb-icon></button>
            </div>
          </ng-template>
        </td>
      </tr>

      <!-- Sub-row for the diagnostics table for clients -->
      <tr *ngIf="isClientPage() && expandedClients[clientOrBuilding.client_pid]" class="diagnostics-row">
        <td [attr.colspan]="headers.length">
          <div *ngIf="diagnosticsLoading[clientOrBuilding.client_pid]">Chargement des diagnostics...</div>
          <div *ngIf="diagnosticsList[clientOrBuilding.client_pid] && diagnosticsList[clientOrBuilding.client_pid].length > 0">
            <table>
              <thead class="diagnostics-header">
              <tr>
                <th>Agence</th>
                <th>
                  <span>Bâtiment</span>
                  <button class="orderBtn" nbButton outline size="tiny" status="info" (click)="toggleSortOrderByBuilding()">
                    <nb-icon [icon]="sortOrderBuilding === 'asc' ? 'arrow-up-outline' : 'arrow-down-outline'"></nb-icon>
                  </button>
                </th>
                <th>N° dossier</th>
                <th>Type de diagnostic</th>
                <th>
                  <span>Réactualisation</span>
                  <button class="orderBtn" nbButton outline size="tiny" status="info" (click)="toggleSortOrderDiagnostics()">
                    <nb-icon [icon]="sortOrderDiagnostics === 'asc' ? 'arrow-up-outline' : 'arrow-down-outline'"></nb-icon>
                  </button>
                </th>
                <th>Avancement du dossier</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let diagnosis of diagnosticsList[clientOrBuilding.client_pid]" (click)="navigateToDiagnosis(diagnosis)">
                <td>{{ diagnosis.agency?.agency_name || 'N/A' }}</td>
                <td>{{ diagnosis.building.building_name }}</td>
                <td>{{ diagnosis.diagnosis_name }}</td>
                <td>{{ diagnosis.diagnosis_type }}</td>
                <td>{{ diagnosis.end_of_contract }}</td>
                <td>{{ diagnosis.customer_answer }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="!diagnosticsLoading[clientOrBuilding.client_pid] && (!diagnosticsList[clientOrBuilding.client_pid] || diagnosticsList[clientOrBuilding.client_pid].length === 0)">
            Aucun diagnostic trouvé pour ce client.
          </div>
        </td>
      </tr>

      <!-- Sub-row for the diagnostics table for buildings -->
      <tr *ngIf="isAddressPage() && expandedBuildings[clientOrBuilding.building_pid]" class="diagnostics-row">
        <td [attr.colspan]="headers.length">
          <div *ngIf="diagnosticsLoading[clientOrBuilding.building_pid]">Chargement des diagnostics...</div>
          <div *ngIf="diagnosticsList[clientOrBuilding.building_pid] && diagnosticsList[clientOrBuilding.building_pid].length > 0">
            <table>
              <thead class="diagnostics-header">
              <tr>
                <th>Client</th>
                <th>Agence</th>
                <th>N° dossier</th>
                <th>Type de diagnostic</th>
                <th>
                  <span>Réactualisation</span>
                  <button class="orderBtn" nbButton outline size="tiny" status="info" (click)="toggleSortOrderDiagnostics()">
                    <nb-icon [icon]="sortOrderDiagnostics === 'asc' ? 'arrow-up-outline' : 'arrow-down-outline'"></nb-icon>
                  </button>
                </th>
                <th>Avancement du dossier</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let diagnosis of diagnosticsList[clientOrBuilding.building_pid]" (click)="navigateToDiagnosis(diagnosis)">
                <td>{{ diagnosis.client?.client_name }}</td>
                <td>{{ diagnosis.agency?.agency_name || 'N/A' }}</td>
                <td>{{ diagnosis.diagnosis_name }}</td>
                <td>{{ diagnosis.diagnosis_type }}</td>
                <td>{{ diagnosis.end_of_contract }}</td>
                <td>{{ diagnosis.customer_answer }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="!diagnosticsLoading[clientOrBuilding.building_pid] && (!diagnosticsList[clientOrBuilding.building_pid] || diagnosticsList[clientOrBuilding.building_pid].length === 0)">
            Aucun diagnostic trouvé pour ce bâtiment.
          </div>
        </td>
      </tr>
    </ng-container>

    <!-- If no data for client or building -->
    <tr *ngIf="!dataList.length">
      <td [attr.colspan]="headers.length" class="empty-state">
        <ParagraphAtom *ngIf="!searchInput" text="Dossier vide" align="center" color="dark" weight="semi-bold" size="x-large"></ParagraphAtom>
        <ParagraphAtom *ngIf="searchInput" text="Aucun élément ne correspond à votre recherche" align="center" color="dark" weight="semi-bold" size="x-large"></ParagraphAtom>
      </td>
    </tr>
    </tbody>
  </table>
  <div *ngIf="dataList.length" class="table-footer">
    <div>
      <nb-select [size]="'tiny'" [(selected)]="selectedElementPerPage">
        <nb-select-label>
          {{ selectedElementPerPage }}
        </nb-select-label>

        <nb-option *ngFor="let number of elementSelectChoice" [value]="number">
          {{ number }}
        </nb-option>
      </nb-select>
    </div>
    <div>
      <ul>
        <li>
          <button *ngIf="darkmodeService.getDarkModeState()" class="button-arrow" [disabled]="disablePrevious()" size="medium" (click)="previousPage()"><nb-icon status="primary" icon="arrow-ios-back-outline"></nb-icon></button>
          <button *ngIf="!darkmodeService.getDarkModeState()" class="button-arrow" [disabled]="disablePrevious()" size="medium" (click)="previousPage()"><nb-icon icon="arrow-ios-back-outline"></nb-icon></button>
        </li>
        <li *ngFor="let i of numberOfPageList">
          <button size="medium" [ngClass]="{'selected-page': i - 1 === selectedPageNumber}" (click)="setPageNumber(i)">{{i}}</button>
          <ParagraphAtom *ngIf="displayDot(i)" text="..." align="center" color="dark" weight="semi-bold" size="large"></ParagraphAtom>
        </li>
        <li>
          <button *ngIf="darkmodeService.getDarkModeState()" class="button-arrow" [disabled]="disableNext()" size="medium" (click)="nextPage()"><nb-icon status="primary" icon="arrow-ios-forward-outline"></nb-icon></button>
          <button *ngIf="!darkmodeService.getDarkModeState()" class="button-arrow" [disabled]="disableNext()" size="medium" (click)="nextPage()"><nb-icon icon="arrow-ios-forward-outline"></nb-icon></button>
        </li>
      </ul>
    </div>
  </div>
</div>
