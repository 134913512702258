import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild, QueryList, AfterViewInit, ViewChildren } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { NbDialogService, NbDialogRef } from '@nebular/theme';
import { AuthGuard } from "../../services/auth.guard";

@Component({
  selector: 'app-comments-modal',
  templateUrl: './comments-modal.component.html',
  styleUrls: ['./comments-modal.component.scss']
})
export class CommentsModalComponent implements OnInit, AfterViewInit {
  @Input() resourcePid!: string;
  comments: any[] = [];
  newComment: string = '';
  dialogRef!: NbDialogRef<any>;

  @ViewChild('dialog', { static: true }) dialog!: TemplateRef<any>;
  @ViewChild('commentsSection') private commentsSection!: ElementRef;
  @ViewChildren('commentElement') commentElements!: QueryList<ElementRef>;

  // User info
  public user_pid: string | undefined = "";
  public user_email: string = "";
  public user_permissions: any = {};

  constructor(
    private apiService: ApiService,
    private authGuard: AuthGuard,
    private dialogService: NbDialogService
  ) {}

  ngOnInit(): void {
    this.loadComments();

    /* Get user permissions */
    this.authGuard.userPermissions$.subscribe(permissions => {
      if (permissions) {
        this.user_permissions = permissions;
      }
    });

    /* Get user infos */
    this.authGuard.userInfo$.subscribe(user => {
      if (user) {
        this.user_pid = user.user_pid;
      }
    });
  }

  ngAfterViewInit(): void {
    this.scrollToBottom();
  }

  openModal(): void {
    this.dialogRef = this.dialogService.open(this.dialog, {
      context: {
        comments: this.comments,
        newComment: this.newComment,
      },
    });

    setTimeout(() => {
      this.scrollToBottom();
    }, 0);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  loadComments(): void {
    this.apiService.get(`comments?resource_pid=${this.resourcePid}`).subscribe((response) => {
      if (response.comments) {
        this.comments = response.comments.sort((a: any, b: any) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());

        setTimeout(() => {
          this.scrollToBottom();
        }, 0);
      } else {
        this.comments = [];
      }
    });
  }

  submitComment(): void {
    if (this.newComment.trim() === '') return;

    const body = { resource_pid: this.resourcePid, user_pid: this.user_pid, message: this.newComment };
    this.apiService.post('comments', body).subscribe(() => {
      this.newComment = '';
      this.loadComments();
    });
  }

  private scrollToBottom(): void {
    try {
      if (this.commentElements.length > 0) {
        const lastComment = this.commentElements.last.nativeElement;
        lastComment.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      }
    } catch (err) {
      console.error('Scroll to bottom failed', err);
    }
  }

  canViewComments(): boolean {
    return !!this.user_permissions && this.user_permissions.comments && this.user_permissions.comments !== 'NONE';
  }

  canWriteComments(): boolean {
    return !!this.user_permissions && this.user_permissions.comments && this.user_permissions.comments === 'WRITE';
  }
}
