<div class="dialog-container">
  <div>
    <TitleAtom color="black" size="medium" weight="semi-bold" type="h2" text="{{ title }} formation"></TitleAtom>

    <!-- Dialog body content -->
    <div class="dialog-body">

      <ul>
        <li>
          <div>
            <ParagraphAtom text="Nature des autres formations en hygiène et sécurité suivies par le personnel" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
            <input type="text" nbInput [(ngModel)]="tmpFormData.formation" placeholder="Nature des autres formations en hygiène et sécurité suivies par le personnel...">
          </div>
          <div>
            <ParagraphAtom text="Nombre de personnes" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
            <input type="number" min="0" nbInput [(ngModel)]="tmpFormData.numberOfPerson" placeholder="Nombre...">
          </div>
        </li>
      </ul>
    </div>

    <!--Dialog action button-->
    <div>
      <button *ngIf="formData" nbButton status="danger" (click)="deleteDialog()">Supprimer</button>
      <button nbButton status="danger" (click)="closeDialog()">Annuler</button>
      <button nbButton status="primary" [disabled]="!isFormEmpty()" (click)="confirmAction()">Sauvegarder</button>
    </div>
  </div>
</div>
