<div class="agencies-container">
  <Sidebar></Sidebar>
  <div class="agencies-page padding">

    <!-- AGENCIES PAGE TITLE -->
    <div class="agencies-title">
      <TitleAtom size="large" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Créer une nouvelle agence"></TitleAtom>
    </div>

    <!-- CREATE AGENCY SECTION -->
    <!-- AGENCIES HEADER -->
    <div class="create-header">
      <button nbButton (click)="returnToAgencies()"><nb-icon icon="arrow-ios-back-outline"></nb-icon>Retour</button>
    </div>

    <!-- CREATE FORM -->
    <div class="agencies-create-container">
      <TitleAtom size="medium" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Renseignez les informations de la nouvelle agence"></TitleAtom>

      <ul>
        <li>
          <div>
            <ParagraphAtom text="Nom de l'agence" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <input type="text" nbInput [(ngModel)]="formData.name" placeholder="Nom...">
          </div>
        </li>
        <li class="form-email">
          <ParagraphAtom text="Email" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
          <input type="email" nbInput fullWidth [(ngModel)]="formData.email" placeholder="Email...">
          <ParagraphAtom *ngIf="!isEmailValid" text="L'email n'est pas valide" color="red" size="nano" weight="semi-bold"></ParagraphAtom>
        </li>
        <li class="form-email">
          <ParagraphAtom text="Adresse" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
          <input type="email" nbInput fullWidth [(ngModel)]="formData.address" placeholder="Adresse...">
        </li>
        <li>
          <div>
            <ParagraphAtom text="Code Postal" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <input type="text" nbInput fullWidth [(ngModel)]="formData.postalCode" placeholder="Code Postal...">
          </div>
          <div>
            <ParagraphAtom text="Ville" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <input type="text" nbInput fullWidth [(ngModel)]="formData.city" placeholder="Ville...">
          </div>
        </li>
        <li>
          <div>
            <ParagraphAtom text="Sélectionnez les utilisateurs" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <nb-select fullWidth [(selected)]="selectedUsers" multiple>
              <nb-select-label>
                {{ selectedUsers.length }} utilisateurs sélectionnés
              </nb-select-label>

              <nb-option *ngFor="let user of userList" [value]="user">
                {{ user.user_firstName | titlecase }} {{ user.user_lastName | titlecase }}
              </nb-option>
            </nb-select>
          </div>
        </li>
        <li class="form-email">
          <button nbButton fullWidth status="primary" [disabled]="!isFormEmpty() || isLoading" (click)="verifyForm()">Sauvegarder</button>
        </li>
      </ul>
    </div>
  </div>
</div>
