import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {APPOINTMENT_URLS, DIAGNOSIS_TYPES, STATUS_OPTIONS} from "../../constants/constants";
import {Client} from "../../types/client.type";
import {Building} from "../../types/building.type";
import {User} from "../../types/user.type";
import {ApiService} from "../../services/api.service";
import {Agency} from "../../types/agency.type";
import {Appointment} from "../../types/appointment.type";
import {DatePipe} from "@angular/common";
import { DarkModeService } from "../../services/darkmode.service";

export interface DiagnosisTypeOption {
  label: string;
  value: string;
}

@Component({
  selector: 'app-appointment-create',
  templateUrl: './appointment-create.component.html',
  styleUrls: ['./appointment-create.component.scss']
})
export class AppointmentCreateComponent implements OnInit {

  // Variable to store the appointment day
  public appointment_day: string = "";

  // If loading
  public isLoading: boolean = false;

  // List to store every user
  public userList: User[] = [];

  // List to store every client
  public clientList: Client[] = [];

  // List to store every agency
  public agencyList: Agency[] = []

  // List to store every building
  public buildingList: Building[] = []

  // Variable to store the selected user
  public selectedUsers: User[] = []

  // Variable to store the selected client
  public selectedClient: Client | null = null

  // Variable to store the selected agency
  public selectedAgency: Agency | null = null

  // Variable to store the selected building
  public selectedBuilding: Building | null = null

  // Variable to set the diagnosis type
  public selectedDiagnosisType: DiagnosisTypeOption | null = null;
  public diagnosisTypes: DiagnosisTypeOption[] = DIAGNOSIS_TYPES;

  // Variable to store the selected status
  public selectedStatus: string = STATUS_OPTIONS[0];
  public statusOptions: string[] = STATUS_OPTIONS;

  // Boolean to show agency select
  public showAgency: Boolean = false;

  // Boolean to show building select
  public showBuilding: Boolean = false;

  // When the client doesn't have any agency
  public foundAgency: Boolean = true;

  // When there's no building found
  public foundBuilding: Boolean = true;

  // To define the min date
  public minDate: Date = new Date(new Date().setHours(0, 0, 0, 0));

  constructor(public router: Router,
              private apiService: ApiService,
              private datePipe: DatePipe,
              public darkmodeService: DarkModeService,
  ) { }

  ngOnInit(): void {
    this.apiService.get('users').subscribe((returnedList) => {
      this.userList = returnedList.data
    });
    this.apiService.get('clients').subscribe((returnedList) => {
      this.clientList = returnedList.data;
    });

    if (this.diagnosisTypes.length > 0) {
      this.selectedDiagnosisType = this.diagnosisTypes[0];
    }

  }

  // Function that is activated when the user select a client
  public onClientSelect(client: Client): void {
    if (client.client_type !== "particulier") {
      this.foundAgency = true;
      this.foundBuilding = true;
      this.showBuilding = false;
      this.selectedAgency = null
      this.apiService.get(`clients/${client.client_pid}/agencies`).subscribe((returnedList) => {
        this.agencyList = returnedList.data;
        this.showAgency = true;
      }, () => {
        this.foundAgency = false;
        this.showAgency = false;
      })
    } else {
      this.foundBuilding = true;
      this.foundAgency = true;
      this.showAgency = false;
      this.selectedBuilding = null;
      this.apiService.get(`clients/${client.client_pid}/buildings`).subscribe((returnedList) => {
        this.buildingList = returnedList.data;
        this.showBuilding = true;
      }, () => {
        this.foundAgency = false;
        this.showBuilding = false;
      })
    }
  }

  // Function that is activated when the user selected an agency
  public onAgencySelect(agency: Agency): void {
    this.selectedBuilding = null
    this.apiService.get(`agencies/${agency.agency_pid}/buildings`).subscribe((returnedList) => {
      this.buildingList = returnedList.data;
      this.showBuilding = true;
      this.foundBuilding = true;
    }, () => {
      this.foundBuilding = false;
      this.showBuilding = false;
    })
  }

  // Sends the user back to the appointment list page
  public returnToAppointment(): void {
    this.router.navigate([APPOINTMENT_URLS.list])
  }

  // Function that checks if the form is valid
  public isFormValid(): boolean {
    if (this.selectedClient?.client_type !== "particulier") {
      return !!(this.selectedUsers.length && this.selectedClient?.client_pid && this.selectedAgency?.agency_pid && this.selectedBuilding?.building_pid && this.appointment_day && this.selectedStatus)
    } else {
      return !!(this.selectedUsers.length && this.selectedClient?.client_pid && this.selectedBuilding?.building_pid && this.appointment_day && this.selectedStatus)
    }
  }

  // Function to create the appointment
  public createAppointment(): void {
    this.isLoading = true;
    const formatedAppointmentDay = this.datePipe.transform(this.appointment_day, 'dd/MM/yyyy');

    const appointmentData: Appointment = {
      user_pid: this.selectedUsers.map(user => user.user_pid!).filter(pid => pid !== undefined) as string[],
      user_email: this.selectedUsers.map(user => user.user_email),
      client_pid: this.selectedClient!.client_pid!,
      client_name: this.selectedClient!.client_name,
      building_pid: this.selectedBuilding!.building_pid!,
      building_name: this.selectedBuilding!.building_name,
      appointment_date: formatedAppointmentDay!,
      diagnosis_type: this.selectedDiagnosisType!.value,
      status: this.selectedStatus,
    }

    this.apiService.post('appointments', appointmentData).subscribe(() => {
      this.returnToAppointment();
      this.isLoading = false;
    })
  }

  // Method to get selected users' emails as a string
  getSelectedUserEmails(): string {
    return this.selectedUsers.map(user => user.user_email).join(', ');
  }
}
