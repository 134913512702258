import {Component, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {AGENCY_URLS} from "../../constants/constants";
import { Client } from 'src/app/types/client.type';
import { Agency } from 'src/app/types/agency.type';
import {ApiService} from "../../services/api.service";
import {HelperService} from "../../services/helper.service";
import { DarkModeService } from "../../services/darkmode.service";
import { User } from "../../types/user.type";

@Component({
  selector: 'app-agency-edit',
  templateUrl: './agency-edit.component.html',
  styleUrls: ['./agency-edit.component.scss']
})
export class AgencyEditComponent implements OnInit {

  // To know which client is selected
  public selectedClient: Client | null = null;

  // If loading
  public isLoading: boolean = false;

  // To know which agency is selected
  public selectedAgency: Agency | null = null;

  // List to store every user
  public userList: User[] = [];

  // Value to store the selected user
  public selectedUsers: User[] = [];

  // Boolean to know if the email follow the regex norms
  public isEmailValid: boolean = true;

  // Stores an empty user
  public defaultUser: User = {
    user_firstName: "AUCUN",
    user_lastName: "",
    user_email: "",
  }

  constructor(public router: Router,
              private route: ActivatedRoute,
              private apiService: ApiService,
              private helperService: HelperService,
              public darkmodeService: DarkModeService,
  ) { }

  ngOnInit(): void {
    // Get the client and agency selected
    this.route.queryParams.subscribe(params => {
      if (params['client']) {
        this.selectedClient = JSON.parse(params['client']);
      }
      if (params['agency']) {
        this.selectedAgency = JSON.parse(params['agency']);
      }
    });

    // Get users selected
    this.apiService.get('users').subscribe((userList) => {
      this.userList = userList.data;
      this.userList.unshift(this.defaultUser);
      this.loadSelectedUsers();
    });

  }

  /**
   * Function to load the selected users for the current agency
   */
  private loadSelectedUsers(): void {
    if (this.selectedAgency) {
      this.apiService.get(`agencies/${this.selectedAgency.agency_pid}/users`).subscribe((response) => {
        const selectedUserPids = response.data.map((user: User) => user.user_pid);
        this.selectedUsers = this.userList.filter(user => selectedUserPids.includes(user.user_pid));
      }, (error) => {
        console.error('Failed to load users for agency:', error);
      });
    }
  }

  /**
   * Function to check if the form is empty
   */
  public isFormEmpty(): boolean {
    return this.selectedAgency?.agency_name !== '' && this.selectedAgency?.agency_email !== '' && this.selectedAgency?.agency_address !== '' && this.selectedAgency?.agency_city !== '' && this.selectedAgency?.agency_postalCode !== '';
  }

  /**
   * Function to return to agencies page with the client selected
   */
  public returnToAgencies(): void {
    this.router.navigate([AGENCY_URLS.list], { queryParams: { client: this.selectedClient?.client_pid } });
  }

  /**
   * Function to send data to edit agency
   * @private
   */
  private editAgency(): void {
    this.isLoading = true;
    const updatedBody = {
      agency_name: this.selectedAgency?.agency_name,
      agency_email: this.selectedAgency?.agency_email,
      agency_address: this.selectedAgency?.agency_address,
      agency_city: this.selectedAgency?.agency_city,
      agency_postalCode: this.selectedAgency?.agency_postalCode,
      user_pids: this.selectedUsers.map(user => user.user_pid),
    }

    this.apiService.patch(`agencies/${this.selectedAgency?.agency_pid}`, updatedBody).subscribe((response) => {
      this.returnToAgencies()
      this.isLoading = false;
    })
  }

  /**
   * Function that check if agency's email respect the regex, if it does update agency's data
   */
  public verifyForm(): void {
    this.isEmailValid = this.helperService.emailRegex.test(this.selectedAgency!.agency_email)
    if (this.isEmailValid)
      this.editAgency();
  }

}
