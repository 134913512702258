import { Component, OnInit } from '@angular/core';
import { AuthGuard } from "../../services/auth.guard";

@Component({
  selector: 'app-redirect',
  template: '',
})
export class RedirectComponent implements OnInit {

  constructor(private authGuard: AuthGuard) {}

  ngOnInit(): void {
    this.authGuard.redirectToAppropriatePage();
  }
}
