<div class="diagnoses-container">
  <Sidebar></Sidebar>
  <div class="diagnoses-page padding">

    <!-- DIAGNOSES PAGE TITLE -->
    <div class="diagnoses-title">
      <Breadcrumb *ngIf="selectedAgency"
                  [links]="[
            {title: selectedClient?.client_name || '', link: CLIENT_URLS.list, icon: 'client-grey', permission: 'clients'},
            {title: selectedAgency?.agency_name || '', link: AGENCY_URLS.list, icon: 'agency-grey', queryParams: { client: selectedClient?.client_pid }, permission: 'agencies'},
            {title: selectedBuilding?.building_name || '', link: BUILDING_URLS.list, icon: 'building-grey', queryParams: { client: selectedClient?.client_pid, agency: selectedAgency?.agency_pid }, permission: 'buildings'},
            {title: 'Diagnostics', link: DIAGNOSIS_URLS.list, icon: darkmodeService.getDarkModeState() ? 'diagnostic-light' : 'diagnostic-dark', permission: 'diagnosis'}
          ]">
      </Breadcrumb>
      <Breadcrumb *ngIf="!selectedAgency"
                  [links]="[
            {title: selectedClient?.client_name || '', link: CLIENT_URLS.list, icon: 'client-grey', permission: 'clients'},
            {title: selectedBuilding?.building_name || '', link: BUILDING_URLS.list, icon: 'building-grey', queryParams: { client: selectedClient?.client_pid }, permission: 'buildings'},
            {title: 'Diagnostics', link: DIAGNOSIS_URLS.list, icon: darkmodeService.getDarkModeState() ? 'diagnostic-light' : 'diagnostic-dark', permission: 'diagnosis'}
          ]">
      </Breadcrumb>
      <div class="diagnoses-btn-container">
        <button nbButton (click)="backToBuildings()"><nb-icon icon="arrow-ios-back-outline"></nb-icon>Retour</button>
        <app-comments-modal [resourcePid]="selectedBuilding?.building_pid || ''"></app-comments-modal>
        <button [disabled]="!this.diagnosesList.length" class="excel-btn" nbButton status="success" (click)="exportToExcel()">Exportation excel</button>
      </div>
    </div>

    <ul>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par nom"></ParagraphAtom>
        <input [(ngModel)]="filterName" (input)="onNameSearch($event)" type="text" nbInput shape="rectangle" placeholder="Rechercher par N° de dossier...">
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par type"></ParagraphAtom>
        <nb-select placeholder="Type" [(selected)]="filterTypes" multiple>
          <nb-option *ngFor="let type of DIAGNOSIS_TYPES" [value]="type.value">
            {{ type.label }}
          </nb-option>
        </nb-select>
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par date de réactualisation"></ParagraphAtom>
        <nb-select placeholder="Trier par" [(selected)]="sortOrder">
          <nb-option *ngFor="let type of DATE_FILTER" [value]="type.value">{{ type.label }}</nb-option>
        </nb-select>
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par date précise"></ParagraphAtom>
        <input nbInput [nbDatepicker]="specificDatePicker" placeholder="Date Précise..." [(ngModel)]="specificDate">
        <nb-datepicker class="datepicker" #specificDatePicker></nb-datepicker>
      </li>
    </ul>

    <ul class="diagnoses-filters-btn">
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par mois"></ParagraphAtom>
        <nb-select placeholder="Mois" [(selected)]="filterMonth">
          <nb-option *ngFor="let month of MONTHS_LIST" [value]="month.value">
            {{ month.label }}
          </nb-option>
        </nb-select>
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par une année précise"></ParagraphAtom>
        <input type="number" nbInput placeholder="Année (ex. 2023)" [(ngModel)]="filterYear">
      </li>
      <li>
        <button nbButton outline status="warning" (click)="resetDateFilter()">Réinitialiser</button>
      </li>
      <li>
        <button nbButton outline status="primary" (click)="applyDateFilter()">Filtrer</button>
      </li>
    </ul>

    <!-- DIAGNOSES LIST -->
    <div class="diagnoses-list-container">
      <table-header *ngIf="hasPermission('diagnosis')" addButtonContent="Ajouter un diagnostique" tableTitle="Diagnostiques" [searchBar]="false" [isClickable]="true" [headers]="DIAGNOSIS_HEADERS.permission" [dataList]="filteredDiagnosesList" [params]="['diagnosis_name', 'diagnosis_type', 'start_of_contract', 'end_of_contract', 'created_at', 'customer_answer', 'actions']" (rowClicked)="navigateToFiles($event)" (editClicked)="editDiagnosis($event)" (deleteClicked)="deleteDiagnosis($event)" (addClicked)="navigateToCreateDiagnosis()"></table-header>
      <table-header *ngIf="!hasPermission('diagnosis')" tableTitle="Diagnostiques" [searchBar]="false" [isClickable]="true" [headers]="DIAGNOSIS_HEADERS.noPermission" [dataList]="filteredDiagnosesList" [params]="['diagnosis_name', 'diagnosis_type', 'start_of_contract', 'end_of_contract', 'created_at']" (rowClicked)="navigateToFiles($event)" (editClicked)="editDiagnosis($event)" (deleteClicked)="deleteDiagnosis($event)" (addClicked)="navigateToCreateDiagnosis()"></table-header>
    </div>

  </div>
</div>
