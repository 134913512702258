import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {AGENCY_URLS, CLIENT_URLS, HOME_URLS, CLIENT_TYPES, BUILDING_URLS} from 'src/app/constants/constants';
import { ApiService} from "../../services/api.service";
import { StorageService} from "../../services/storage.service";
import {HelperService} from "../../services/helper.service";
import { AuthGuard } from "../../services/auth.guard";
import { DarkModeService } from "../../services/darkmode.service";

interface FormData {
  user_email: string,
  user_password: string
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  // If loading
  public isLoading: boolean = false;

  public readonly CLIENT_URLS = CLIENT_URLS;

  // Boolean to check if the email is valid
  public isEmailValid: boolean = true;

  // Boolean to check if identifiers are correct
  public isIdentifiersValid: boolean = true;

  // Login Form data
  public formData: FormData = {
    user_email: '',
    user_password: '',
  }

  constructor(public router: Router,
              private readonly helperService: HelperService,
              private apiService: ApiService,
              private storage: StorageService,
              private authGuard: AuthGuard,
              public darkmodeService: DarkModeService,
  ) { }

  ngOnInit(): void {
  }

  /**
   * Function to sign in
   */
  public login(): void {
    this.isLoading = true;
    if (this.isFormValid()) {
      const userData = {
        user_email: this.formData.user_email,
        user_password: this.formData.user_password
      };

      this.apiService.post('sign/in', userData).subscribe(response => {
        if (response) {
          const jwtToken = response.token;
          this.storage.setItem('jwtToken', jwtToken);
          this.authGuard.setUserPermissions(response.permissions);

          const headers = { 'x-api-key': jwtToken };
          this.apiService.get('sign/check', {}, headers).subscribe(response => {
            this.authGuard.setUserInfos(response.user, response.client_type, response.client_pid, response.agency_pid);

            // Check if the user has buildings
            this.apiService.get(`users/${response.user.user_pid}/buildings`).subscribe(buildingResponse => {
              const userBuildings = buildingResponse.data;

              if (userBuildings.length > 0) {
                // Redirect to the buildings page if the user has buildings
                const firstBuilding = userBuildings[0]; // Récupérer le premier bâtiment
                this.router.navigate([BUILDING_URLS.list], {
                  queryParams: {
                    client: firstBuilding.client_pid,  // Assure-toi d'utiliser le client_pid du building
                    agency: firstBuilding.agency_pid
                  }
                });
              } else if (response.agency_pid) {
                // Redirect to the buildings page if the user has an agency
                this.router.navigate([BUILDING_URLS.list], {
                  queryParams: {
                    client: response.client_pid,
                    agency: response.agency_pid
                  }
                });
              } else if (response.client_pid && (response.client_type === CLIENT_TYPES.SYNDIC || response.client_type === CLIENT_TYPES.SOCIETE)) {
                // Redirect to the agencies page if the user is a syndic or a company
                this.router.navigate([AGENCY_URLS.list], {
                  queryParams: { client: response.client_pid }
                });
              } else if (response.client_pid && response.client_type === CLIENT_TYPES.PARTICULIER) {
                // Redirect to the buildings page if the user is a particulier
                this.router.navigate([BUILDING_URLS.list], {
                  queryParams: { client: response.client_pid }
                });
              } else {
                this.router.navigate([CLIENT_URLS.list]);
              }
            });

            this.isLoading = false;
          }, error => {
            this.isLoading = false;
          });
        }
      }, error => {
        this.isIdentifiersValid = false;
        this.isLoading = false;
      });
    }
  }

  /**
   * Function to check if email is valid and if input is empty
   */
  public isFormValid = (): boolean => {
    return this.helperService.emailRegex.test(this.formData.user_email) && this.formData.user_email.length > 0 && this.formData.user_password.length > 0;
  }

}
