import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {
  CLIENT_HEADERS,
  CLIENT_TYPES,
  CLIENT_URLS,
  DIAGNOSIS_TYPES, MONTHS_LIST, ADDRESSES_URLS, ADDRESSES_HEADERS
} from '../../constants/constants';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { NbDialogService } from "@nebular/theme";
import { AuthGuard } from "../../services/auth.guard";
import { DarkModeService } from "../../services/darkmode.service";
import { DataService } from 'src/app/services/data.service';
import { TableHeaderComponent } from "../../molecule/table-header/table-header.component";
import { Building } from "../../types/building.type";
import * as XLSX from 'xlsx';
import { DiagnosticsService } from '../../services/diagnoses.service';

@Component({
  selector: 'app-adresses',
  templateUrl: './adresses.component.html',
  styleUrls: ['./adresses.component.scss']
})
export class AdressesComponent implements OnInit {

  public readonly CLIENT_URLS = CLIENT_URLS;
  public readonly CLIENT_HEADERS = CLIENT_HEADERS
  public readonly ADDRESSES_HEADERS = ADDRESSES_HEADERS;
  public readonly MONTHS_LIST = MONTHS_LIST;
  public readonly ADDRESSES_URLS = ADDRESSES_URLS;

  // Buildings list objects
  @Input() buildingsList: Building[] = [];

  // Table header component
  @ViewChild('tableHeaderComponent') tableHeaderComponent!: TableHeaderComponent;

  // To know which client is selected
  public selectedBuilding: Building | null = null;

  // Search value
  public filteredBuildingsList: Building[] = [];

  // Loading
  public isLoading: boolean = false;

  // Init Filters
  agencyNameFilter: string = '';
  clientNameFilter: string = '';
  nameFilter: string = '';
  cityFilter: string = '';
  postalCodeFilter: string = '';
  filterClientType: string[] = [];
  diagnosisTypeFilter: string[] = [];
  filterYear: number | null = null;
  filterMonth: number | null = null;
  clientTypesArray = Object.entries(CLIENT_TYPES).map(([key, value]) => ({ label: key, value }));
  diagnosisTypesArray = DIAGNOSIS_TYPES;
  diagnosisNameFilter: string = '';

  public diagnosticsList: { [key: string]: any[] } = {};


  constructor(public router: Router,
    private apiService: ApiService,
    private dialogService: NbDialogService,
    private authGuard: AuthGuard,
    public darkmodeService: DarkModeService,
    private dataService: DataService,
    private diagnosticsService: DiagnosticsService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    // Fetch the buildings list
    this.apiService.get('buildings').subscribe(response => {
      this.buildingsList = response.data;
      this.filteredBuildingsList = [...this.buildingsList];

      // Fetch diagnostics for each building
      this.buildingsList.forEach(building => {
        if (building.building_pid) {
          this.apiService.get(`diagnoses/${building.building_pid as string}`).subscribe(diagnosisResponse => {
            this.diagnosticsService.setDiagnostics(building.building_pid as string, diagnosisResponse.data || []);
          });
        }
      });
      this.isLoading = false;
    });
  }


  /**
   * Function to export table data into excel file
   * @returns - excel file
   */
  public exportToExcel(): void {
    this.isLoading = true;
    // Vérifiez si la liste filtrée contient des données
    if (this.filteredBuildingsList.length === 0) {
      this.isLoading = false;
      return;
    }

    // Prepare a list of API requests for each building_pid
    const diagnosticRequests = this.filteredBuildingsList.map(building => {
      if (building.building_pid) {
        return this.apiService.get(`diagnoses/${building.building_pid}/buildings`).toPromise().then(response => {
          this.diagnosticsList[building.building_pid as string] = response.diagnoses || [];
        }).catch(error => {
          console.error(`Erreur lors de la récupération des diagnostics pour le bâtiment ${building.building_pid}:`, error);
          this.diagnosticsList[building.building_pid as string] = [];
        });
      } else {
        // returns a resolved promise by default if building_pid is not defined
        return Promise.resolve();
      }
    });

    // Wait until all API requests have been completed
    Promise.all(diagnosticRequests).then(() => {
      this.isLoading = false;
      // Build the data to be exported by browsing the list of filtered buildings
      const exportData = this.filteredBuildingsList.map((building) => {
        // Use recovered diagnostics
        const diagnostics = this.diagnosticsList[building.building_pid as string] || [];

        // If no diagnostics, create a default entry
        if (diagnostics.length === 0) {
          return {
            'Nom de l\'immeuble': building.building_name,
            'Code postal': building.building_postalCode,
            'Ville': building.building_city,
            'Total diagnostics': building.total_diagnostics,
            'Nom du client': 'N/A',
            'Nom de l\'agence': 'N/A',
            'N° dossier': 'N/A',
            'Type de diagnostic': 'N/A',
            'Réactualisation': 'N/A',
            'Réponse client': 'N/A',
          };
        }

        // If diagnostics exist, map them
        return diagnostics.map((diagnosis) => ({
          'Nom de l\'immeuble': building.building_name,
          'Code postal': building.building_postalCode,
          'Ville': building.building_city,
          'Total diagnostics': building.total_diagnostics,
          'Nom du client': diagnosis.client?.client_name || 'N/A',
          'Nom de l\'agence': diagnosis.agency?.agency_name || 'N/A',
          'N° dossier': diagnosis.diagnosis_name,
          'Type de diagnostic': diagnosis.diagnosis_type,
          'Réactualisation': diagnosis.end_of_contract,
          'Réponse client': diagnosis.customer_answer,
        }));
      }).flat();

      // Create the spreadsheet from the data
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
      const workbook: XLSX.WorkBook = { Sheets: { 'Adresses': worksheet }, SheetNames: ['Adresses'] };

      // Name for Excel file
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      const fileName = `adresses_export_${timestamp}.xlsx`;

      // Export the file
      XLSX.writeFile(workbook, fileName);
    }).catch(error => {
      console.error('Erreur lors de la récupération des diagnostics:', error);
      this.isLoading = false;
    });
  }

  /**
   * Function to search a client
   * @param input
   */
  public onBuildingSearch(input: string): void {
    this.filteredBuildingsList = this.buildingsList.filter(building =>
      building.building_name.toLowerCase().includes(input)
    );
  }

  /**
   * Function to apply filters
   */
  public applyFilters(): void {
    const trimmedNameFilter = this.nameFilter.trim();
    const trimmedAgencyNameFilter = this.agencyNameFilter.trim();
    const trimmedClientNameFilter = this.clientNameFilter.trim();
    const trimmedDiagnosisNameFilter = this.diagnosisNameFilter.trim();

    // Define filters
    const filters = {
      agencyNameFilter: trimmedAgencyNameFilter || '',
      clientNameFilter: trimmedClientNameFilter || '',
      nameFilter: trimmedNameFilter || '',
      cityFilter: this.cityFilter || '',
      postalCodeFilter: this.postalCodeFilter || '',
      filterClientType: this.filterClientType.length ? this.filterClientType.join(',') : '',
      diagnosisTypeFilter: this.diagnosisTypeFilter.length ? this.diagnosisTypeFilter.join(',') : '',
      filterYear: this.filterYear ? this.filterYear.toString() : '',
      filterMonth: this.filterMonth ? this.filterMonth.toString() : '',
      diagnosisNameFilter: trimmedDiagnosisNameFilter || ''
    };

    // Convert filters to query params
    const queryParams = Object.entries(filters)
      .filter(([_, value]) => value)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');

    // Call the API to get the filtered clients
    this.apiService.get(`buildings?${queryParams}`).subscribe(response => {
      this.filteredBuildingsList = response.data || [];

      // Call reloadDiagnostics function from table header component to reload the diagnostics
      this.tableHeaderComponent.reloadDiagnostics();
    }, error => {
      console.error('Error fetching filtered buildings:', error);
      this.filteredBuildingsList = [];
    });
  }

  /**
   * Function to reset filters
   */
  public resetFilters(): void {
    this.agencyNameFilter = '';
    this.clientNameFilter = '';
    this.nameFilter = '';
    this.cityFilter = '';
    this.postalCodeFilter = '';
    this.filterClientType = [];
    this.diagnosisTypeFilter = [];
    this.filterYear = null;
    this.filterMonth = null;
    this.diagnosisNameFilter = '';
    this.applyFilters();
  }

  /**
   * Check if user has permissions
   * @param permissionKey
   */
  public hasPermission(permissionKey: string): boolean {
    const userPermissions = this.authGuard.getUserPermissions();

    if (userPermissions) {
      return userPermissions[permissionKey] && userPermissions[permissionKey] === 'WRITE';
    } else {
      return false;
    }
  }

  protected readonly DIAGNOSIS_TYPES = DIAGNOSIS_TYPES;
  protected readonly CLIENT_TYPES = CLIENT_TYPES;
}
