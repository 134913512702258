import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { DIAGNOSIS_TYPES } from "../../constants/constants";
import { DIAGNOSIS_URLS } from "../../constants/constants";
import { Client } from 'src/app/types/client.type';
import { Agency } from 'src/app/types/agency.type';
import { Building } from 'src/app/types/building.type';
import { DatePipe } from '@angular/common';
import {ApiService} from "../../services/api.service";
import { DarkModeService } from "../../services/darkmode.service";

interface FormData {
  diagnosis_pid: string,
  diagnosis_name: string,
  start_of_contract: Date | null,
  end_of_contract: Date | null,
  diagnosis_type: string,
  progress_status: string
}

@Component({
  selector: 'app-diagnosis-edit',
  templateUrl: './diagnosis-edit.component.html',
  styleUrls: ['./diagnosis-edit.component.scss']
})
export class DiagnosisEditComponent implements OnInit {

  // Get the diagnosis types
  public diagnosisTypes = DIAGNOSIS_TYPES;

  // If loading
  public isLoading: boolean = false;

  // Form datas
  public selectedDiagnosis: FormData = {
    diagnosis_pid: '',
    diagnosis_name: '',
    start_of_contract: null as Date | null,
    end_of_contract: null as Date | null,
    diagnosis_type: DIAGNOSIS_TYPES[0].value,
    progress_status: 'non défini'
  }

  // To define the min date
  public minDate: Date = new Date(new Date().setHours(0, 0, 0, 0));

  // To know which client/building is selected
  public selectedClient: Client | null = null;
  public selectedAgency: Agency | null = null;
  public selectedBuilding: Building | null = null;

  // To display the date error message
  public showDateError: boolean = false;

  constructor(public router: Router,
              private route: ActivatedRoute,
              private datePipe: DatePipe,
              private apiService: ApiService,
              public darkmodeService: DarkModeService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['client']) {
        this.selectedClient = JSON.parse(params['client']);
      }
      if (params['agency']) {
        this.selectedAgency = JSON.parse(params['agency']);
      }
      if (params['building']) {
        this.selectedBuilding = JSON.parse(params['building']);
      }
      if (params['diagnosis']) {
        const diagnosisObject = JSON.parse(params['diagnosis']);
        this.selectedDiagnosis.diagnosis_pid = diagnosisObject.diagnosis_pid;
        this.selectedDiagnosis.diagnosis_name = diagnosisObject.diagnosis_name;
        this.selectedDiagnosis.diagnosis_type = diagnosisObject.diagnosis_type;
        this.selectedDiagnosis.progress_status = diagnosisObject.progress_status;
        // The 'startOfContract' date from queryParams is in "DD/MM/YYYY" format. We need to convert it to a Date object
        const [startDay, startMonth, startYear] = diagnosisObject.start_of_contract.split('/').map(Number);
        this.selectedDiagnosis.start_of_contract = new Date(startYear, startMonth - 1, startDay);

        // Min date to compare start_of_contract and end_of_contract time
        this.minDate = this.selectedDiagnosis.start_of_contract;

        // The 'endOfContract' date from queryParams is in "DD/MM/YYYY" format. We need to convert it to a Date object
        const [endDay, endMonth, endYear] = diagnosisObject.end_of_contract.split('/').map(Number);
        this.selectedDiagnosis.end_of_contract = new Date(endYear, endMonth - 1, endDay);
      }
    });
  }

  /**
   * Function to check if the form is valid
   */
  public isFormValid(): boolean {
    // The end_of_contract value have to be inferior to start_of_contract
    if (this.selectedDiagnosis.diagnosis_name !== '' && this.selectedDiagnosis.start_of_contract && this.selectedDiagnosis.end_of_contract) {
      const isValid = this.selectedDiagnosis.end_of_contract >= this.selectedDiagnosis.start_of_contract;
      this.showDateError = !isValid;
      return isValid;
    }
    return false;
  }

  /**
   * Function to send data to edit diagnosis
   */
  public editDiagnosis(): void {
    this.isLoading = true;
    const formatedStartOfContract = this.datePipe.transform(this.selectedDiagnosis.start_of_contract, 'dd/MM/yyyy');
    const formatedEndOfContract = this.datePipe.transform(this.selectedDiagnosis.end_of_contract, 'dd/MM/yyyy');
    const updatedBody = {
      diagnosis_name: this.selectedDiagnosis?.diagnosis_name,
      start_of_contract: formatedStartOfContract,
      end_of_contract: formatedEndOfContract,
      diagnosis_type: this.selectedDiagnosis?.diagnosis_type,
      progress_status: this.selectedDiagnosis?.progress_status
    }

    this.apiService.patch(`diagnoses/${this.selectedDiagnosis.diagnosis_pid}`, updatedBody).subscribe((response) => {
      this.returnToDiagnoses()
      this.isLoading = false;
    })

  }

  /**
   * Function to return to the diagnosis page with the client and building selected
   */
  public returnToDiagnoses(): void {
    if (this.selectedAgency) {
      this.router.navigate([DIAGNOSIS_URLS.list], { queryParams: { building: this.selectedBuilding?.building_pid } });
    } else {
      this.router.navigate([DIAGNOSIS_URLS.list], { queryParams: { building: this.selectedBuilding?.building_pid } });
    }
  }

}
