import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {PERMISSION_TYPES, USER_URLS} from "../../constants/constants";
import {ApiService} from "../../services/api.service";
import {HelperService} from "../../services/helper.service";
import { DarkModeService } from "../../services/darkmode.service";

interface FormData {
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  user_permissions: {
    addresses: string,
    clients: string,
    users: string,
    notifications: string,
    agencies: string,
    buildings: string,
    diagnosis: string,
    files: string,
    appointments: string,
    comments: string,
  }
  buildings: string[]
}

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {

  public readonly USER_URLS = USER_URLS;

  // Get the permissions
  public permissionOptions = Object.values(PERMISSION_TYPES);

  // If loading
  public isLoading: boolean = false;

  // Boolean to know if the mail is valid
  public isEmailValid: boolean = true;

  // Buildings list
  public buildingsList: any[] = [];
  public filteredBuildingsList: any[] = [];

  // User datas
  public formData: FormData = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    user_permissions: {
      addresses: PERMISSION_TYPES.None.value,
      clients: PERMISSION_TYPES.None.value,
      users: PERMISSION_TYPES.None.value,
      notifications: PERMISSION_TYPES.None.value,
      agencies: PERMISSION_TYPES.None.value,
      buildings: PERMISSION_TYPES.None.value,
      diagnosis: PERMISSION_TYPES.None.value,
      files: PERMISSION_TYPES.None.value,
      appointments: PERMISSION_TYPES.None.value,
      comments: PERMISSION_TYPES.None.value,
    },
    buildings: []
  };

  constructor(private router: Router,
              private readonly apiService: ApiService,
              private readonly helperService: HelperService,
              public darkmodeService: DarkModeService,
  ) { }

  ngOnInit(): void {
    this.apiService.get('buildings').subscribe(response => {
      this.buildingsList = response.data;
    });
  }

  public onBuildingSearch(query: string): void {
    this.filteredBuildingsList = this.buildingsList.filter(building =>
      building.building_name.toLowerCase().includes(query.toLowerCase())
    );
  }

  /**
   * Function to check if the form is valid
   */
  public isFormValid(): boolean {
    return this.formData.firstName !== '' && this.formData.lastName !== '' && this.formData.email !== '' && this.formData.password !== ''
  }

  /** Get the label for display french label on select view */
  public getPermissionLabel(value: string): string {
    const permission = this.permissionOptions.find(option => option.value === value);
    return permission ? permission.label : '';
  }

  /**
   * Function that create an user
   * @private
   */
  private createUser(): void {
    this.isLoading = true;
    const userData = {
      user_firstName: this.formData.firstName,
      user_lastName: this.formData.lastName,
      user_email: this.formData.email,
      user_password: this.formData.password,
      user_permissions: this.formData.user_permissions,
      buildings: this.formData.buildings,
    }

    this.apiService.post('users', userData).subscribe(() => {
      this.returnToUser()
      this.isLoading = false;
    });
  }

  /**
   * Function that send the user to the user list page
   */
  public returnToUser(): void {
    this.router.navigate([USER_URLS.list])
  }

  /**
   * Function that check if every input isn't empty
   */
  public isFormEmpty(): boolean {
    return !!(this.formData.email && this.formData.password && this.formData.lastName && this.formData.firstName)
  }

  /**
   * Function that will check if the mail is valid and will call createUser function
   */
  public verifyForm(): void {
    this.isEmailValid = this.helperService.emailRegex.test(this.formData.email)
    if (this.isEmailValid)
      this.createUser()
  }
}
