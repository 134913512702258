import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbMenuItem, NbMenuService } from '@nebular/theme';
import {
  ADDRESSES_URLS,
  AGENCY_URLS,
  APPOINTMENT_URLS,
  BUILDING_URLS,
  CLIENT_URLS,
  DIAGNOSIS_URLS,
  FILE_URLS,
  NOTIFICATION_URLS, USER_URLS
} from 'src/app/constants/constants';
import { DarkModeService } from "../../services/darkmode.service";
import {ApiService} from "../../services/api.service";
import {Router} from "@angular/router";
import { DataService } from "../../services/data.service";
import { DiagnosticsService } from '../../services/diagnoses.service';

@Component({
  selector: 'table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnInit {

  public expandedClients: { [key: string]: boolean } = {};
  public expandedBuildings: { [key: string]: boolean } = {};
  public diagnosticsList: { [key: string]: any[] } = {};
  public diagnosticsLoading: { [key: string]: boolean } = {};

  // If update_at column is displayed
  public showUpdatedAt: boolean = false;

  // The order for the sorting
  public sortOrder: 'asc' | 'desc' | 'none' = 'none';
  public sortOrderDiagnostics: 'asc' | 'desc' | 'none' = 'none';
  public sortOrderBuilding: 'asc' | 'desc' | 'none' = 'none';

  // The route where to show update_at toggle
  public toggleVisibleRoutes: string[] = [
    /*CLIENT_URLS.list,*/
    DIAGNOSIS_URLS.list,
    FILE_URLS.list,
    USER_URLS.list,
  ];

  // constant to know which pages the user is currently on
  public currentRoute!: string;

  // constant to store the current item
  private currentItem!: Object;

  // List for the parameters button
  public parameters: NbMenuItem[] = [
    { title: 'Supprimer'},
  ];

  // Selected Page number
  public selectedPageNumber: number = 0;

  public elementSelectChoice: number[] = [10, 25, 50, 100];

  // Number of elements per page
  public selectedElementPerPage: number = 10;

  // Will store the searchInput
  public searchInput: string = "";

  // Number of page
  public numberOfPageList: number[] = [];

  // If download_at column is displayed
  public showDownloadAt: boolean = false;

  // The route where to show download_at toggle
  public downloadToggleVisibleRoutes: string[] = [
    FILE_URLS.list,
  ];

  @Output() rowClicked: EventEmitter<any> = new EventEmitter<any>();

  // Headers for the table
  @Input() headers: string[] = [];

  // Data list
  @Input() dataList: any[] = [];

  // Inputted data keys
  @Input() params: string[] = [];

  // If the row is clickable
  @Input() isClickable: boolean = false;

  // Add button content
  @Input() addButtonContent: string = "";

  // If the table need a search bar
  @Input() searchBar: boolean = true;

  // Table main title
  @Input() tableTitle: string = "";

  // Event to create or add an item
  @Output() addClicked: EventEmitter<any> = new EventEmitter<any>()

  // Event to edit an item
  @Output() editClicked: EventEmitter<any> = new EventEmitter<any>();

  // Event to delete an item
  @Output() deleteClicked: EventEmitter<any> = new EventEmitter<any>();

  // Event to re-send an email
  @Output() resendClicked: EventEmitter<any> = new EventEmitter<any>();

  // Event to generate a PDF
  @Output() stepperClicked: EventEmitter<any> = new EventEmitter<any>();

  // Event to download a file
  @Output() downloadClicked: EventEmitter<any> = new EventEmitter<any>();

  // Event to send back the research input
  @Output() searchInputEvent: EventEmitter<string> = new EventEmitter<string>();

  // For the client page with the diagnostics list with filters
  @Input() nameFilter: string = '';
  @Input() phoneFilter: string = '';
  @Input() filterType: string = '';
  @Input() diagnosisTypeFilter: string[] = [];
  @Input() filterYear: number | null = null;
  @Input() filterMonth: number | null = null;
  @Input() diagnosisNameFilter: string = '';

  constructor(private route: ActivatedRoute,
              private menuService: NbMenuService,
              public darkmodeService: DarkModeService,
              private apiService: ApiService,
              private router: Router,
              private dataService: DataService,
              private diagnosticsService: DiagnosticsService,
  ) { }

  ngOnInit(): void {

    this.route.url.subscribe(event => this.currentRoute = "/" + event[0].path);

    this.cleanUpHeaders();

    // Get current path and set currenRoute variable to it
    this.route.url.subscribe(event => this.currentRoute = "/" + event[0].path)

    if (this.currentRoute !== NOTIFICATION_URLS.list && this.currentRoute !== FILE_URLS.list) {
      this.parameters.unshift({ title: 'Modifier'});
    }

    if (FILE_URLS.list === this.currentRoute) {
      this.parameters.unshift({ title: 'Télécharger'});
    }

    // If the current page is the Notification page, add one more options to the list
    if (NOTIFICATION_URLS.list === this.currentRoute) {
      this.parameters.unshift({ title: 'Renvoyer l\'email'});
    }

    if (APPOINTMENT_URLS.list === this.currentRoute)
      this.parameters.unshift({ title: 'Générer un PDF'});

    // Subscribe to detect which option has been selected
    this.menuService.onItemClick().subscribe((event) => {
      if (event.item.title === 'Modifier')
        this.onEditClick(this.currentItem);
      if (event.item.title === 'Supprimer')
        this.onDeleteClick(this.currentItem);
      if (event.item.title === 'Renvoyer l\'email')
        this.onResendClick(this.currentItem);
      if (event.item.title === 'Générer un PDF')
        this.onStepperClick((this.currentItem));
      if (event.item.title === 'Télécharger')
        this.onDownloadClick((this.currentItem));
    })
  }

  /**
   * Function to check if the current page is the client page
   */
  public isClientPage(): boolean {
    return this.currentRoute === CLIENT_URLS.list;
  }

  /**
   * Function to check if the current page is the user page
   */
  public isAddressPage(): boolean {
    return this.currentRoute === ADDRESSES_URLS.list;
  }

  /**
   * Function to toggle diagnostics
   * @param clientPid - The client pid
   */
  public toggleClientDiagnostics(clientPid: string): void {
    // If the client is already expanded, we collapse it and finish here
    if (this.expandedClients[clientPid]) {
      this.expandedClients[clientPid] = false;
      return;
    }

    // Otherwise, we expand the client and load the diagnostics with the filters
    this.expandedClients[clientPid] = true;
    this.diagnosticsLoading[clientPid] = true;

    // Préparation of filters
    const filters = {
      nameFilter: this.nameFilter || '',
      phoneFilter: this.phoneFilter || '',
      filterType: this.filterType || '',
      diagnosisTypeFilter: this.diagnosisTypeFilter.length ? this.diagnosisTypeFilter.join(',') : '',
      filterYear: this.filterYear ? this.filterYear.toString() : '',
      filterMonth: this.filterMonth ? this.filterMonth.toString() : '',
      diagnosisNameFilter: this.diagnosisNameFilter || ''
    };

    // Conversion of filters into query parameters
    const queryParams = Object.entries(filters)
      // Exclude empty filters
      .filter(([_, value]) => value)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');

    // API call with filters to load or reload the diagnostics
    this.apiService.get(`diagnoses/${clientPid}/clients?${queryParams}`).subscribe(response => {
      this.diagnosticsList[clientPid] = response.diagnoses || [];
      this.diagnosticsLoading[clientPid] = false;
    }, error => {
      console.error('Error fetching diagnoses:', error);
      this.diagnosticsLoading[clientPid] = false;
    });
  }

  /**
   * Function to toggle diagnostics for buildings
   * @param buildingPid - The building pid
   */
  public toggleBuildingDiagnostics(buildingPid: string): void {
    // If the building is already expanded, we collapse it and finish here
    if (this.expandedBuildings[buildingPid]) {
      this.expandedBuildings[buildingPid] = false;
      return;
    }

    // Otherwise, we expand the building and load the diagnostics with the filters
    this.expandedBuildings[buildingPid] = true;
    this.diagnosticsLoading[buildingPid] = true;

    // Preparation of filters
    const filters = {
      diagnosisTypeFilter: this.diagnosisTypeFilter.length ? this.diagnosisTypeFilter.join(',') : '',
      filterYear: this.filterYear ? this.filterYear.toString() : '',
      filterMonth: this.filterMonth ? this.filterMonth.toString() : '',
      diagnosisNameFilter: this.diagnosisNameFilter || ''
    };

    // Conversion of filters into query parameters
    const queryParams = Object.entries(filters)
      // Exclude empty filters
      .filter(([_, value]) => value)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');

    // API call with filters to load or reload the diagnostics
    this.apiService.get(`diagnoses/${buildingPid}/buildings?${queryParams}`).subscribe(response => {
      this.diagnosticsList[buildingPid] = response.diagnoses || [];
      this.diagnosticsService.setDiagnostics(buildingPid, response.diagnoses || []);
      this.diagnosticsLoading[buildingPid] = false;
    }, error => {
      console.error('Error fetching diagnoses:', error);
      this.diagnosticsLoading[buildingPid] = false;
    });
  }

  /**
   * Function to reload diagnostics
   */
  public reloadDiagnostics(): void {
    // Reload diagnostics for each expanded client
    Object.keys(this.expandedClients).forEach(clientPid => {
      if (this.expandedClients[clientPid]) {
        this.diagnosticsLoading[clientPid] = true;

        const filters = {
          diagnosisTypeFilter: this.diagnosisTypeFilter.length ? this.diagnosisTypeFilter.join(',') : '',
          filterYear: this.filterYear ? this.filterYear.toString() : '',
          filterMonth: this.filterMonth ? this.filterMonth.toString() : '',
          diagnosisNameFilter: this.diagnosisNameFilter || ''
        };

        const queryParams = Object.entries(filters)
          .filter(([_, value]) => value)
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
          .join('&');

        this.apiService.get(`diagnoses/${clientPid}/clients?${queryParams}`).subscribe(response => {
          this.diagnosticsList[clientPid] = response.diagnoses || [];
          this.diagnosticsLoading[clientPid] = false;
        }, error => {
          console.error('Error fetching diagnoses:', error);
          this.diagnosticsList[clientPid] = [];
          this.diagnosticsLoading[clientPid] = false;
        });
      }
    });

    // Reload diagnostics for each expanded building
    Object.keys(this.expandedBuildings).forEach(buildingPid => {
      if (this.expandedBuildings[buildingPid]) {
        this.diagnosticsLoading[buildingPid] = true;

        const filters = {
          diagnosisTypeFilter: this.diagnosisTypeFilter.length ? this.diagnosisTypeFilter.join(',') : '',
          filterYear: this.filterYear ? this.filterYear.toString() : '',
          filterMonth: this.filterMonth ? this.filterMonth.toString() : '',
          diagnosisNameFilter: this.diagnosisNameFilter || ''
        };

        const queryParams = Object.entries(filters)
          .filter(([_, value]) => value)
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
          .join('&');

        this.apiService.get(`diagnoses/${buildingPid}/buildings?${queryParams}`).subscribe(response => {
          this.diagnosticsList[buildingPid] = response.diagnoses || [];
          this.diagnosticsLoading[buildingPid] = false;
        }, error => {
          console.error('Error fetching diagnoses:', error);
          this.diagnosticsList[buildingPid] = [];
          this.diagnosticsLoading[buildingPid] = false;
        });
      }
    });
  }

  /**
   * Function to redirect to the diagnoses page
   * @param diagnosis - The diagnosis object
   */
  public navigateToDiagnosis(diagnosis: any): void {
    // Set the selected client
    this.dataService.setSelectedClient(diagnosis.client);

    // Set the selected agency if it exists
    if (diagnosis.agency) {
      this.dataService.setSelectedAgency(diagnosis.agency);
    }

    //Set the selected building
    this.dataService.setSelectedBuilding(diagnosis.building);

    // Navigate to the diagnosis page
    this.router.navigate([DIAGNOSIS_URLS.list], {
      queryParams: { building: diagnosis.building.building_pid, client: diagnosis.client.client_pid }
    });
  }

  /**
   * Function to toggle the sort order
   */
  public toggleSortOrder(): void {
    this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    this.sortDiagnosesList();
  }

  /**
   * Function to sort the diagnoses list
   * @private
   */
  private sortDiagnosesList(): void {
    this.dataList.sort((a, b) => {
      const dateA = this.parseDate(a.end_of_contract);
      const dateB = this.parseDate(b.end_of_contract);

      if (this.sortOrder === 'asc') {
        return dateA.getTime() - dateB.getTime();
      } else if (this.sortOrder === 'desc') {
        return dateB.getTime() - dateA.getTime();
      } else {
        return 0;
      }
    });
  }

  /**
   * Function to toggle the sort order for diagnostics
   */
  public toggleSortOrderDiagnostics(): void {
    this.sortOrderDiagnostics = this.sortOrderDiagnostics === 'asc' ? 'desc' : 'asc';
    this.sortDiagnosticsList();
  }

  /**
   * Function to sort the diagnostics list
   * @private
   */
  private sortDiagnosticsList(): void {
    Object.keys(this.diagnosticsList).forEach(clientOrBuildingPid => {
      this.diagnosticsList[clientOrBuildingPid].sort((a, b) => {
        const dateA = this.parseDate(a.end_of_contract);
        const dateB = this.parseDate(b.end_of_contract);

        if (this.sortOrderDiagnostics === 'asc') {
          return dateA.getTime() - dateB.getTime();
        } else if (this.sortOrderDiagnostics === 'desc') {
          return dateB.getTime() - dateA.getTime();
        } else {
          return 0;
        }
      });
    });
  }

  /**
   * Function to toggle the sort order for buildings in diagnostics
   */
  public toggleSortOrderByBuilding(): void {
    this.sortOrderBuilding = this.sortOrderBuilding === 'asc' ? 'desc' : 'asc';
    this.sortDiagnosticsByBuilding();
  }

  /**
   * Function to sort diagnostics by building name in natural order (considering both numbers and text)
   * @private
   */
  private sortDiagnosticsByBuilding(): void {
    Object.keys(this.diagnosticsList).forEach(clientOrBuildingPid => {
      this.diagnosticsList[clientOrBuildingPid].sort((a, b) => {
        const buildingNameA = a.building?.building_name?.toLowerCase() || '';
        const buildingNameB = b.building?.building_name?.toLowerCase() || '';

        if (this.sortOrderBuilding === 'asc') {
          return this.naturalCompare(buildingNameA, buildingNameB);
        } else if (this.sortOrderBuilding === 'desc') {
          return this.naturalCompare(buildingNameB, buildingNameA);
        } else {
          return 0;
        }
      });
    });
  }

  /**
   * Natural comparison function to compare two strings considering numbers within the strings.
   * @param a
   * @param b
   */
  private naturalCompare(a: string, b: string): number {
    const regex = /(\d+|\D+)/g;
    const aParts = a.match(regex);
    const bParts = b.match(regex);

    for (let i = 0; i < Math.max(aParts!.length, bParts!.length); i++) {
      const aPart = aParts![i] || '';
      const bPart = bParts![i] || '';

      // Compare numbers numerically
      const aIsNumber = !isNaN(aPart as any);
      const bIsNumber = !isNaN(bPart as any);

      if (aIsNumber && bIsNumber) {
        const numberComparison = Number(aPart) - Number(bPart);
        if (numberComparison !== 0) {
          return numberComparison;
        }
      } else {
        // Compare alphabetically if parts are not numbers
        const partComparison = aPart.localeCompare(bPart);
        if (partComparison !== 0) {
          return partComparison;
        }
      }
    }

    // If all parts are equal, return 0
    return 0;
  }

  /**
   * Function to parse a date string
   * @param dateString - The date string
   * @private
   */
  private parseDate(dateString: string): Date {
    const [day, month, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day);
  }

  // Function to show Toggle on appropriate pages
  public shouldShowDownloadToggle(): boolean {
    return this.downloadToggleVisibleRoutes.includes(this.currentRoute);
  }

  // Function to add download_at column in the table
  public toggleDownloadAtColumn() {
    if (this.showDownloadAt) {
      // Get the correct index to display download_at column
      const insertIndex = this.headers.length - 1;

      if (!this.headers.includes('Dernier téléchargement client')) {
        this.headers.splice(insertIndex, 0, 'Dernier téléchargement client');
        this.params.splice(insertIndex, 0, 'clients_download');
      }
    } else {
      const downloadAtIndex = this.headers.indexOf('Dernier téléchargement client');
      if (downloadAtIndex > -1) {
        this.headers.splice(downloadAtIndex, 1);
        this.params.splice(downloadAtIndex, 1);
      }
    }
  }

  /**
   * Function to clean
   * @private
   */
  private cleanUpHeaders() {
    if (!this.showUpdatedAt) {
      const updatedAtIndex = this.headers.indexOf('Dernière modification');
      if (updatedAtIndex > -1) {
        this.headers.splice(updatedAtIndex, 1);
      }
    }

    if (!this.showDownloadAt) {
      const downloadAtIndex = this.headers.indexOf('Dernier téléchargement client');
      if (downloadAtIndex > -1) {
        this.headers.splice(downloadAtIndex, 1);
      }
    }
  }

  /**
   * Function to add updated_at column in the table
   */
  public toggleUpdatedAtColumn() {
    if (this.showUpdatedAt) {
      // Get the correct index to display upadate_at column
      const insertIndex = this.headers.length - 1;

      if (!this.headers.includes('Dernière modification')) {
        this.headers.splice(insertIndex, 0, 'Dernière modification');
        this.params.splice(insertIndex, 0, 'updated_at');
      }
    } else {
      const updatedAtIndex = this.headers.indexOf('Dernière modification');
      if (updatedAtIndex > -1) {
        this.headers.splice(updatedAtIndex, 1);
        this.params.splice(updatedAtIndex, 1);
      }
    }
  }

  /**
   * Function to show Toggle on appropriate pages
   */
  public shouldShowToggle(): boolean {
    return this.toggleVisibleRoutes.includes(this.currentRoute);
  }

  /**
   * Function to emit the event to add an element
   */
  public onAddClick(): void {
    this.addClicked.emit();
  }

  /**
   * Function to emit the event to edit an element
   * @param item
   */
  public onEditClick(item: any): void {
    this.editClicked.emit(item);
  }

  /**
   * Function to emit the event to delete an element
   * @param item
   */
  public onDeleteClick(item: any): void {
    this.deleteClicked.emit(item);
  }

  /**
   * Function to emit the event to resend an email
   * @param item
   */
  public onResendClick(item: any): void {
    this.resendClicked.emit(item);
  }

  /**
   * Function to emit the event to generate a pdf file
   * @param item
   */
  public onStepperClick(item: any): void {
    this.stepperClicked.emit(item);
  }

  /**
   * Function to emit the event to download a file
   * @param item
   */
  public onDownloadClick(item: any): void {
    this.downloadClicked.emit(item);
  }

  /**
   * Function to check which header is selected to display edit and delete button
   * @param header
   * @param item
   * @returns boolean
  */
  public checkHeader(header: any, item: any): boolean | void{
   return header !== 'actions' && this.onRowClick(item)
  }

  /**
   * Function to emit the event to click on a row
   * @param item
   */
  public onRowClick(item: any): void {
    this.rowClicked.emit(item);
  }

  /**
   * Function to set the selected item into the current item variable
   * @param item
   */
  public setCurrentItem(item: any): void {
    this.currentItem = item;
  }

  /**
   * Function to emit the event to send back the research input
   * @param event
   */
  public onSearch(event: Event): void {
    const query = (event.target as HTMLInputElement).value.toLowerCase();
    this.searchInput = query;
    this.searchInputEvent.emit(query);
  }

  /**
   * Function to set the number of element per page
   * @param nbrOfList - The number of element per page
   * @param pageNumber - The current page number
   */
  public generatePageList(nbrOfList: number, pageNumber: number): number[] {
    if (nbrOfList < 7) {
      return Array.from({ length: nbrOfList }, (_, index) => index + 1);
    } else {
      // Number of page shown
      const pageSize: number = 7;
      // Variable to store the starting page for the list
      const startPage = Math.max(1, Math.min(nbrOfList - pageSize + 1, pageNumber - Math.floor(pageSize / 2)));
      // List that store the list of page
      let pageArray: number[] = [];

      if (pageNumber - 2 > 1 && pageNumber + 2 < nbrOfList - 1) {
        // Handle the list display so the page number is always in the middle
        pageArray = Array.from({length: pageSize}, (_, index) => (pageNumber - 2) + index);
      } else {
        pageArray = Array.from({length: pageSize}, (_, index) => startPage + index);
      }

      // Replace first element by 1
      if (pageArray[0] !== 1) {
        pageArray.shift()
        pageArray.unshift(1)
      }

      // replace last element by the max number of page
      if (pageArray[nbrOfList - 1] !== nbrOfList) {
        pageArray.pop();
        pageArray.push(nbrOfList);
      }

      return pageArray
    }
  }

  /**
   * Will separate list into a list of list by selectedElementPerPage
   */
  public handlePage(): any[][] {
    const nbrOfList = Math.ceil(this.dataList.length / this.selectedElementPerPage);

    if (this.selectedPageNumber >= nbrOfList && nbrOfList !== 0)
      this.selectedPageNumber = nbrOfList - 1;

    // Get the number of page
    this.numberOfPageList = this.generatePageList(nbrOfList, this.selectedPageNumber);

    // return the array of list
    return Array.from({ length: Math.ceil(nbrOfList) }, (_, index) =>
      this.dataList.slice(index * this.selectedElementPerPage, (index + 1) * this.selectedElementPerPage)
    );
  }

  // Set page's Index with user's input value
  public setPageNumber(index: number): void {
    this.selectedPageNumber = index - 1;
  }

  // Set page's index to the previous one
  public previousPage(): void {
    --this.selectedPageNumber;
  }

  // Set page's index to the next one
  public nextPage(): void {
    ++this.selectedPageNumber
  }

  // Check if previous button need to be disabled
  public disablePrevious(): boolean {
    return this.selectedPageNumber === 0;
  }

  // check if next button need to be disabled
  public disableNext(): boolean {
    return this.selectedPageNumber === this.numberOfPageList.length - 1;
  }

  // Function to check if three dot is needed
  public displayDot(index: number): boolean {
    // Check if we have to put dot after the first element
    if (index == 1) {
      if (this.selectedPageNumber - 2 > 1)
        return true
      // Check if we have to put dot after the penultimate element
    } else if (index == this.numberOfPageList[this.numberOfPageList.length - 2]) {
      if (this.selectedPageNumber + 2 < this.numberOfPageList[this.numberOfPageList.length - 1] - 2)
        return true
    }
    return false
  }
}
