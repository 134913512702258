import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DarkModeService {
  private isDarkMode: boolean = false;

  constructor() {
    this.loadInitialTheme();
  }

  /**
   * Function to change the state of the theme
   */
  public toggleDarkMode(): void {
    this.isDarkMode = !this.isDarkMode;
    localStorage.setItem('darkMode', this.isDarkMode ? 'true' : 'false');
    document.body.setAttribute('data-theme', this.isDarkMode ? 'dark' : 'light');
  }


  /**
   * Load the state of the theme with localstorage
   */
  public loadInitialTheme(): void {
    const savedMode = localStorage.getItem('darkMode');
    this.isDarkMode = (savedMode === 'true');
    document.body.setAttribute('data-theme', this.isDarkMode ? 'dark' : 'light');
  }

  /**
   * Function to get the darkmode state
   */
  public getDarkModeState(): boolean {
    return this.isDarkMode;
  }
}
