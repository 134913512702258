<div class="appointments-container">
  <Sidebar></Sidebar>
  <div class="appointments-page padding">

    <!-- APPOINTMENT TITLE -->
    <div class="appointments-title">
      <TitleAtom size="large" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Formulaire de rendez-vous"></TitleAtom>
    </div>

    <!-- GENERATE APPOINTMENT SECTION -->
    <!-- APPOINTMENTS HEADER -->
    <div class="create-header">
      <button *ngIf="getStepNumber() === 1" nbButton (click)="returnToAppointment()"><nb-icon icon="arrow-ios-back-outline"></nb-icon>Retour</button>
    </div>

    <div class="appointment-steps-container">

      <div class="appointment-stepper-count">
        <TitleAtom size="medium" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Étape {{getStepNumber()}} / 9"></TitleAtom>
      </div>

      <!-- STEP 1-->
      <div *ngIf="getStepNumber() === 1" class="general-steps-style appointment-first-step">
        <ul class="appointment-list-style">
          <li>
            <nb-radio-group [(ngModel)]="formData.activity">
              <nb-radio *ngFor="let option of APPOINTMENT_TYPES" [value]="option.value">{{ option.label }}</nb-radio>
            </nb-radio-group>
          </li>
          <li>
            <nb-checkbox [(ngModel)]="formData.workPhases.generalTask">Tâches générales</nb-checkbox>
            <nb-checkbox [(ngModel)]="formData.workPhases.basement">Caves</nb-checkbox>
          </li>
          <li>
            <nb-checkbox [(ngModel)]="formData.workPhases.trash">Poubelles</nb-checkbox>
            <nb-checkbox [(ngModel)]="formData.workPhases.circulation">Circulation</nb-checkbox>
          </li>
          <li>
            <nb-checkbox [(ngModel)]="formData.workPhases.psychologicalRisk">Risques psychologiques</nb-checkbox>
            <nb-checkbox [(ngModel)]="formData.workPhases.electricalRisk">Risques éléctriques</nb-checkbox>
          </li>
          <li>
            <nb-checkbox [(ngModel)]="formData.workPhases.parking">Parking</nb-checkbox>
            <nb-checkbox [(ngModel)]="formData.workPhases.elevatorRepair">Ascenseurs avec dépannage</nb-checkbox>
          </li>
          <li>
            <nb-checkbox [(ngModel)]="formData.workPhases.elevatorNoRepair">Ascenseurs sans dépannage</nb-checkbox>
            <nb-checkbox [(ngModel)]="formData.workPhases.greenSpace">Espaces verts</nb-checkbox>
          </li>
          <li>
            <nb-checkbox [(ngModel)]="formData.workPhases.bioAgent">Agents Biologiques</nb-checkbox>
          </li>
        </ul>
      </div>

      <!-- STEP 2 -->
      <div *ngIf="getStepNumber() === 2" class="general-steps-style appointment-second-step">
        <ul class="appointment-list-style">
          <ParagraphAtom text="Information concernant l'employé et l'entreprise" color="dark" size="x-large" weight="semi-bold"></ParagraphAtom>
          <li>
            <div>
              <ParagraphAtom text="Numéro du dossier" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
              <input type="number" min="0" nbInput [(ngModel)]="formData.dossierNumber" placeholder="Dossier...">
            </div>
            <div>
              <ParagraphAtom text="Date de repérage" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
              <input type="text" nbInput [(ngModel)]="formData.dateReperage" placeholder="Date de repérage...">
            </div>
          </li>
          <li class="employee-name">
            <div>
              <ParagraphAtom text="Nom du salarié" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
              <input type="text" fullWidth nbInput [(ngModel)]="formData.employeName" placeholder="Nom du salarié...">
            </div>
          </li>
          <li>
            <div class="stepper-date">
              <ParagraphAtom text="Date d'entrée dans l'entreprise du salarié" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
              <input fullWidth type="number" min="1950" nbInput [(ngModel)]="formData.userInfo.dateBegin" placeholder="Année d'entrée dans l'entreprise...">
            </div>
            <div>
              <ParagraphAtom text="Adresse du donneur d'ordre" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
              <input fullWidth type="text" nbInput [(ngModel)]="formData.agencyAddress" placeholder="Adresse du donneur d'ordre...">
            </div>
          </li>
          <li>
            <div>
              <ParagraphAtom text="Description de la méthode" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
              <nb-select fullWidth [(selected)]="formData.methode">
                <nb-select-label>
                  {{ formData.methode }}
                </nb-select-label>

                <nb-option *ngFor="let type of FORMATION_TYPES" [value]="type.value">
                  {{ type.label }}
                </nb-option>
              </nb-select>
            </div>
            <div>
              <ParagraphAtom text="Personnes associées à l'évaluation" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
              <nb-select fullWidth [(selected)]="formData.userInfo.name">
                <nb-select-label>
                  {{ formData.userInfo.name }}
                </nb-select-label>

                <nb-option *ngFor="let user of userList" [value]="user.user_lastName">
                  {{ user.user_lastName }} {{ user.user_firstName }}
                </nb-option>
              </nb-select>
            </div>
          </li>
        </ul>
      </div>

      <!-- STEP 3 -->
      <div *ngIf="getStepNumber() === 3" class="general-steps-style appointment-third-step">
        <ul class="appointment-list-style">
          <ParagraphAtom text="Organisation de la sécurité au sein de l'unité de travail" color="dark" size="x-large" weight="semi-bold"></ParagraphAtom>
          <li class="li-checkbox">
            <nb-checkbox [(ngModel)]="formData.ACMOpresence">Présence d'un ACMO ou correspondant de sécurité</nb-checkbox>
            <nb-checkbox [(ngModel)]="formData.internalRules">Existence d'un règlement intérieur</nb-checkbox>
          </li>
          <li class="li-checkbox">
            <nb-checkbox [(ngModel)]="formData.workMeasureHourly">Mesure pour le travail isolé et/ou en horaires décalées</nb-checkbox>
            <nb-checkbox [(ngModel)]="formData.preventionPlan">Rédaction de plan de prévention lors d'intervention d'entreprises extérieurs</nb-checkbox>
          </li>
          <li>
            <div>
              <ParagraphAtom text="Nomination" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
              <nb-select fullWidth [(selected)]="formData.nomination">
                <nb-select-label>
                  {{ formData.nomination }}
                </nb-select-label>

                <nb-option *ngFor="let type of FORMATION_TYPES" [value]="type.value">
                  {{ type.label }}
                </nb-option>
              </nb-select>
            </div>
            <div>
              <ParagraphAtom text="Formation initiale" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
              <nb-select fullWidth [(selected)]="formData.initialFormation">
                <nb-select-label>
                  {{ formData.initialFormation }}
                </nb-select-label>

                <nb-option *ngFor="let type of FORMATION_TYPES" [value]="type.value">
                  {{ type.label }}
                </nb-option>
              </nb-select>
            </div>
          </li>
          <li>
            <div>
              <ParagraphAtom text="Formation continue" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
              <nb-select fullWidth [(selected)]="formData.continueFormation">
                <nb-select-label>
                  {{ formData.continueFormation }}
                </nb-select-label>

                <nb-option *ngFor="let type of FORMATION_TYPES" [value]="type.value">
                  {{ type.label }}
                </nb-option>
              </nb-select>
            </div>
            <div>
              <ParagraphAtom text="Présence d'un registre hygiène et sécurité" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
              <nb-select fullWidth [(selected)]="formData.safetyHealth">
                <nb-select-label>
                  {{ formData.safetyHealth }}
                </nb-select-label>

                <nb-option *ngFor="let type of HEALTH_TYPES" [value]="type.value">
                  {{ type.label }}
                </nb-option>
              </nb-select>
            </div>
          </li>
          <li>
            <div>
              <ParagraphAtom text="Existence d'une instance consultative (CHSCT)" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
              <nb-select fullWidth [(selected)]="formData.CHSCT">
                <nb-select-label>
                  {{ formData.CHSCT }}
                </nb-select-label>

                <nb-option *ngFor="let type of FORMATION_TYPES" [value]="type.value">
                  {{ type.label }}
                </nb-option>
              </nb-select>
            </div>
          </li>
        </ul>
      </div>

      <!-- STEP 4 -->
      <div *ngIf="getStepNumber() === 4" class="general-steps-style appointment-fourth-step">
        <ul class="appointment-list-style">
          <ParagraphAtom text="Organisation des secours" color="dark" size="x-large" weight="semi-bold"></ParagraphAtom>
          <li>
            <div>
              <ParagraphAtom text="Nombre de sauveteurs secouristes du travail" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
              <input type="number" min="0" nbInput [(ngModel)]="formData.rescuersNumber" placeholder="Nombre de sauveteurs secouristes du travail...">
            </div>
            <div>
              <ParagraphAtom text="Nombre de chargés d'évacuation" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
              <input type="number" min="0" nbInput [(ngModel)]="formData.evacuationOfficerNumber" placeholder="Nombre de chargés d'évactuation...">
            </div>
          </li>
          <li class="stepper-date">
            <ParagraphAtom text="Nombre d'équipiers de première intervention" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
            <input fullWidth type="number" min="0" nbInput [(ngModel)]="formData.firstTeamsNumbers" placeholder="Nombre d'équipiers de première intervention">
          </li>
          <li class="li-checkbox">
            <nb-checkbox [(ngModel)]="formData.displaySafetyInstructions">Affichage de consignes générales de sécurité</nb-checkbox>
            <nb-checkbox [(ngModel)]="formData.displaySpecificInstructions">Affichage de consignes spécifiques de sécurité</nb-checkbox>
          </li>
          <li class="li-checkbox-left">
            <nb-checkbox [(ngModel)]="formData.evacuationDrills">Organisation d'exercices d'évacuation</nb-checkbox>
          </li>
          <ParagraphAtom text="Formation" color="dark" size="x-large" weight="semi-bold"></ParagraphAtom>
          <li>
            <div>
              <ParagraphAtom text="Nombre de personnes formées à la manipulation d'extincteurs" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
              <input type="number" min="0" nbInput [(ngModel)]="formData.numberTrainedPeopleExtinguisher" placeholder="Dossier...">
            </div>
            <div>
              <ParagraphAtom text="Nombre de nouveaux entrants formés" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
              <input type="number" min="0" nbInput [(ngModel)]="formData.numberNewEntrantsTrained" placeholder="Nom...">
            </div>
          </li>
        </ul>
      </div>

      <!-- STEP 5 -->
      <div *ngIf="getStepNumber() === 5" class="general-steps-style appointment-fifth-step">
        <ParagraphAtom text="Nature des autres formations en hygiène et sécurité suivies par le personnel" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="x-large" weight="semi-bold"></ParagraphAtom>
        <table-header class="appointment-table-width" [searchBar]="false" [addButtonContent]="'Ajouter'" [headers]="STEPPER_HEADERS.formation" [dataList]="formationList" [params]="['formation', 'numberOfPerson', 'action']" (editClicked)="editFormation($event)"></table-header>

      </div>


      <!-- STEP 6 -->
      <div *ngIf="getStepNumber() === 6" class="general-steps-style appointment-sixth-step">
        <ParagraphAtom text="Risques Professionels" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="x-large" weight="semi-bold"></ParagraphAtom>
        <ul class="appointment-list-style">
          <li class="li-checkbox">
            <nb-checkbox [(ngModel)]="formData.buildingMaintenance">Hygiène - Entretien des immeubles</nb-checkbox>
            <nb-checkbox [(ngModel)]="formData.electricalAuthorization">Habilitation éléctrique BS</nb-checkbox>
          </li>
          <li class="li-checkbox">
            <nb-checkbox [(ngModel)]="formData.basicCourse">Stage de base gardiens</nb-checkbox>
            <nb-checkbox [(ngModel)]="formData.gesturesAndPostures">Gestes et postures</nb-checkbox>
          </li>
          <li class="li-checkbox">
            <nb-checkbox [(ngModel)]="formData.hygieneSafety">Hygiène et sécurité</nb-checkbox>
            <nb-checkbox [(ngModel)]="formData.electricalInterventions">Interventions: Éléctricité</nb-checkbox>
          </li>
          <li class="li-checkbox">
            <nb-checkbox [(ngModel)]="formData.electricalRecycling">Habilitation éléctrique BS recyclage</nb-checkbox>
            <nb-checkbox [(ngModel)]="formData.discriminationPitfalls">La discrimination: éviter les pièges</nb-checkbox>
          </li>
          <li class="li-checkbox">
            <nb-checkbox [(ngModel)]="formData.dealingAggressionInsecurity">Faire face aux agressions et à l'insécurité</nb-checkbox>
            <nb-checkbox [(ngModel)]="formData.preventingConflictSituations">Prévention et gestion des situations confluctuelles</nb-checkbox>
          </li>
          <li class="li-checkbox">
            <nb-checkbox [(ngModel)]="formData.culturalDiversity">La diversité culturelle dans les relations professionelles</nb-checkbox>
            <nb-checkbox [(ngModel)]="formData.plumbingIntervention">Interventions: Plomberie</nb-checkbox>
          </li>
          <li class="li-checkbox">
            <nb-checkbox [(ngModel)]="formData.greenSpaceMaintenance">Entretien des espaces verts</nb-checkbox>
            <nb-checkbox [(ngModel)]="formData.problemsApartmentBuildings">Désordres dans les immeubles de logements</nb-checkbox>
          </li>
          <li class="li-checkbox">
            <nb-checkbox [(ngModel)]="formData.nothing">Néant</nb-checkbox>
            <nb-checkbox [(ngModel)]="formData.nothingEmployeeNoCommunicate">Néant - Le salarié ne nous à rien communiqué</nb-checkbox>
          </li>
          <li class="li-checkbox-left">
            <nb-checkbox [(ngModel)]="formData.otherTraining">Autres formations</nb-checkbox>
          </li>
          <li>
            <div>
              <ParagraphAtom text="Commentaire du salarié" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
              <textarea [(ngModel)]="formData.employeeComments" nbInput fullWidth placeholder="Commentaire du salarié..."></textarea>
            </div>
          </li>
        </ul>
      </div>

      <!-- STEP 7 -->
      <div *ngIf="getStepNumber() === 7" class="general-steps-style appointment-seventh-step">
        <ul class="appointment-list-style">
          <ParagraphAtom text="Suivi médical du personnel" color="dark" size="x-large" weight="semi-bold"></ParagraphAtom>
          <li class="li-checkbox-left">
            <nb-checkbox [(ngModel)]="formData.preventionDoctorPresence">Présence d'un médecin de prévention dans l'établissement</nb-checkbox>
          </li>
          <li>
            <div>
              <ParagraphAtom text="Suivi médical adapté aux risques professionnels pour toutes les personnes" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
              <nb-select fullWidth [(selected)]="formData.medicalFollowAdaptedRisks">
                <nb-select-label>
                  {{ formData.medicalFollowAdaptedRisks }}
                </nb-select-label>

                <nb-option *ngFor="let type of HEALTH_MONITORING_TYPES" [value]="type.value">
                  {{ type.label }}
                </nb-option>
              </nb-select>
            </div>
            <div>
              <ParagraphAtom text="Nombre d’accidents au cours de l’année écoulée" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
              <input type="number" min="0" nbInput [(ngModel)]="formData.numberAccidentsPastYear" placeholder="Nombre d'accidents au cours de l'année écoulée...">
            </div>
          </li>
          <ParagraphAtom text="Accidents du travail et maladies professionelles" color="dark" size="x-large" weight="semi-bold"></ParagraphAtom>
          <li>
            <div>
              <ParagraphAtom text="Nombre d’accidents analysés" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
              <input type="number" min="0" nbInput [(ngModel)]="formData.numbersAccidentsAnalyzed" placeholder="Nombre d'accidents analysés">
            </div>
            <div>
              <ParagraphAtom text="Nombre de maladies professionnelles" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
              <input type="number" min="0" nbInput [(ngModel)]="formData.numberOccupationalIllnesses" placeholder="Nomber de maladies professionelles">
            </div>
          </li>
          <ParagraphAtom text="Gestion de déchets" color="dark" size="x-large" weight="semi-bold"></ParagraphAtom>
          <li>
            <div>
              <ParagraphAtom text="Mise en place d'une gestion des déchets" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
              <nb-select fullWidth [(selected)]="formData.wasteManagement">
                <nb-select-label>
                  {{ formData.wasteManagement }}
                </nb-select-label>

                <nb-option *ngFor="let type of WASTE_MANAGEMENTS_TYPES" [value]="type.value">
                  {{ type.label }}
                </nb-option>
              </nb-select>
            </div>
          </li>
          <li>
            <div>
              <ParagraphAtom text="Nature des accidents et des maladies professionelles" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
              <textarea [(ngModel)]="formData.natureAccidentsIllnesses" nbInput fullWidth placeholder="Nature des accidents et des maladies professionelles..."></textarea>
            </div>
          </li>
        </ul>
      </div>

      <!-- STEP 8 -->
      <div *ngIf="getStepNumber() === 8" class="general-steps-style appointment-seventh-step">
        <ul class="appointment-list-style">
          <ParagraphAtom text="Manutentions manuelles de charges" color="dark" size="x-large" weight="semi-bold"></ParagraphAtom>
          <li class="li-checkbox">
            <nb-checkbox [(ngModel)]="formData.handling1">Lever ou porter</nb-checkbox>
            <nb-checkbox [(ngModel)]="formData.handling2">Pousser ou tirer</nb-checkbox>
          </li>
          <li class="li-checkbox">
            <nb-checkbox [(ngModel)]="formData.handling3">Déplacer du travailleur avec la charge ou prise de charge au sol ou à une hauteur au dessus des épaules</nb-checkbox>
            <nb-checkbox [(ngModel)]="formData.handling4">Cumul de manutentions de charges</nb-checkbox>
          </li>
          <ParagraphAtom text="Postures pénibles" color="dark" size="x-large" weight="semi-bold"></ParagraphAtom>
          <li class="li-checkbox-left">
            <nb-checkbox [(ngModel)]="formData.awkwardPostures">Postures pénibles</nb-checkbox>
          </li>
          <ParagraphAtom text="Vibration mécaniques" color="dark" size="x-large" weight="semi-bold"></ParagraphAtom>
          <li class="li-checkbox">
            <nb-checkbox [(ngModel)]="formData.mechanicalVibration1">Transmises aux mains et aux bras</nb-checkbox>
            <nb-checkbox [(ngModel)]="formData.mechanicalVibration2">Transmises à l'ensemble du corps</nb-checkbox>
          </li>
          <li class="li-checkbox">
            <nb-checkbox [(ngModel)]="formData.nightWork">Travail de nuit</nb-checkbox>
            <nb-checkbox [(ngModel)]="formData.workingShifts">Travail en équipes successives alternantes</nb-checkbox>
          </li>
          <li class="li-checkbox-left">
            <nb-checkbox [(ngModel)]="formData.repetitiveWork">Travail répétitif (Travaux impliquant l’exécution de mouvements répétés)</nb-checkbox>
          </li>
        </ul>
      </div>

      <!-- STEP 9 -->
      <div *ngIf="getStepNumber() === 9" class="general-steps-style appointment-eighth-step">
        <ul class="appointment-list-style">
          <li class="li-checkbox">
            <nb-checkbox [(ngModel)]="formData.dangerousChemicals">Agent chimiques dangereux</nb-checkbox>
            <nb-checkbox [(ngModel)]="formData.activityHyperbaric">Activités exercées en milieu hyperbare</nb-checkbox>
          </li>
          <li class="li-checkbox-left">
            <nb-checkbox [(ngModel)]="formData.externalTemperatures">Températures externes</nb-checkbox>
          </li>
          <ParagraphAtom text="Bruit" color="dark" size="x-large" weight="semi-bold"></ParagraphAtom>
          <li class="li-checkbox">
            <nb-checkbox [(ngModel)]="formData.noise1">Exposition à au moins 81 décibels pendant 8h</nb-checkbox>
            <nb-checkbox [(ngModel)]="formData.noise2">Exposition à au moins 135 décibels</nb-checkbox>
          </li>
        </ul>
      </div>

      <div>
        <div>
          <button *ngIf="getStepNumber() !== 1" nbButton fullWidth status="basic" (click)="previousPage()">Précédent</button>
          <button *ngIf="getStepNumber() !== 9" [disabled]="!enableNextStep()" nbButton fullWidth status="primary" (click)="nextPage()">Suivant</button>
          <button [nbSpinner]="isLoading" nbSpinnerStatus="info" *ngIf="getStepNumber() === 9" [disabled]="!enableNextStep() || this.isLoading" nbButton fullWidth status="primary" (click)="saveData()">Générer le PDF</button>
        </div>
        <ParagraphAtom *ngIf="getStepNumber() === 9 && dowloadError" size="intermediate" weight="semi-bold" text="Une erreur s'est produite durant la génération du PDF." color="red"></ParagraphAtom>
        <ParagraphAtom *ngIf="getStepNumber() === 9 && !dowloadError && isLoading" size="intermediate" weight="semi-bold" text="Génération du PDF en cours... veuillez patienter." color="dark"></ParagraphAtom>
      </div>

    </div>

  </div>
</div>
