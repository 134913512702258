import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HttpClient, HttpClientModule} from "@angular/common/http";
import { NgSelectModule } from '@ng-select/ng-select';

/* SERVICES */
import { AuthGuard } from "./services/auth.guard";

/* PAGE */
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from "./pages/login/login.component";
import { EditPasswordComponent } from './pages/edit-password/edit-password.component';
import { AccountComponent } from './pages/account/account.component';
import { AdressesComponent } from './pages/adresses/adresses.component';

import { UsersComponent } from './pages/users/users.component';
import { UserCreateComponent } from './pages/user-create/user-create.component';
import { UserEditComponent } from './pages/user-edit/user-edit.component';

import { ClientsComponent } from './pages/clients/clients.component';
import { ClientCreateComponent } from './pages/client-create/client-create.component';
import { ClientEditComponent } from './pages/client-edit/client-edit.component';

import { AgenciesComponent } from './pages/agencies/agencies.component';
import { AgencyCreateComponent } from './pages/agency-create/agency-create.component';
import { AgencyEditComponent } from './pages/agency-edit/agency-edit.component';

import { BuildingsComponent } from './pages/buildings/buildings.component';
import { BuildingCreateComponent } from './pages/building-create/building-create.component';
import { BuildingEditComponent } from './pages/building-edit/building-edit.component';

import { DiagnosesComponent } from './pages/diagnoses/diagnoses.component';
import { DiagnosisCreateComponent } from './pages/diagnosis-create/diagnosis-create.component';
import { DiagnosisEditComponent } from './pages/diagnosis-edit/diagnosis-edit.component';

import { FilesComponent } from './pages/files/files.component';

import { NotificationsComponent } from './pages/notifications/notifications.component';
import { DialogFormationComponent } from './pages/dialog-formation/dialog-formation.component';
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';

//import { NotificationsCreateComponent } from './pages/notifications-create/notifications-create.component';
//import { NotificationsEditComponent } from './pages/notifications-edit/notifications-edit.component';

import { AppointmentsComponent } from './pages/appointments/appointments.component';
import { AppointmentCreateComponent } from './pages/appointment-create/appointment-create.component';
import { AppointmentEditComponent } from './pages/appointment-edit/appointment-edit.component';
import { AppointmentStepperComponent } from './pages/appointment-stepper/appointment-stepper.component';

/* MOLECULE */
import { SidebarComponent } from './molecule/sidebar/sidebar.component';
import { TableHeaderComponent } from './molecule/table-header/table-header.component';
import { BreadcrumbComponent } from './molecule/breadcrumb/breadcrumb.component';
import { DialogComponent } from './molecule/dialog/dialog.component';
import { CommentsModalComponent } from './molecule/comments-modal/comments-modal.component';
/* ATOM */
import { ButtonComponent } from './atom/button/button.component';
import { TitleComponent } from './atom/title/title.component';
import { ImageComponent } from './atom/image/image.component';
import { ParagraphComponent } from './atom/paragraph/paragraph.component';

/* NEBULAR */
import {
  NbThemeModule,
  NbLayoutModule,
  NbButtonModule,
  NbInputModule,
  NbSelectModule,
  NbDatepickerModule,
  NbMenuModule,
  NbContextMenuModule,
  NbIconModule,
  NbRadioModule,
  NbCheckboxModule,
  NbDialogModule,
  NbDialogRef,
  NbCardModule,
  NbToastrModule,
  NbSpinnerModule,
  NbToggleModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { RedirectComponent } from './pages/redirect/redirect.component';

const appRouter: Routes = [
  { path: '', redirectTo: '/redirect', pathMatch: 'full' },
  { path: 'redirect', component: RedirectComponent },
  { path: 'login', component: LoginComponent },
  { path: 'account', canActivate: [AuthGuard], component: AccountComponent},
  { path: 'edit-password', canActivate: [AuthGuard], component: EditPasswordComponent},
  { path: 'users', canActivate: [AuthGuard], data: {requiredSection: 'users'}, component: UsersComponent },
  { path: 'users/new', canActivate: [AuthGuard], data: {requiredSection: 'users', requiredPermission: 'WRITE'}, component: UserCreateComponent },
  { path: 'users/edit', canActivate: [AuthGuard], data: {requiredSection: 'users', requiredPermission: 'WRITE'},  component: UserEditComponent },
  { path: 'clients', canActivate: [AuthGuard], data: {requiredSection: 'clients' }, component: ClientsComponent },
  { path: 'clients/new', canActivate: [AuthGuard], data: {requiredSection: 'clients', requiredPermission: 'WRITE'}, component: ClientCreateComponent },
  { path: 'clients/edit', canActivate: [AuthGuard], data: {requiredSection: 'clients', requiredPermission: 'WRITE'}, component: ClientEditComponent },
  { path: 'agencies', canActivate: [AuthGuard], data: {requiredSection: 'agencies'}, component: AgenciesComponent },
  { path: 'agencies/new', canActivate: [AuthGuard], data: {requiredSection: 'agencies', requiredPermission: 'WRITE'}, component: AgencyCreateComponent },
  { path: 'agencies/edit', canActivate: [AuthGuard], data: {requiredSection: 'agencies', requiredPermission: 'WRITE'}, component: AgencyEditComponent },
  { path: 'buildings', canActivate: [AuthGuard], data: {requiredSection: 'buildings'}, component: BuildingsComponent },
  { path: 'buildings/new', canActivate: [AuthGuard], data: {requiredSection: 'buildings', requiredPermission: 'WRITE'}, component: BuildingCreateComponent },
  { path: 'buildings/edit', canActivate: [AuthGuard], data: {requiredSection: 'buildings', requiredPermission: 'WRITE'}, component: BuildingEditComponent },
  { path: 'diagnoses', canActivate: [AuthGuard], data: {requiredSection: 'diagnosis'}, component: DiagnosesComponent },
  { path: 'diagnoses/new', canActivate: [AuthGuard], data: {requiredSection: 'diagnosis', requiredPermission: 'WRITE'}, component: DiagnosisCreateComponent },
  { path: 'diagnoses/edit', canActivate: [AuthGuard], data: {requiredSection: 'diagnosis', requiredPermission: 'WRITE'}, component: DiagnosisEditComponent },
  { path: 'files', canActivate: [AuthGuard], data: {requiredSection: 'files'}, component: FilesComponent },
  { path: 'notifications', canActivate: [AuthGuard], data: { requiredSection: 'notifications' }, component: NotificationsComponent },
  //{ path: 'notifications/new', canActivate: [AuthGuard], data: {requiredSection: 'notifications', requiredPermission: 'WRITE'}, component: NotificationsCreateComponent },
  //{ path: 'notifications/edit', canActivate: [AuthGuard], data: {requiredSection: 'notifications', requiredPermission: 'WRITE'}, component: NotificationsEditComponent },
  { path: 'appointments', canActivate: [AuthGuard], data: { requiredSection: 'appointments'}, component: AppointmentsComponent },
  { path: 'appointments/new', canActivate: [AuthGuard], data: { requiredSection: 'appointments', requiredPermission: 'WRITE'}, component: AppointmentCreateComponent },
  { path: 'appointments/edit', canActivate: [AuthGuard], data: { requiredSection: 'appointments', requiredPermission: 'WRITE'}, component: AppointmentEditComponent },
  { path: 'appointments/stepper', canActivate: [AuthGuard], data: { requiredSection: 'appointments', requiredPermission: 'WRITE'}, component: AppointmentStepperComponent },
  { path: 'confirmation', component: ConfirmationComponent },
  { path: 'adresses', canActivate: [AuthGuard], data: { requiredSection: 'addresses' }, component: AdressesComponent },
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload'
};

@NgModule({
  declarations: [AppComponent, UsersComponent, SidebarComponent, ButtonComponent, TitleComponent, TableHeaderComponent, ImageComponent, ParagraphComponent, ClientsComponent, UserCreateComponent, UserEditComponent, ClientCreateComponent, ClientEditComponent, AgenciesComponent, AgencyCreateComponent, AgencyEditComponent, BuildingsComponent, BuildingCreateComponent, BuildingEditComponent, BreadcrumbComponent, DiagnosesComponent, DiagnosisCreateComponent, DiagnosisEditComponent, NotificationsComponent, HomeComponent, LoginComponent, EditPasswordComponent, AccountComponent, AgenciesComponent, AgencyCreateComponent, AgencyEditComponent, FilesComponent, AppointmentsComponent, AppointmentEditComponent, AppointmentCreateComponent, AppointmentStepperComponent, DialogComponent, DialogFormationComponent, CommentsModalComponent, ConfirmationComponent, RedirectComponent, AdressesComponent],
  imports: [BrowserModule, RouterModule.forRoot(appRouter, routerOptions), FormsModule, BrowserAnimationsModule, NbThemeModule.forRoot({ name: 'default' }), NbLayoutModule, NbEvaIconsModule, NbButtonModule, NbInputModule, NbSelectModule, NbDatepickerModule.forRoot(), NbContextMenuModule, NbMenuModule.forRoot(), NbIconModule, HttpClientModule, NbRadioModule, NbCheckboxModule, NbDialogModule.forRoot(), NbCardModule, NbToastrModule.forRoot(), NbSpinnerModule, NbToggleModule, NgSelectModule],
  providers: [AuthGuard],
  bootstrap: [AppComponent],
})

export class AppModule {}
