<div class="diagnosis-container">
  <Sidebar></Sidebar>
  <div class="diagnosis-page padding">

    <!-- DIAGNOSIS PAGE TITLE -->
    <div class="diagnosis-title">
      <TitleAtom size="large" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Modifier un diagnostic"></TitleAtom>
    </div>

    <!-- EDIT DIAGNOSIS SECTION -->
    <!-- DIAGNOSIS HEADER -->
    <div class="create-header">
      <button nbButton (click)="returnToDiagnoses()"><nb-icon icon="arrow-ios-back-outline"></nb-icon>Retour</button>
    </div>

    <!-- EDIT FORM -->
    <div class="diagnosis-edit-container">
      <TitleAtom size="medium" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Modifier les informations du diagnostic"></TitleAtom>

      <div class="diagnosis-content-container">
        <div class="diagnosis-info-container">
          <div class="diagnosis-info-title">
            <ParagraphAtom text="INFORMATION" [color]="darkmodeService.getDarkModeState() ? 'light' : 'light'" size="large" weight="semi-bold"></ParagraphAtom>
          </div>
          <ul>
            <li>
              <ParagraphAtom [text]="'Client : ' + selectedClient!.client_name" [color]="darkmodeService.getDarkModeState() ? 'light' : 'light'" size="large" weight="semi-bold"></ParagraphAtom>
            </li>
            <li *ngIf="selectedAgency">
              <ParagraphAtom [text]="'Agence :' + selectedAgency!.agency_name" [color]="darkmodeService.getDarkModeState() ? 'light' : 'light'" size="large" weight="semi-bold"></ParagraphAtom>
            </li>
            <li>
              <ParagraphAtom [text]="'Immeuble : ' + selectedBuilding!.building_name" [color]="darkmodeService.getDarkModeState() ? 'light' : 'light'" size="large" weight="semi-bold"></ParagraphAtom>
            </li>
            <li>
              <ParagraphAtom [text]="'Diagnostic : ' + selectedDiagnosis!.diagnosis_name" [color]="darkmodeService.getDarkModeState() ? 'light' : 'light'" size="large" weight="semi-bold"></ParagraphAtom>
            </li>
          </ul>
        </div>

        <ul class="diagnosis-edit-form">
          <li>
            <div>
              <ParagraphAtom text="Numéro dossier" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
              <input fullWidth type="text" nbInput [(ngModel)]="selectedDiagnosis.diagnosis_name" placeholder="Numéro...">
            </div>
          </li>
          <li class="diagnosis-date">
            <div>
              <ParagraphAtom text="Modifier la date de début de contrat" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
              <input fullWidth nbInput placeholder="Début de contrat..." [nbDatepicker]="datepickerStart" [(ngModel)]="selectedDiagnosis.start_of_contract" readOnly>
              <nb-datepicker #datepickerStart ></nb-datepicker>
            </div>
            <div>
              <ParagraphAtom text="Modifier la date de fin de contrat" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
              <input fullWidth nbInput placeholder="Fin de contrat..." [nbDatepicker]="datepickerEnd" [(ngModel)]="selectedDiagnosis.end_of_contract" readOnly>
              <nb-datepicker #datepickerEnd [min]="selectedDiagnosis.start_of_contract"></nb-datepicker>
            </div>
          </li>
          <li>
            <div>
              <ParagraphAtom text="Modifier le type de diagnostic" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
              <nb-select fullWidth placeholder="Choose type" [(selected)]="selectedDiagnosis.diagnosis_type">
                <nb-select-label>
                  {{ selectedDiagnosis.diagnosis_type }}
                </nb-select-label>

                <nb-option *ngFor="let type of diagnosisTypes" [value]="type.value">
                  {{ type.label }}
                </nb-option>
              </nb-select>
            </div>
          </li>
          <li class="form-btn">
            <ParagraphAtom *ngIf="showDateError" text="La date de fin de contrat ne peut être inférieure à celle du début de contrat" color="red" size="medium" weight="semi-bold"></ParagraphAtom>
            <button nbButton fullWidth status="primary" [disabled]="!isFormValid() || isLoading" (click)="editDiagnosis()">Sauvegarder</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
