import { Component, OnInit, Input } from '@angular/core';
import {AGENCY_HEADERS, AGENCY_URLS, BUILDING_URLS, CLIENT_URLS} from '../../constants/constants';
import {ActivatedRoute, Router} from '@angular/router';
import { Client } from 'src/app/types/client.type';
import { Agency } from 'src/app/types/agency.type';
import { ApiService } from 'src/app/services/api.service';
import { DialogComponent } from "../../molecule/dialog/dialog.component";
import { NbDialogService } from "@nebular/theme";
import { AuthGuard } from "../../services/auth.guard";
import { DataService } from "../../services/data.service";
import { DarkModeService } from "../../services/darkmode.service";

@Component({
  selector: 'app-agencies',
  templateUrl: './agencies.component.html',
  styleUrls: ['./agencies.component.scss']
})
export class AgenciesComponent implements OnInit {

  // Navigation constants
  public readonly CLIENT_URLS = CLIENT_URLS;
  public readonly AGENCY_URLS = AGENCY_URLS;

  // Constants for agencies header
  public readonly AGENCY_HEADERS = AGENCY_HEADERS;

  // Agencies list objects
  @Input() agenciesList: Agency[] = [];

  // To know which agency is selected
  public selectedAgency: Agency | null = null;

  // To know which client is selected
  public selectedClient: Client | null = null;

  // Client pid
  public clientPid: string | undefined = undefined;

  // Search value
  public filteredAgenciesList: Agency[] = [];

  // For filters
  nameFilter: string = '';
  emailFilter: string = '';
  postalCodeFilter: string = '';
  cityFilter: string = '';

  constructor(public router: Router,
              private route: ActivatedRoute,
              private apiService: ApiService,
              private dialogService: NbDialogService,
              private authGuard: AuthGuard,
              private dataService: DataService,
              public darkmodeService: DarkModeService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.clientPid = params['client'];
    });

    this.apiService.get(`clients/${this.clientPid}`).subscribe(response => {
      this.selectedClient = response.data[0];
      this.dataService.setSelectedClient(response.data[0]);

      this.apiService.get(`clients/${this.clientPid}/agencies`).subscribe(response => {
        this.agenciesList = response.data;

        // Sort alphabetically
        this.agenciesList.sort((a, b) => a.agency_name.localeCompare(b.agency_name));
        this.filteredAgenciesList = [...this.agenciesList];
      });
    });
  }

  /**
   * Function to search an agency
   * @param input
   */
  public onAgencySearch(input: string): void {
    this.filteredAgenciesList = this.agenciesList.filter(agency =>
      agency.agency_name.toLowerCase().includes(input) ||
      agency.agency_email.toLowerCase().includes(input) ||
      agency.created_at!.toLowerCase().includes(input) ||
      agency.updated_at!.toLowerCase().includes(input)
    );
  }

  /**
   * Function to apply filters
   */
  public applyFilters(): void {
    const trimmedNameFilter = this.nameFilter.trim();
    const trimmedEmailFilter = this.emailFilter.trim();
    const trimmedPostalCodeFilter = this.postalCodeFilter.trim();
    const trimmedCityFilter = this.cityFilter.trim();
    this.filteredAgenciesList = this.agenciesList.filter(agency =>
      agency.agency_name.toLowerCase().includes(trimmedNameFilter.toLowerCase()) &&
      agency.agency_email.toLowerCase().includes(trimmedEmailFilter.toLowerCase()) &&
      agency.agency_postalCode.includes(trimmedPostalCodeFilter) &&
      agency.agency_city.toLowerCase().includes(trimmedCityFilter.toLowerCase())
    );
  }

  /**
   * Function to reset filters
   */
  public resetFilters(): void {
    this.nameFilter = '';
    this.emailFilter = '';
    this.postalCodeFilter = '';
    this.cityFilter = '';
    this.filteredAgenciesList = [...this.agenciesList];
  }

  /**
   * Function to create a new agency
   */
  public navigateToCreateAgency(): void {
    this.router.navigate([AGENCY_URLS.new], { queryParams: { client: JSON.stringify(this.selectedClient) } });
  }

  /**
   * Function to get the agency selected
   * @param agency
   * @param client
   */
  public editAgency(agency: Agency, client: Client): void {
    this.selectedAgency = agency;
    this.selectedClient = client;
    this.router.navigate([AGENCY_URLS.edit], { queryParams: { agency: JSON.stringify(this.selectedAgency), client: JSON.stringify(this.selectedClient) } });
  }

  /**
   * Function to delete an agency, will first open a modal to confirm the deletion
   * @param agency
   */
  public deleteAgency(agency: Agency): void {
    const dialogRef = this.dialogService.open(DialogComponent, {
      context: {
        content: "Êtes-vous sûr de supprimer cette agence ?",
        title: "Confirmer la suppression",
        confirmButton: "Supprimer",
      },
      closeOnBackdropClick: true,
    });

    dialogRef.onClose.subscribe((result: string) => {
      if (result === 'action') {
        this.apiService.delete(`agencies/${agency.agency_pid}`).subscribe()
        this.filteredAgenciesList.splice(this.filteredAgenciesList.indexOf(agency), 1);
      }
    })
  }

  /**
   * Function to navigate to the buildings page with the agency selected
   * @param client
   * @param agency
   */
  public navigateToBuildings(client: string, agency: Agency): void {
    this.dataService.setSelectedAgency(agency);
    this.router.navigate([BUILDING_URLS.list], { queryParams: { client: client, agency: agency.agency_pid } });
  }

  /**
   * Check if user has permissions
   * @param permissionKey
   */
  public hasPermission(permissionKey: string): boolean {
    const userPermissions = this.authGuard.getUserPermissions();

    if (userPermissions) {
      return userPermissions[permissionKey] && userPermissions[permissionKey] === 'WRITE';
    } else {
      return false;
    }
  }
}
