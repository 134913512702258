import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {
  APPOINTMENT_TYPES,
  APPOINTMENT_URLS, PRODIAG_INFOS, STEPPER_HEADER, USER_URLS,
} from "../../constants/constants";
import {ApiService} from "../../services/api.service";
import {User} from "../../types/user.type";
import {NbDialogService} from "@nebular/theme";
import {DialogFormationComponent} from "../dialog-formation/dialog-formation.component";
import {Building} from "../../types/building.type";
import {Agency} from "../../types/agency.type";
import {Appointment} from "../../types/appointment.type";
import {Client} from "../../types/client.type";
import { DarkModeService } from "../../services/darkmode.service";

interface formationInterface {
  formation: string,
  numberOfPerson: string,
}

@Component({
  selector: 'app-appointment-stepper',
  templateUrl: './appointment-stepper.component.html',
  styleUrls: ['./appointment-stepper.component.scss']
})
export class AppointmentStepperComponent implements OnInit {

  // Path constant
  public readonly APPOINTMENT_TYPES = APPOINTMENT_TYPES;

  // Constant for stepper table
  public readonly STEPPER_HEADERS = STEPPER_HEADER

  // Constant for prodiag's company information
  private readonly PRODIAG_INFOS = PRODIAG_INFOS;

  // Variable to store the step number
  private stepNumber: number = 1;

  public FORMATION_TYPES = [
    {value: 'Aucun renseignement', label: 'Aucun renseignement'}
  ]

  public HEALTH_TYPES = [
    {value: 'Sans information', label: 'Sans information'}
  ]

  public HEALTH_MONITORING_TYPES = [
    {value: 'Par l’organisme de médecine du travail', label: 'Par l’organisme de médecine du travail'}
  ]

  public WASTE_MANAGEMENTS_TYPES = [
    {value: 'Mise en place par les containers de recyclage', label: 'Mise en place par les containers de recyclage'}
  ]

  // To define the min date
  public minDate: Date = new Date(new Date().setHours(0, 0, 0, 0));

  // Variable to store the selected user
  public selectedUserList: User[] = [];

  // Variable to store the selected client
  private selectedClient: Client | null = null;

  // Variable to store the selected agency
  private selectedAgency: Agency | null = null;

  // Variable to store the selected building
  private selectedBuilding: Building | null = null;

  // Variable to store the selected appointment
  private selectedAppointment: Appointment | null = null;

  // Variable to store a list of user
  public userList: User[] = [];

  // Variable to store the formation list
  public formationList: formationInterface[] = [];

  // To load during the pdf generation
  public isLoading: boolean = false;

  // Display message if error during the download
  public dowloadError: boolean = false;

  public formData = {
    // STEP 1
    workPhases: {
      generalTask: false,
      basement: false,
      trash: false,
      circulation: false,
      psychologicalRisk: false,
      electricalRisk: false,
      parking: false,
      elevatorRepair: false,
      elevatorNoRepair: false,
      greenSpace: false,
      bioAgent: false,
    },
    // STEP 2
    dossierNumber: '',
    dateReperage: '',
    agencyName: '',
    agencyAddress: '',
    buildingAddress: '',
    buildingCity: '',
    activity: '',
    employeName: '',
    userInfo: {
      name: '',
      dateBegin: '',
    },
    company: {
      name: PRODIAG_INFOS.company,
      address: PRODIAG_INFOS.address,
      siret: PRODIAG_INFOS.siret,
      insurance: PRODIAG_INFOS.insurance,
    },
    methode: this.FORMATION_TYPES[0].value,
    // STEP 3
    rapportDate: '',
    ACMOpresence: false,
    internalRules: false,
    workMeasureHourly: false,
    preventionPlan: false,
    nomination: this.FORMATION_TYPES[0].value,
    initialFormation: this.FORMATION_TYPES[0].value,
    continueFormation: this.FORMATION_TYPES[0].value,
    safetyHealth: this.HEALTH_TYPES[0].value,
    CHSCT: this.FORMATION_TYPES[0].value,
    // STEP 4
    rescuersNumber: '0',
    evacuationOfficerNumber: '0',
    firstTeamsNumbers: '0',
    displaySafetyInstructions: false,
    displaySpecificInstructions: false,
    evacuationDrills: false,
    numberTrainedPeopleExtinguisher: '0',
    numberNewEntrantsTrained: '0',
    // STEP 5
    tableDataFormation: [],
    // STEP 6
    buildingMaintenance: false,
    electricalAuthorization: false,
    basicCourse: false,
    gesturesAndPostures: false,
    hygieneSafety: false,
    electricalInterventions: false,
    electricalRecycling: false,
    discriminationPitfalls: false,
    dealingAggressionInsecurity: false,
    preventingConflictSituations: false,
    culturalDiversity: false,
    plumbingIntervention: false,
    greenSpaceMaintenance: false,
    problemsApartmentBuildings: false,
    nothing: false,
    nothingEmployeeNoCommunicate: false,
    otherTraining: false,
    employeeComments: '',
    // STEP 7
    preventionDoctorPresence: false,
    medicalFollowAdaptedRisks: this.HEALTH_MONITORING_TYPES[0].value,
    numberAccidentsPastYear: '0',
    numbersAccidentsAnalyzed: '0',
    numberOccupationalIllnesses: '0',
    natureAccidentsIllnesses: '',
    wasteManagement: this.WASTE_MANAGEMENTS_TYPES[0].value,
    // STEP 8
    handling1: false,
    handling2: false,
    handling3: false,
    handling4: false,
    awkwardPostures: false,
    mechanicalVibration1: false,
    mechanicalVibration2: false,
    nightWork: false,
    workingShifts: false,
    repetitiveWork: false,
    // STEP 9
    dangerousChemicals: false,
    activityHyperbaric: false,
    externalTemperatures: false,
    noise1: false,
    noise2: false,
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private dialogService: NbDialogService,
    public darkmodeService: DarkModeService,
  ) {}

  ngOnInit(): void {
    // Get the selected appointment
    this.route.queryParams.subscribe(params => {
      if (params['appointment']) {
        this.selectedAppointment = JSON.parse(params['appointment']);
      }
    })

    // Get selected user with the user_pid from the selected appointment
    if (this.selectedAppointment?.user_pid) {
      this.selectedAppointment.user_pid.forEach(user_pid => {
        this.apiService.get(`users/${user_pid}`).subscribe((returnedUser) => {
          const user = returnedUser.data;
          if (user) {
            this.selectedUserList.push(user); // Assuming selectedUserList is defined to store multiple users
          }
        });
      });
    }

    // Get selected client with the client_pid from the selected appointment
    this.apiService.get(`clients/${this.selectedAppointment?.client_pid}`).subscribe((returnedClient) => {
      this.selectedClient = returnedClient.data;
    })

    // Get selected client with the building_pid from the selected appointment
    this.apiService.get(`buildings/${this.selectedAppointment?.building_pid}`).subscribe((returnedBuilding) => {
      this.selectedBuilding = returnedBuilding.data[0];
      this.formData.buildingAddress = this.selectedBuilding!.building_address;
      this.formData.buildingCity =  this.selectedBuilding!.building_city;

      // If the building is from an agency get the agency from the selected building
      if (this.selectedBuilding?.agency_pid) {
        this.apiService.get(`agencies/${this.selectedBuilding.agency_pid}`).subscribe((returnedAgency) => {
          this.selectedAgency = returnedAgency.data[0];
          this.formData.agencyName = this.selectedAgency!.agency_name;
          this.formData.agencyAddress = this.selectedAgency!.agency_address + this.selectedAgency!.agency_postalCode;
        })
      }
    })

    // Update user list in ngOnInit
    this.apiService.get('users').subscribe((returnedList) => {
      this.userList = returnedList.data;
      this.formData.userInfo.name = this.userList[0].user_lastName;

      // Populate selectedUserList based on selectedAppointment
      if (this.selectedAppointment?.user_pid) {
        this.selectedUserList = this.userList.filter(user => user.user_pid && this.selectedAppointment!.user_pid!.includes(user.user_pid));
      }
    });
  }

  // Function to navigate to the main appointment page
  public returnToAppointment(): void {
    this.router.navigate([APPOINTMENT_URLS.list]);
  }

  // Function to go to the stepper's next page
  public nextPage(): void {
    this.stepNumber++;
  }

  // Function to go to the stepper's previous page
  public previousPage(): void {
    this.stepNumber--;
  }

  // Function to get the current step number
  public getStepNumber(): number {
    return this.stepNumber;
  }

  public editFormation(item: formationInterface): void {
    const dialogRef = this.dialogService.open(DialogFormationComponent, {
      context: {
        formData: item,
        title: "Modifier la",
      }
    });

    dialogRef.onClose.subscribe((result: formationInterface | string) => {
      if (result !== 'close') {
        Object.entries(this.formationList).find(() => {
          const index = this.formationList.findIndex(formation => formation.formation === item.formation);
          if (index !== -1) {
            if (typeof result !== 'string') {
              this.formationList[index] = result;
            } else {
              this.formationList.splice(index, 1);
            }
          }
        });
      }}
    )
  }

  // Function that open up a dialog to create a new prevention
  public addFormation(): void {
    const dialogRef = this.dialogService.open(DialogFormationComponent, {
      context: {
        title: "Ajouter une",
      }
    });

    dialogRef.onClose.subscribe((result: formationInterface | string) => {
      if (typeof result !== "string") {
        this.formationList.push(result)
      }}
    )
  }

  // Check if step data is valid
  public enableNextStep(): boolean {
    switch (this.getStepNumber()) {
      case 1:
        return !!(this.formData.activity)
      case 2:
        return !!(this.formData.dossierNumber && this.formData.employeName && this.formData.userInfo.dateBegin && this.formData.methode && this.formData.userInfo.name && this.formData.dateReperage)
      case 3:
        return !!(this.formData.nomination && this.formData.initialFormation && this.formData.continueFormation && this.formData.safetyHealth && this.formData.CHSCT)
      case 7:
        return !!(this.formData.medicalFollowAdaptedRisks && this.formData.wasteManagement)
      default:
        return true;
    }
  }

  // Function to save data from the stepper
  public saveData(): void {

    this.dowloadError = false;
    this.isLoading = true;

    // Object to list conversion
    this.formationList.map((formation: formationInterface) => this.formData.tableDataFormation.push(Object.values(formation) as never));

    this.apiService.post('appointments/pdf', this.formData).subscribe((response) => {

      const downloadUrl = response.downloadUrl
      window.open(downloadUrl, '_blank');

      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
      this.dowloadError = true;
    })
  }
}
