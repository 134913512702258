import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ACCOUNT_URLS, AUTH_URLS } from 'src/app/constants/constants';
import { User } from 'src/app/types/user.type';
import { HelperService } from "../../services/helper.service";
import { StorageService } from "../../services/storage.service";
import {NbDialogService, NbToastrService, NbGlobalPhysicalPosition} from "@nebular/theme";
import { DialogComponent } from "../../molecule/dialog/dialog.component";
import {ApiService} from "../../services/api.service";
import {AuthGuard} from "../../services/auth.guard";
import { DarkModeService } from "../../services/darkmode.service";
import {DataService} from "../../services/data.service";

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  public readonly ACCOUNT_URLS = ACCOUNT_URLS;
  public readonly AUTH_URLS = AUTH_URLS;

  // Boolean to know if the email is valid
  public isEmailValid: boolean = true;

  // Variable to store the user that is currently connected
  public currentUser: User | null = null;

  public formData: User = {
    user_firstName: '',
    user_email: '',
    user_lastName: '',
  }

  constructor(public readonly router: Router,
              private readonly helperService: HelperService,
              private readonly storageService: StorageService,
              private readonly dialogService: NbDialogService,
              private readonly apiService: ApiService,
              private readonly authGuard: AuthGuard,
              private toastrService: NbToastrService,
              public darkmodeService: DarkModeService,
              private dataService: DataService
  ) { }

  ngOnInit(): void {
    /* Get user infos */
    this.authGuard.userInfo$.subscribe(user => {
      if (user) {
        this.currentUser = user;
        this.formData.user_email = this.currentUser.user_email;
        this.formData.user_lastName = this.currentUser.user_lastName;
        this.formData.user_firstName = this.currentUser.user_firstName;
      }
    });
  }

  /**
   * Function that saves the user's new info (doing nothing for now)
   */
  private editAccount(): void {
    const userData: User = {
      user_firstName: this.formData.user_firstName,
      user_lastName: this.formData.user_lastName,
      user_email: this.formData.user_email,
    }

    this.apiService.patch(`users/${this.currentUser!.user_pid}`, userData).subscribe(() => {
      this.toastrService.show(
        'Votre compte a été mis à jour',
        'Succès',
        { position: NbGlobalPhysicalPosition.BOTTOM_RIGHT, status: 'success' }
      );
    }, () => {
      this.toastrService.show(
        'Votre compte n\'a pas pu être mis à jour',
        'Attention',
        { position: NbGlobalPhysicalPosition.BOTTOM_RIGHT, status: 'danger' }
      );
    })
  }

  /**
   * Verify if every input has a content
   * @returns boolean
   */
  public isFormEmpty(): boolean {
    return !!(this.formData.user_firstName && this.formData.user_lastName && this.formData.user_email);
  }

  /**
   * Function to check if the regEX is respected
   */
  public verifyForm(): void {
    this.isEmailValid = this.helperService.emailRegex.test(this.formData.user_email);
    if (this.isEmailValid)
      this.editAccount()
  }

  /**
   * Function to sign-out the current user, will first open a modal to confirm the action
   */
  public signOut(): void {
    const dialogRef = this.dialogService.open(DialogComponent, {
      context: {
        content: "Êtes-vous certain de vous déconnecter ?",
        title: "Déconnexion",
        confirmButton: "Déconnecter",
      },
      closeOnBackdropClick: true,
    });

    dialogRef.onClose.subscribe((result: string) => {
      if (result === 'action') {
        this.storageService.clearLocalStorage();
        this.dataService.setSelectedClient(null);
        this.dataService.setSelectedAgency(null);
        this.dataService.setSelectedBuilding(null);
        this.authGuard.clearUserInfos();
        this.router.navigate(['/login']);
      }
    })
  }
}
