/* Constant for client types */
export const CLIENT_TYPES = {
  SYNDIC: 'syndic',
  PARTICULIER: 'particulier',
  SOCIETE: 'societe'
};

/* Constant for appointment status types */
export const STATUS_OPTIONS = [
  'rdv',
  'pris',
  'dossier envoyé',
  'complété',
  'annulé'
];

/* Constants for diagnosis types */
export const DIAGNOSIS_TYPES = [
  {value: 'DU', label: 'DU'},
  {value: 'EF', label: 'EF'},
  {value: 'Elec loge', label: 'Elec loge'},
  {value: 'DTA', label: 'DTA'},
  {value: 'Termites', label: 'Termites'},
  {value: 'PPPT', label: 'PPPT'},
  {value: 'DPE immeuble', label: 'DPE immeuble'},
];

/* Constants for date filter */
export const DATE_FILTER = [
  {value: 'asc', label: 'CROISSANT'},
  {value: 'desc', label: 'DÉCROISSANT'},
  {value: 'none', label: 'AUCUN'}
];

// The user permissions
export const PERMISSION_TYPES = {
  None: { value: 'NONE', label: 'AUCUN' },
  Read: { value: 'READ', label: 'LECTURE' },
  Write: { value: 'WRITE', label: 'ECRITURE' },
};

/* PAGES URLS */

/* Url route path for Home page */
export const HOME_URLS = {
  home: '/',
};

export const ADDRESSES_URLS = {
  list: '/adresses',
};

/* Url route path for Client pages */
export const CLIENT_URLS = {
  list: '/clients',
  new: '/clients/new',
  edit: '/clients/edit',
};

/* Url route path for Agency pages */
export const AGENCY_URLS = {
  list: '/agencies',
  new: '/agencies/new',
  edit: '/agencies/edit',
};

/* Url route path for Building pages */
export const BUILDING_URLS = {
  list: '/buildings',
  new: '/buildings/new',
  edit: '/buildings/edit',
}

/* Url route path for Diagnosis pages */
export const DIAGNOSIS_URLS = {
  list: '/diagnoses',
  new: '/diagnoses/new',
  edit: '/diagnoses/edit'
}

/* Url route path for File pages */
export const FILE_URLS = {
  list: '/files'
}

/* Url route path for User pages */
export const USER_URLS = {
  list: '/users',
  new: '/users/new',
  edit: '/users/edit'
};

/* Url route path for Notification pages */
export const NOTIFICATION_URLS = {
  list: '/notifications',
  new: '/notifications/new',
  edit: '/notifications/edit'
};

/* Constant for notifications state */
export const NOTIFICATION_STATES = {
  sent: "transmis",
  not_sent: "non transmis"
}

/* Constant for account pages */
export const ACCOUNT_URLS = {
  account: '/account',
  password: '/edit-password'
}

export const AUTH_URLS = {
  login: '/login',
}

/* Url route path for Appointment pages */
export const APPOINTMENT_URLS = {
  list: '/appointments',
  new: '/appointments/new',
  edit: '/appointments/edit',
  stepper: '/appointments/stepper'
};

export const APPOINTMENT_TYPES = [
  {value: 'Gardien', label: 'Gardien'},
  {value: "Employé d'immeuble", label: "Employé d'immeuble"},
]

export const USER_HEADERS = {
  permission: ['Nom de famille', 'Prénom', 'Email', 'Date de création', 'actions'],
  noPermission: ['Nom de famille', 'Prénom', 'Email', 'Date de création']
}

export const CLIENT_HEADERS = {
  permission: ['', 'Nom', 'Téléphone', 'Date de création', 'Type', 'actions'],
  noPermission: ['', 'Nom', 'Téléphone', 'Date de création', 'Type']
}

export const AGENCY_HEADERS = {
  permission: ['Nom', 'Email', 'Adresse', 'Code Postal', 'Ville', 'actions'],
  noPermission: ['Nom', 'Email', 'Adresse', 'Code Postal', 'Ville']
}

export const BUILDING_HEADERS = {
  permission: ['Adresse', 'Code Postal', 'Ville', 'Total de diagnostiques', 'Avancement dossier', 'actions'],
  noPermissionNoManager: ['Adresse', 'Code Postal', 'Ville', 'Total de diagnostiques', 'Avancement dossier'],
  permissionManager: ['Adresse', 'Code Postal', 'Ville', 'Nom gestionnaire', 'Prénom gestionnaire', 'Email du gestionnaire', 'Total de diagnostiques', 'Avancement dossier', 'actions'],
  noPermissionManager: ['Adresse', 'Code Postal', 'Ville', 'Nom gestionnaire', 'Prénom gestionnaire', 'Email du gestionnaire', 'Total de diagnostiques', 'Avancement dossier']
}

export const ADDRESSES_HEADERS = {
  permission: ['', 'Adresse', 'Code Postal', 'Ville', 'Total de diagnostiques'],
  noPermissionNoManager: ['', 'Adresse', 'Code Postal', 'Ville', 'Total de diagnostiques'],
  permissionManager: ['', 'Adresse', 'Code Postal', 'Ville', 'Nom gestionnaire', 'Prénom gestionnaire', 'Email du gestionnaire', 'Total de diagnostiques'],
  noPermissionManager: ['', 'Adresse', 'Code Postal', 'Ville', 'Nom gestionnaire', 'Prénom gestionnaire', 'Email du gestionnaire', 'Total de diagnostiques']
}

export const DIAGNOSIS_HEADERS = {
  permission: ['Numéro dossier', 'Type', 'Dernière intervention', 'Réactualisation', 'Date de création', 'Avancement du dossier', 'actions'],
  noPermission: ['Numéro dossier', 'Type', 'Dernière intervention', 'Réactualisation', 'Date de création']
}

export const NOTIFICATION_HEADERS = {
  permission: ['Client', 'Immeuble', 'Date d\'envoi', 'Date de création', 'Dernier envoi', 'Statut', 'actions'],
  noPermission: ['Client', 'Immeuble', 'Date d\'envoi', 'Date de création', 'Dernier envoi', 'Statut'],
}

export const APPOINTMENT_HEADERS = {
  permission: ['Client', 'Immeuble', 'Date du rendez-vous', 'Type', 'Email', 'Nom employé immeuble', 'Téléphone employé immeuble', 'Statut', 'actions'],
  noPermission: ['Client', 'Immeuble', 'Date du rendez-vous', 'Type', 'Email', 'Nom employé immeuble', 'Téléphone employé immeuble', 'Statut']
}

export const STEPPER_HEADER = {
  formation: ['Nature des autres formations', 'Nombre de personnes', 'action'],
}

export const FILE_HEADERS = {
  permission: ['Nom du fichier', 'Ajouté par', 'Date d\'ajout', 'Dernier téléchargement client', 'action'],
  noPermission: ['Nom du fichier', 'Ajouté par', 'Date d\'ajout', 'Dernier téléchargement client'],
}

export const PRODIAG_INFOS = {
  siret: '452764780',
  address: '157, rue Amelot 75011 PARIS',
  company: 'PRODIAG',
  insurance: 'AXA FRANCE'
}

export const MONTHS_LIST = [
  { value: 1, label: 'Janvier' },
  { value: 2, label: 'Février' },
  { value: 3, label: 'Mars' },
  { value: 4, label: 'Avril' },
  { value: 5, label: 'Mai' },
  { value: 6, label: 'Juin' },
  { value: 7, label: 'Juillet' },
  { value: 8, label: 'Août' },
  { value: 9, label: 'Septembre' },
  { value: 10, label: 'Octobre' },
  { value: 11, label: 'Novembre' },
  { value: 12, label: 'Décembre' }
];
