<div class="login-container">

  <div class="login-logo">
    <ImageAtom type="image" image="prodiag-logo" width="120px"></ImageAtom>
  </div>

  <!-- LEFT FORM -->
    <div class="login-content">
      <TitleAtom type="h1" align="center" size="x-large" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Connexion"></TitleAtom>
      <ParagraphAtom text="Veuillez entrer vos identifiants pour accéder à votre espace." color="medium-grey" size="medium" weight="semi-bold"></ParagraphAtom>
      <ul>
        <li>
          <ParagraphAtom text="E-mail" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
          <input fullWidth id="email" nbInput placeholder="E-mail" [(ngModel)]="formData.user_email">
          <ParagraphAtom *ngIf="!isEmailValid" text="L'email n'est pas valide" color="red" size="nano" weight="semi-bold"></ParagraphAtom>
        </li>
        <li>
          <ParagraphAtom text="Mot de passe" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
          <input fullWidth id="password" nbInput placeholder="Mot de passe" [(ngModel)]="formData.user_password" type="password">
          <ParagraphAtom *ngIf="!isIdentifiersValid" text="Les identifiants ne sont pas valides" color="red" size="nano" weight="semi-bold"></ParagraphAtom>
        </li>
        <li>
          <button nbButton fullWidth shape="rectangle" (click)="login()" [disabled]="!isFormValid() || isLoading" status="primary">Connexion</button>
        </li>
      </ul>
    </div>

  <!-- RIGHT PART -->
  <div class="login-right">
<!--
    <ImageAtom type="image" image="background-login" width="100%"></ImageAtom>
-->
  </div>
</div>
