import { Component, OnInit } from '@angular/core';
import { AGENCY_URLS, BUILDING_URLS, CLIENT_TYPES, CLIENT_URLS } from "./constants/constants";
import { AuthGuard } from "./services/auth.guard";
import { StorageService } from "./services/storage.service";
import { ApiService } from "./services/api.service";
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public router: Router,
    private apiService: ApiService,
    private storage: StorageService,
    private authGuard: AuthGuard
  ) { }

  ngOnInit() {
    const jwtToken = this.storage.getItem('jwtToken');
    const headers = { 'x-api-key': jwtToken };

    this.initializeUserState();

    // Remove the router event subscription that triggers redirects
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const currentRoute = this.router.url;
        if (currentRoute === '/confirmation' || currentRoute === '/login') {
          return;
        }
      }
    });

    // Initial authentication check
    this.isAuthenticated$.next(!!jwtToken);
  }

  /**
   * Function to initialise user info and permission
   * @private
   */
  private initializeUserState(): void {
    const userInfo = this.storage.getItem('userInfo');
    const userPermissions = this.storage.getItem('userPermissions');

    if (userInfo && userPermissions) {
      this.authGuard.setUserInfos(JSON.parse(userInfo));
      this.authGuard.setUserPermissions(JSON.parse(userPermissions));
      this.isAuthenticated$.next(true);
    }
  }
}
