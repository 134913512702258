import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  BUILDING_URLS,
  CLIENT_URLS,
  AGENCY_URLS,
  DIAGNOSIS_URLS,
  CLIENT_TYPES,
  BUILDING_HEADERS, DIAGNOSIS_TYPES
} from "../../constants/constants";
import { Client } from 'src/app/types/client.type';
import { Agency } from 'src/app/types/agency.type';
import { Building } from 'src/app/types/building.type';
import { ApiService } from "../../services/api.service";
import { AuthGuard } from "../../services/auth.guard";
import { DataService } from "../../services/data.service";
import { NbDialogService } from "@nebular/theme";
import { DialogComponent } from "../../molecule/dialog/dialog.component";
import { DarkModeService } from "../../services/darkmode.service";
import * as XLSX from 'xlsx';
import { stringify } from 'querystring';

@Component({
  selector: 'app-buildings',
  templateUrl: './buildings.component.html',
  styleUrls: ['./buildings.component.scss']
})
export class BuildingsComponent implements OnInit {

  public permissionsUser = this.authGuard.getUserPermissions();

  // URL
  public readonly CLIENT_URLS = CLIENT_URLS;
  public readonly AGENCY_URLS = AGENCY_URLS;
  public readonly BUILDING_URLS = BUILDING_URLS;

  // Types
  public readonly CLIENT_TYPES = CLIENT_TYPES;

  // Table header's header list
  public readonly BUILDING_HEADERS = BUILDING_HEADERS;

  // Buildings list objects
  @Input() buildingsList: Building[] = [];

  // To know which client is selected
  public selectedClient: Client | null = null;

  // To know which agency is selected
  public selectedAgency: Agency | null = null;

  // To know which building is selected
  public selectedBuilding: Building | null = null;

  // Client pid
  public clientPid: string | undefined = undefined;

  // Agency pid
  public agencyPid: string | undefined = undefined;

  // Search value
  public filteredBuildingsList: Building[] = [];

  // To know the client type of the user
  public userClientType: string = '';

  // To know if is loading
  public isLoading: boolean = false;

  // To search
  public addressFilter: string = '';
  public postalCodeFilter: string = '';
  public cityFilter: string = '';
  public sortOrder: 'asc' | 'desc' = 'asc';
  public managerEmailFilter: string = '';
  public managerFirstNameFilter: string = '';
  public managerLastNameFilter: string = '';

  // Add the property to store the comments
  private buildingComments: Map<string, any[]> = new Map();

  constructor(public router: Router,
              private route: ActivatedRoute,
              private apiService: ApiService,
              private dialogService: NbDialogService,
              private authGuard: AuthGuard,
              private dataService: DataService,
              public darkmodeService: DarkModeService) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.isLoading = true;
      this.clientPid = params['client'];
      this.agencyPid = params['agency'];

      // Get the client data
      this.dataService.getSelectedClient().subscribe(client => {
        this.selectedClient = client;

        if (!client && this.clientPid) {
          this.apiService.get(`clients/${this.clientPid}`).subscribe(response => {
            this.selectedClient = response.data[0];
            this.dataService.setSelectedClient(this.selectedClient);
          }, error => {
            console.error(error);
          }, () => {
            this.isLoading = false;
          });
        } else {
          this.isLoading = false;
        }

      });

      // Get the agency data
      this.dataService.getSelectedAgency().subscribe(agency => {
        this.selectedAgency = agency;

        // If agency data is not available, fetch it from the API
        if (this.agencyPid && !this.selectedAgency) {
          this.isLoading = true
          this.apiService.get(`agencies/${this.agencyPid}`).subscribe(response => {
            this.selectedAgency = response.data[0];
            this.dataService.setSelectedAgency(response.data[0]);
            this.loadBuildings();
          });
        } else {
          this.loadBuildings();
        }
      });

      if (!this.selectedAgency) {
        this.apiService.get(`clients/${this.clientPid}`).subscribe(response => {
          this.selectedClient = response.data[0];
          this.dataService.setSelectedClient(response.data[0]);
        });
      } else {
        this.loadBuildings();
      }
    });

    // Get the client_type
    this.authGuard.userInfo$.subscribe(user => {
      if (user?.client_type) {
        this.userClientType = user.client_type;
      }
    });

    // After the buildings are loaded, load the comments for each building
    this.loadBuildings();
  }

  private loadBuildings(): void {
    const userInfo = this.authGuard.getUserInfos();

    // Check if the user is linked to buildings
    this.apiService.get(`users/${userInfo.user_pid}/buildings`).subscribe(buildingResponse => {
      const userBuildings = buildingResponse.data;
      const clientInfo = buildingResponse.client;
      const agencyInfo = buildingResponse.agency;

      if (userBuildings.length > 0) {
        // Display all the buildings linked to the user
        this.buildingsList = this.sortBuildingsAlphabetically(userBuildings);
        this.filteredBuildingsList = [...this.buildingsList];

        // Set the selected client and agency
        if (!this.selectedClient && clientInfo) {
          this.selectedClient = clientInfo;
          this.dataService.setSelectedClient(clientInfo);
        }

        if (!this.selectedAgency && agencyInfo) {
          this.selectedAgency = agencyInfo;
          this.dataService.setSelectedAgency(agencyInfo);
        }
      } else {
        // If the user is not linked to any building, fetch the buildings linked to the client or the agency
        if (this.agencyPid) {
          this.apiService.get(`agencies/${this.agencyPid}/buildings`).subscribe(response => {
            this.buildingsList = this.sortBuildingsAlphabetically(response.data);
            this.filteredBuildingsList = [...this.buildingsList];
          });
        } else if (this.clientPid) {
          this.apiService.get(`clients/${this.clientPid}/buildings`).subscribe(response => {
            this.buildingsList = this.sortBuildingsAlphabetically(response.data);
            this.filteredBuildingsList = [...this.buildingsList];
          });
        }
      }

      // After the buildings are loaded, load the comments for each building
      this.buildingsList.forEach(building => {
        if (building.building_pid) {
          this.apiService.get(`comments?resource_pid=${building.building_pid}`).subscribe(response => {
            if (response.comments && building.building_pid) {
              this.buildingComments.set(building.building_pid, response.comments);
            }
          });
        }
      });
    });
  }

  /**
   * Function to export data to excel file
   */
  public exportToExcel(): void {
    // Load the comments before the export
    // const commentPromises = this.buildingsList.map(building => {
    //   if (building.building_pid) {
    //     return this.apiService.get(`comments?resource_pid=${building.building_pid}`).toPromise();
    //   }
    //   return Promise.resolve(null);
    // });

    // Wait for all the comments to be loaded
    // Promise.all(commentPromises).then(responses => {
    //   responses.forEach((response, index) => {
    //     const building = this.buildingsList[index];
    //     if (response?.comments && building.building_pid) {
    //        this.buildingComments.set(building.building_pid, response.comments);
    //     }
    //   });
    // });

      // Continue with the export once the comments are loaded
      const exportData = this.buildingsList.map((building: Building) => {
        // Base data structure with 'Client' and possibly 'Agence' at the beginning
        const data: any = {
          'Client': this.selectedClient ? this.selectedClient.client_name : '',
        };
    
        // Add the 'Agency' column only if selectedAgency is defined, and just after 'Client'
        if (this.selectedAgency) {
          data['Agence'] = this.selectedAgency.agency_name;
        }  
        // Get the comments for this building
        // const buildingComments = building.building_pid ? this.buildingComments.get(building.building_pid) || [] : [];
        // const formattedComments = buildingComments.map(comment => 
        //   `${new Date(comment.created_at).toLocaleString()} - ${comment.user_firstName} ${comment.user_lastName}: ${comment.message}`
        // ).join('\n');
    
        // Add the other columns
        data['Nom de l\'immeuble'] = building.building_name;
        data['Adresse'] = building.building_address;
        data['Code Postal'] = building.building_postalCode;
        data['Ville'] = building.building_city;
        data['Téléphone'] = building.building_tel || '';
        data['Nom employé immeuble'] = building.building_employeeName || '';
        data['Nom du gestionnaire'] = building.gestionnaire_lastName || '';
        data['Prénom du gestionnaire'] = building.gestionnaire_firstName || '';
        data['Email du gestionnaire'] = building.gestionnaire_email || '';
        data['Nombre de diagnostics'] = building.diagnosis_count || 0;
        //data['Commentaires'] = formattedComments;
       // data['Statut de progression'] = building.progress_status;
    
        return data;
      });
    
      // Create excel sheet
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
    
      // Adjust the height of the cells for the comments
      //const range = XLSX.utils.decode_range(worksheet['!ref'] || 'A1');
      //const commentColumn = 'K'; // Comment column (adjust according to your structure)
      //for (let row = range.s.r + 1; row <= range.e.r; row++) {
      //  const cell = worksheet[`${commentColumn}${row + 1}`];
      //  if (cell) {
      //    worksheet['!rows'] = worksheet['!rows'] || [];
      //    worksheet['!rows'][row] = { hpt: 100 }; // Height in points
      //  }
      //}
    
      // Create excel workbook
      const workbook: XLSX.WorkBook = { Sheets: { 'Immeubles': worksheet }, SheetNames: ['Immeubles'] };
    
      // Excel file name
      const fileName = this.selectedAgency 
        ? `immeubles_${this.selectedClient?.client_name}_${this.selectedAgency?.agency_name}.xlsx`
        : `immeubles_${this.selectedClient?.client_name}.xlsx`;
    
      // Export excel file
    XLSX.writeFile(workbook, fileName);
  }
  

  /**
   * Function to search a building
   * @param input
   */
  public onBuildingSearch(input: string): void {
    this.filteredBuildingsList = this.buildingsList.filter(building =>
      (building.building_name?.toLowerCase().includes(input) || false) ||
      (building.building_city?.toLowerCase().includes(input) || false)
    );
  }

  /**
   * Function to sort list alphabetically
   */
  private sortBuildingsAlphabetically(buildings: Building[]): Building[] {
    return buildings.sort((a, b) => a.building_name.localeCompare(b.building_name));
  }

  /**
   * Function to apply filters
   */
  public applyFilters(): void {
    const trimmedAddressFilter = this.addressFilter.trim();
    const trimmedPostalCodeFilter = this.postalCodeFilter.trim();
    const trimmedCityFilter = this.cityFilter.trim();
    const trimmedManagerEmailFilter = this.managerEmailFilter.trim();
    const trimmedManagerFirstNameFilter = this.managerFirstNameFilter.trim().toLowerCase();
    const trimmedManagerLastNameFilter = this.managerLastNameFilter.trim().toLowerCase();

    let filtered = this.buildingsList.filter(b =>
      (!trimmedAddressFilter || b.building_address.toLowerCase().includes(trimmedAddressFilter.toLowerCase())) &&
      (!trimmedPostalCodeFilter || b.building_postalCode.includes(trimmedPostalCodeFilter)) &&
      (!trimmedCityFilter || b.building_city.toLowerCase().includes(trimmedCityFilter.toLowerCase())) &&
      (!trimmedManagerEmailFilter || (b.gestionnaire_email && b.gestionnaire_email.toLowerCase().includes(trimmedManagerEmailFilter.toLowerCase()))) &&
      (!trimmedManagerFirstNameFilter || (b.gestionnaire_firstName && b.gestionnaire_firstName.toLowerCase().includes(trimmedManagerFirstNameFilter))) &&
      (!trimmedManagerLastNameFilter || (b.gestionnaire_lastName && b.gestionnaire_lastName.toLowerCase().includes(trimmedManagerLastNameFilter)))
    );

    // For the number of diagnoses
    if (this.sortOrder === 'asc' || this.sortOrder === 'desc') {
      filtered = filtered.sort((a, b) => {
        const countA = parseInt(a.diagnosis_count!, 10) || 0;
        const countB = parseInt(b.diagnosis_count!, 10) || 0;
        return this.sortOrder === 'asc' ? countA - countB : countB - countA;
      });
    }

    this.filteredBuildingsList = filtered;
  }

  /**
   * Function to reset filters
   */
  public resetFilters(): void {
    this.addressFilter = '';
    this.postalCodeFilter = '';
    this.cityFilter = '';
    this.sortOrder = 'asc';
    this.managerEmailFilter = '';
    this.filteredBuildingsList = [...this.buildingsList];
  }

  /**
   * Function to go to edit building selected
   * @param building
   */
  public editBuilding(building: Building): void {
    this.selectedBuilding = building;
    if (this.selectedAgency && this.selectedClient) {
      this.router.navigate([BUILDING_URLS.edit], {queryParams: {building: JSON.stringify(building), client: JSON.stringify(this.selectedClient), agency: JSON.stringify(this.selectedAgency)}});
    } else if (this.selectedAgency === null && this.selectedClient) {
      this.router.navigate([BUILDING_URLS.edit], {queryParams: {building: JSON.stringify(building), client: JSON.stringify(this.selectedClient)}});
    }
  }

  /**
   * Function to delete a building, opens a modal to confirm the deletion
   * @param building
   */
  public deleteBuilding(building: Building): void {
    const dialogRef = this.dialogService.open(DialogComponent, {
      context: {
        content: "Êtes-vous sûr de supprimer cet immeuble ?",
        title: "Confirmer la suppression",
        confirmButton: "Supprimer",
      },
      closeOnBackdropClick: true,
    });

    dialogRef.onClose.subscribe((result: string) => {
      if (result === 'action') {
        this.apiService.delete(`buildings/${building.building_pid}`).subscribe();
        this.filteredBuildingsList.splice(this.filteredBuildingsList.indexOf(building), 1);
      }
    });
  }

  /**
   * Function to navigate to the create building page with the client selected for the back button
   */
  public navigateToCreateBuilding(): void {
    if (this.selectedAgency && this.selectedClient) {
      this.router.navigate([BUILDING_URLS.new], {queryParams: {client: JSON.stringify(this.selectedClient), agency: JSON.stringify(this.selectedAgency)}});
    } else if (this.selectedClient && !this.selectedAgency) {
      this.router.navigate([BUILDING_URLS.new], {queryParams: {client: JSON.stringify(this.selectedClient)}});
    }
  }

  /**
   * Function to navigate to the diagnoses page with the client and the building selected
   * @param client
   * @param building
   */
  public navigateToDiagnoses(client: Client | null, building: Building): void {
    if (!client || !client.client_pid) {
      console.error('Client non défini ou sans client_pid');
      return;
    }

    this.dataService.setSelectedBuilding(building);

    if (this.selectedAgency) {
      this.router.navigate([DIAGNOSIS_URLS.list], { queryParams: { building: building.building_pid, client: client.client_pid, agency: this.selectedAgency.agency_pid } });
    } else {
      this.router.navigate([DIAGNOSIS_URLS.list], { queryParams: { building: building.building_pid, client: client.client_pid } });
    }
  }

  /**
   * Function to return to agencies page or clients page with the agency selected for the back button
   */
  public return(): void {
    if (this.selectedAgency) {
      this.router.navigate([AGENCY_URLS.list], {queryParams: {client: this.selectedClient?.client_pid}});
    } else {
      this.router.navigate([CLIENT_URLS.list]);
    }
  }

  /**
   * Function to stringify a JSON object
   */
  public stringify(object: Client | null): string {
    return JSON.stringify(object);
  }

  /**
   * Check if user has permissions
   * @param permissionKey
   */
  public hasPermission(permissionKey: string): boolean {
    const userPermissions = this.authGuard.getUserPermissions();

    if (userPermissions) {
      return userPermissions[permissionKey] && userPermissions[permissionKey] === 'WRITE';
    } else {
      return false;
    }
  }

  protected readonly DIAGNOSIS_TYPES = DIAGNOSIS_TYPES;
}
