import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {DIAGNOSIS_TYPES, DIAGNOSIS_URLS} from '../../constants/constants';
import { Client } from 'src/app/types/client.type';
import { Agency } from 'src/app/types/agency.type';
import { Building } from 'src/app/types/building.type';
import {Diagnose} from "../../types/diagnosis.type";
import { DatePipe } from '@angular/common';
import { ApiService } from "../../services/api.service";
import { DarkModeService } from "../../services/darkmode.service";

interface FormData {
  diagnosis_name: string;
  startOfContract: Date | null,
  endOfContract: Date | null ,
  diagnosisType: string
}

@Component({
  selector: 'app-diagnosis-create',
  templateUrl: './diagnosis-create.component.html',
  styleUrls: ['./diagnosis-create.component.scss']
})
export class DiagnosisCreateComponent implements OnInit {

  // If loading
  public isLoading: boolean = false;

  // Form datas
  public formData: FormData = {
    diagnosis_name: '',
    startOfContract: null as Date | null,
    endOfContract: null as Date | null,
    diagnosisType: DIAGNOSIS_TYPES[0].value,
  }

  // To define the min date
  public minDate: Date = new Date(new Date().setHours(0, 0, 0, 0));

  // To know which client/building is selected
  public selectedClient: Client | null = null;
  public selectedBuilding: Building | null = null;
  public selectedAgency: Agency | null = null;

  // To display the date error message
  public showDateError: boolean = false;
  public isFormValid: boolean = true;

  // Get the diagnosis types
  public diagnosisType = DIAGNOSIS_TYPES;

  constructor(public router: Router,
              private route: ActivatedRoute,
              private datePipe: DatePipe,
              private apiService: ApiService,
              public darkmodeService: DarkModeService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['client']) {
        this.selectedClient = JSON.parse(params['client']);
      }
      if (params['agency']) {
        this.selectedAgency = JSON.parse(params['agency']);
      }
      if (params['building']) {
        this.selectedBuilding = JSON.parse(params['building']);
      }
    });
  }

  /**
   * Function to return to the diagnoses page with the selected client and building
   */
  public returnToDiagnoses(): void {
    if (this.selectedAgency) {
      this.router.navigate([DIAGNOSIS_URLS.list], { queryParams: { building: this.selectedBuilding?.building_pid } });
    } else {
      this.router.navigate([DIAGNOSIS_URLS.list], { queryParams: { building: this.selectedBuilding?.building_pid } });
    }
  }

  /**
   * Function to create a new diagnosis
   * @private
   */
  public createDiagnosis(): void {

    this.isLoading = true;

    if (this.verifyForm()) {
      const formatedStartOfContract = this.datePipe.transform(this.formData.startOfContract, 'dd/MM/yyyy');
      const formatedEndOfContract = this.datePipe.transform(this.formData.endOfContract, 'dd/MM/yyyy');

      const diagnosisData: Diagnose = {
        diagnosis_name: this.formData.diagnosis_name,
        start_of_contract: formatedStartOfContract || '',
        end_of_contract: formatedEndOfContract || '',
        diagnosis_type: this.formData.diagnosisType,
        client_pid: this.selectedClient?.client_pid,
        building_pid: this.selectedBuilding?.building_pid
      };

      // Add agency_pid if agency is selected
      if (this.selectedAgency) {
        diagnosisData.agency_pid = this.selectedAgency.agency_pid;
      }

      this.apiService.post('diagnoses', diagnosisData).subscribe(() => {
        this.returnToDiagnoses();
        this.isLoading = false;
      });
    }
    this.isLoading = false;
  }

  /**
   * Function to check if the form is valid
   */
  public verifyForm(): boolean {
    if (this.formData.diagnosis_name !== '' && this.formData.startOfContract !== null && this.formData.endOfContract !== null) {
      if (this.formData.startOfContract > this.formData.endOfContract) {
        this.showDateError = true;
        this.isFormValid = false;
      } else {
        this.showDateError = false;
        this.isFormValid = true;
      }
    } else {
      this.isFormValid = false;
    }

    return this.isFormValid;
  }


  /**
   * Function to check if any field in the form is empty
   */
  public isFormEmpty(): boolean {
    return this.formData.diagnosis_name === '' || this.formData.startOfContract === null || this.formData.endOfContract === null;
  }
}
