 <div class="files-container" (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)">
  <Sidebar></Sidebar>
  <div class="files-page padding">

    <!-- FILES PAGE TITLE -->
    <div class="files-title">
      <Breadcrumb *ngIf="selectedAgency" [links]="[{title: this.selectedClient?.client_name || '', link: CLIENT_URLS.list, icon: 'client-grey'},
                                                   {title: this.selectedAgency?.agency_name || '', link: AGENCY_URLS.list, icon: 'agency-grey', queryParams: { client: this.selectedClient?.client_pid }},
                                                   {title: this.selectedBuilding?.building_name || '', link: BUILDING_URLS.list, icon: 'building-grey', queryParams: { client: this.selectedClient?.client_pid, agency: this.selectedAgency?.agency_pid }},
                                                   {title: this.selectedDiagnosis?.diagnosis_type || '', link: DIAGNOSIS_URLS.list, icon: 'diagnostic-grey', queryParams: { building: this.selectedBuilding?.building_pid }},
                                                    {title: 'Fichiers', link: FILE_URLS.list, icon: darkmodeService.getDarkModeState() ? 'file-light' : 'file-dark'}]"></Breadcrumb>
      <Breadcrumb *ngIf="!selectedAgency" [links]="[{title: this.selectedClient?.client_name || '', link: CLIENT_URLS.list, icon: 'client-grey'},
                                                    {title: this.selectedBuilding?.building_name || '', link: BUILDING_URLS.list, icon: 'building-grey', queryParams: { client: this.selectedClient?.client_pid }},
                                                    {title: this.selectedDiagnosis?.diagnosis_type || '', link: DIAGNOSIS_URLS.list, icon: 'diagnostic-grey', queryParams: { building: this.selectedBuilding?.building_pid }},
                                                    {title: 'Fichiers', link: FILE_URLS.list, icon: darkmodeService.getDarkModeState() ? 'file-light' : 'file-dark'}]"></Breadcrumb>
      <div>
        <button nbButton (click)="backToDiagnoses()"><nb-icon icon="arrow-ios-back-outline"></nb-icon>Retour</button>
        <app-comments-modal [resourcePid]="selectedDiagnosis?.diagnosis_pid || ''"></app-comments-modal>
      </div>
    </div>

    <!-- FILES LISTING SECTION -->
    <!-- FILES HEADER -->
    <div class="files-header">
      <div class="files-upload">
        <input type="file" #fileInput style="display: none" (change)="onFileSelect($event)" multiple>
        <button *ngIf=" !uploading && (hasPermission('files', 'READ') || hasPermission('files', 'WRITE'))" nbButton status="primary" size="medium" [disabled]="uploading" (click)="fileInput.click()">Ajouter un fichier</button>
        <button *ngIf="uploading" [nbSpinner]="uploading" nbButton status="primary" size="medium" [disabled]="uploading"></button>
      </div>
    </div>

    <!-- FILTERS -->
    <ul>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par nom du fichier"></ParagraphAtom>
        <input [(ngModel)]="fileNameFilter" nbInput placeholder="Nom du fichier" />
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Email"></ParagraphAtom>
        <input [(ngModel)]="addedByFilter" nbInput placeholder="Ajouté par" />
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par date d'ajout"></ParagraphAtom>
        <input nbInput [nbDatepicker]="addDatePicker" placeholder="Date d'ajout" [(ngModel)]="addDateFilter">
        <nb-datepicker #addDatePicker></nb-datepicker>
      </li>
      <li>
        <button nbButton outline status="warning" (click)="resetFilters()">Réinitialiser</button>
      </li>
      <li>
        <button nbButton outline status="primary" (click)="applyFilters()">Filtrer</button>
      </li>
    </ul>

    <!-- AGENCIES LIST -->
    <div class="files-list-container">
      <table-header [searchBar]="false" *ngIf="hasPermission('files', 'WRITE')" tableTitle="Fichiers" [headers]="FILE_HEADERS.permission" [dataList]="filteredFilesList" [params]="['file_name', 'user_email', 'created_at', 'actions']" (downloadClicked)="downloadFile($event)" (deleteClicked)="deleteFile($event)" (searchInputEvent)="onFilesSearch($event)"></table-header>
      <table-header [searchBar]="false" *ngIf="!hasPermission('files', 'WRITE')" tableTitle="Fichiers" [headers]="FILE_HEADERS.noPermission" [dataList]="filteredFilesList" [params]="['file_name', 'user_email', 'created_at']"></table-header>
    </div>
  </div>
</div>
