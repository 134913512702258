import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {PERMISSION_TYPES, USER_URLS} from "../../constants/constants";
import { User } from 'src/app/types/user.type';
import {ApiService} from "../../services/api.service";
import {HelperService} from "../../services/helper.service";
import { DarkModeService } from "../../services/darkmode.service";

interface updateData {
  user_firstName: string,
  user_lastName: string,
  user_email: string,
  user_password?: string,
  user_permissions?: {
    addresses: string,
    clients: string,
    users: string,
    notifications: string,
    agencies: string,
    buildings: string,
    diagnosis: string,
    files: string,
    appointments: string,
    comments: string,
  }
  buildings: string[]
}

interface userPermission {
  user_permissions?: {
    addresses: string,
    clients: string,
    users: string,
    notifications: string,
    agencies: string,
    buildings: string,
    diagnosis: string,
    files: string,
    appointments: string,
    comments: string,
  }
}

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {

  public readonly USER_URLS = USER_URLS;

  public permissionOptions = Object.values(PERMISSION_TYPES);

  // If loading
  public isLoading: boolean = false;

  // Buildings list
  public buildingsList: any[] = [];

  // To know which user is selected
  public selectedUser!: User;

  // Boolean to know if the email is valid
  public isEmailValid: boolean = true;

  public newPassword: string = "";

  constructor(public router: Router,
              private route: ActivatedRoute,
              private readonly apiService: ApiService,
              private readonly helperService: HelperService,
              public darkmodeService: DarkModeService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.selectedUser = JSON.parse(params['user']);

      // Init user permissions
      if (Object.keys(this.selectedUser!.permissions!).length != 0) {
        this.formData.user_permissions = this.selectedUser.permissions;
      }

      // Get buildings associated with the user
      this.apiService.get(`users/${this.selectedUser.user_pid}/buildings`).subscribe(response => {
        // Exrtact the building_pid of the associated buildings
        this.formData.buildings = response.data.map((building: any) => building.building_pid);

        // Get the list of buildings
        this.apiService.get('buildings').subscribe(response => {
          this.buildingsList = response.data;

          // Filter the buildings list to sort the selected buildings first
          this.buildingsList.sort((a: any, b: any) => {
            const isASelected = this.formData.buildings.includes(a.building_pid);
            const isBSelected = this.formData.buildings.includes(b.building_pid);
            return isASelected === isBSelected ? 0 : isASelected ? -1 : 1;
          });
        });
      });
    });
  }

  public formData: any = {
    buildings: [],
    user_permissions: {
      addresses: PERMISSION_TYPES.None.value,
      clients: PERMISSION_TYPES.None.value,
      users: PERMISSION_TYPES.None.value,
      notifications: PERMISSION_TYPES.None.value,
      agencies: PERMISSION_TYPES.None.value,
      buildings: PERMISSION_TYPES.None.value,
      diagnosis: PERMISSION_TYPES.None.value,
      files: PERMISSION_TYPES.None.value,
      appointments: PERMISSION_TYPES.None.value,
      comments: PERMISSION_TYPES.None.value,
    }
  }

  /**
   * Function to edit the information of an user
   * @private
   */
  private editUser(): void {
    this.isLoading = true;
    let userData: updateData = {
      user_firstName: this.selectedUser.user_firstName,
      user_lastName: this.selectedUser.user_lastName,
      user_email: this.selectedUser.user_email,
      user_permissions: this.formData.user_permissions,
      buildings: this.formData.buildings
    }
    if (this.newPassword) {
      userData = {...userData, user_password: this.newPassword};
    }

    this.apiService.patch(`users/${this.selectedUser.user_pid}`, userData).subscribe((response) => {
      this.returnToUser()
      this.isLoading = false;
    })
  }

  /** Get the label for display french label on select view */
  public getPermissionLabel(value: string): string {
    const permission = this.permissionOptions.find(option => option.value === value);
    return permission ? permission.label : '';
  }

  /**
   * Function to check if every input isn't empty
   */
  public isFormEmpty(): boolean {
    return this.selectedUser?.user_firstName !== '' && this.selectedUser?.user_email !== '' && this.selectedUser?.user_lastName !== '';
  }

  /**
   * Function that send the user to the user list page
   */
  public returnToUser(): void {
    this.router.navigate([USER_URLS.list]);
  }
  /**
   * Function that will check if the mail is valid and will call editUser function
   */
  public verifyForm(): void {
    this.isEmailValid = this.helperService.emailRegex.test(this.selectedUser!.user_email)
    if (this.isEmailValid)
      this.editUser();
  }
}
