import { Component, OnInit } from '@angular/core';
import {Client} from "../../types/client.type";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../../services/api.service";
import {HelperService} from "../../services/helper.service";
import {AGENCY_URLS, CLIENT_URLS} from "../../constants/constants";
import { DarkModeService } from "../../services/darkmode.service";
import { User } from "../../types/user.type";

interface FormData {
  name: string,
  email: string,
  address: string,
  postalCode: string,
  city: string,
}

@Component({
  selector: 'app-agency-create',
  templateUrl: './agency-create.component.html',
  styleUrls: ['./agency-create.component.scss']
})
export class AgencyCreateComponent implements OnInit {

  public readonly AGENCY_URLS = AGENCY_URLS;

  // If loading
  public isLoading: boolean = false;

  // Stores a list of selected users
  public selectedUsers: User[] = [];

  // Agency datas
  public formData: FormData = {
    name: '',
    email: '',
    address: '',
    city: '',
    postalCode: '',
  }

  // To know which client is selected
  public selectedClient: Client | null = null;

  // Boolean to know if the email follow the regex norms
  public isEmailValid: boolean = true;

  // List that store every user
  public userList: User[] = [];

  // Value to store the selected user
  public selectedUser: User | null = null;

  // Stores an empty user
  public defaultUser: User = {
    user_firstName: "AUCUN",
    user_lastName: "",
    user_email: "",
  }

  constructor(public router: Router,
              private route: ActivatedRoute,
              private apiService: ApiService,
              private helperService: HelperService,
              public darkmodeService: DarkModeService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['client']) {
        this.selectedClient = JSON.parse(params['client']);
      }
    });

    this.apiService.get('users').subscribe((userList) => {
      this.userList = userList.data;
      this.userList.unshift(this.defaultUser);
    });
  }

  /**
   * Function to check if the form is valid
   */
  public isFormEmpty(): boolean {
    return this.formData.name !== '' && this.formData.email !== '' && this.formData.address !== '' && this.formData.postalCode !== '' && this.formData.city !== '';
  }

  /**
   * Function to return to agencies page with the client selected
   */
  public returnToAgencies(): void {
    this.router.navigate([AGENCY_URLS.list], { queryParams: { client: this.selectedClient?.client_pid } });
  }

  /**
   * Function to create a new agencies
   * @private
   */
  private createAgency(): void {
    this.isLoading = true;
    const agencyData = {
      agency_name: this.formData.name,
      agency_email: this.formData.email,
      agency_address: this.formData.address,
      agency_postalCode: this.formData.postalCode,
      agency_city: this.formData.city,
      client_pid: this.selectedClient?.client_pid,
      user_pids: this.selectedUsers.map(user => user.user_pid),
    };

    this.apiService.post('agencies', agencyData).subscribe((response) => {
      const agencyPid = response.agency_pid;
      this.linkUsersToAgency(agencyPid);
      this.returnToAgencies();
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }

  private linkUsersToAgency(agencyPid: string): void {
    const userPids = this.selectedUsers.map(user => user.user_pid);
    userPids.forEach(userPid => {
      const userAgencyData = {
        user_pid: userPid,
        agency_pid: agencyPid
      };
      this.apiService.post('users_agencies', userAgencyData).subscribe();
    });
  }

  /**
   * Function to check if the email is valid
   */
  public verifyForm(): void {
    this.isEmailValid = this.helperService.emailRegex.test(this.formData.email)
    if (this.isEmailValid)
      this.createAgency()
  }


}
