import { Component, OnInit, Input } from '@angular/core';
import { AuthGuard } from '../../services/auth.guard';

interface QueryParams {
  client?: string,
  agency?: string,
  building?: string,
}

@Component({
  selector: 'Breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() links: Array<{title: string, link?: string, icon?: string, queryParams?: QueryParams, permission?: string}> = [];

  constructor(private authGuard: AuthGuard) {}

  ngOnInit(): void {}

  public hasPermission(section: string | undefined, permission?: string): boolean {
    if (!section) {
      return true;
    }
    const test = this.authGuard.hasPermission(section, permission);

    return test;
  }
}
