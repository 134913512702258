import { Component, OnInit } from '@angular/core';
import { User } from "../../types/user.type";
import { Client } from "../../types/client.type";
import { Agency } from "../../types/agency.type";
import { Building } from "../../types/building.type";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { DatePipe } from "@angular/common";
import { APPOINTMENT_URLS, DIAGNOSIS_TYPES, STATUS_OPTIONS } from "../../constants/constants";
import { Appointment } from "../../types/appointment.type";
import { DarkModeService } from "../../services/darkmode.service";

@Component({
  selector: 'app-appointment-edit',
  templateUrl: './appointment-edit.component.html',
  styleUrls: ['./appointment-edit.component.scss']
})
export class AppointmentEditComponent implements OnInit {

  // If loading
  public isLoading: boolean = false;

  // Variable to store the appointment day
  public appointment_day: Date | null = null;

  // List to store every user
  public userList: User[] = [];

  // List to store every client
  public clientList: Client[] = [];

  // List to store every agency
  public agencyList: Agency[] = []

  // List to store every building
  public buildingList: Building[] = []

  // Variable to store the selected users
  public selectedUsers: User[] = []

  // Variable to store the selected client
  public selectedClient: Client | null = null

  // Variable to store the selected agency
  public selectedAgency: Agency | null = null

  // Variable to store the selected building
  public selectedBuilding: Building | null = null

  // Variable to store the selected appointment
  public selectedAppointment: Appointment | null = null;

  // Variable to get the diagnosis for select
  public selectedDiagnosisTypes: string[] = [];
  public diagnosisTypes: { label: string; value: string }[] = DIAGNOSIS_TYPES;

  // Variable to store the selected status
  public selectedStatus: string = '';

  // Options for status
  public statusOptions: string[] = STATUS_OPTIONS;

  // Boolean to show agency select
  public showAgency: Boolean = false;

  // Boolean to show building select
  public showBuilding: Boolean = true;

  // When the client doesn't have any agency
  public foundAgency: Boolean = true;

  // When there's no building found
  public foundBuilding: Boolean = true;

  // To define the min date
  public minDate: Date = new Date(new Date().setHours(0, 0, 0, 0));

  constructor(public router: Router,
              private route: ActivatedRoute,
              private apiService: ApiService,
              private datePipe: DatePipe,
              public darkmodeService: DarkModeService,
  ) { }

  ngOnInit(): void {
    // Get the selected appointment
    this.route.queryParams.subscribe(params => {
      if (params['appointment']) {
        this.selectedAppointment = JSON.parse(params['appointment']);

        this.selectedStatus = this.selectedAppointment!.status;
        if (typeof this.selectedAppointment?.user_pid === 'string') {
          this.selectedAppointment.user_pid = JSON.parse(this.selectedAppointment.user_pid);
        }
        if (typeof this.selectedAppointment?.user_email === 'string') {
          this.selectedAppointment.user_email = JSON.parse(this.selectedAppointment.user_email);
        }

        // Initialize selected diagnosis types
        if (this.selectedAppointment?.diagnosis_type && Array.isArray(this.selectedAppointment.diagnosis_type)) {
          const diagnosisValues = this.selectedAppointment.diagnosis_type; // Array of values (e.g., ['DU', 'EF', 'Elec loge'])
          this.selectedDiagnosisTypes = diagnosisValues; // Use values directly
        } else {
          console.warn('Selected Appointment or Diagnosis Type is not valid.');
        }
      }
    });

    this.apiService.get('users').subscribe((returnedList) => {
      this.userList = returnedList.data;
      if (this.selectedAppointment?.user_pid) {
        this.selectedUsers = this.userList.filter(user => user.user_pid && this.selectedAppointment!.user_pid!.includes(user.user_pid));
      }
    });

    this.apiService.get('clients').subscribe((returnedList) => {
      this.clientList = returnedList.data;
      this.selectedClient = this.clientList.find(client => client.client_pid === this.selectedAppointment?.client_pid) || this.clientList[0];

      // If the client is a particulier, we need to get the buildings of the client
      if (this.selectedClient?.client_type === "particulier") {
        this.apiService.get(`clients/${this.selectedClient.client_pid}/buildings`).subscribe((returnedList) => {
          this.buildingList = returnedList.data;
          this.selectedBuilding = this.buildingList.find(building => building.building_pid === this.selectedAppointment?.building_pid) || this.buildingList[0];
          this.showBuilding = true;
        });
      } else {
        // If the client is not a particulier, we need to get the agency and the buildings of the building
        this.apiService.get(`buildings/${this.selectedAppointment?.building_pid}`).subscribe((returnedBuilding) => {
          this.selectedBuilding = returnedBuilding.data[0];

          this.apiService.get(`clients/${this.selectedClient?.client_pid}/agencies`).subscribe((returnedList) => {
            this.agencyList = returnedList.data;
            this.showAgency = true;
            this.selectedAgency = this.agencyList.find(agency => agency.agency_pid === this.selectedBuilding?.agency_pid) || this.agencyList[0];

            this.apiService.get(`agencies/${this.selectedAgency.agency_pid}/buildings`).subscribe((returnedList) => {
              this.buildingList = returnedList.data;
              this.selectedBuilding = this.buildingList.find(building => building.building_pid === this.selectedAppointment?.building_pid) || this.buildingList[0];
            });
          });
        });
      }
    });

    const [startDay, startMonth, startYear] = this.selectedAppointment!.appointment_date.split('/').map(Number);
    this.appointment_day = new Date(startYear, startMonth - 1, startDay);
  }

  /**
   * Function that is activated when the user select a client
   * @param client - The client selected by the user
   */
  public onClientSelect(client: Client): void {
    if (client.client_type !== "particulier") {
      this.foundAgency = true;
      this.foundBuilding = true;
      this.showBuilding = false;
      this.selectedAgency = null;
      this.apiService.get(`clients/${client.client_pid}/agencies`).subscribe((returnedList) => {
        this.agencyList = returnedList.data;
        this.showAgency = true;
      }, () => {
        this.foundAgency = false;
        this.showAgency = false;
      });
    } else {
      this.foundBuilding = true;
      this.foundAgency = true;
      this.showAgency = false;
      this.selectedBuilding = null;
      this.apiService.get(`clients/${client.client_pid}/buildings`).subscribe((returnedList) => {
        this.buildingList = returnedList.data;
        this.showBuilding = true;
      }, () => {
        this.foundAgency = false;
        this.showBuilding = false;
      });
    }
  }

  /**
   * Function that is activated when the user select an agency
   * @param agency - The agency selected by the user
   */
  public onAgencySelect(agency: Agency): void {
    this.selectedBuilding = null;
    this.apiService.get(`agencies/${agency.agency_pid}/buildings`).subscribe((returnedList) => {
      this.buildingList = returnedList.data;
      this.showBuilding = true;
      this.foundBuilding = true;
    }, () => {
      this.foundBuilding = false;
      this.showBuilding = false;
    });
  }

  // Sends the user back to the appointment list page
  public returnToAppointment(): void {
    this.router.navigate([APPOINTMENT_URLS.list]);
  }

  // Function that checks if the form is valid
  public isFormValid(): boolean {
    if (this.selectedClient?.client_type !== "particulier") {
      return !!(this.selectedUsers.length && this.selectedClient?.client_pid && this.selectedAgency?.agency_pid && this.selectedBuilding?.building_pid && this.appointment_day && this.selectedStatus);
    } else {
      return !!(this.selectedUsers.length && this.selectedClient?.client_pid && this.selectedBuilding?.building_pid && this.appointment_day && this.selectedStatus);
    }
  }

  // Method to get selected diagnosis labels
  public getSelectedDiagnosisLabels(): string {
    return this.selectedDiagnosisTypes.join(', ');
  }

  // Function to create the appointment
  public editAppointment(): void {
    this.isLoading = true;
    const formatedAppointmentDay = this.datePipe.transform(this.appointment_day, 'dd/MM/yyyy');

    const appointmentData: Appointment = {
      user_pid: this.selectedUsers.map(user => user.user_pid!).filter(pid => pid !== undefined) as string[],
      user_email: this.selectedUsers.map(user => user.user_email),
      client_pid: this.selectedClient!.client_pid!,
      client_name: this.selectedClient!.client_name,
      building_pid: this.selectedBuilding!.building_pid!,
      building_name: this.selectedBuilding!.building_name,
      appointment_date: formatedAppointmentDay!,
      diagnosis_type: this.selectedDiagnosisTypes,
      status: this.selectedStatus,
    };

    this.apiService.patch(`appointments/${this.selectedAppointment?.appointment_pid}`, appointmentData).subscribe(() => {
      this.returnToAppointment();
      this.isLoading = false;
    });
  }

  // Method to get selected users' emails as a string
  getSelectedUserEmails(): string {
    return this.selectedUsers.map(user => user.user_email).join(', ');
  }
}
