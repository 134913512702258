import { Injectable } from '@angular/core'
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
  ) {
  }

  /**
   * Set a new token into the local storage
   * @param key
   * @param value
   */
  public setItem(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  /**
   * Get the token from the local storage
   * @return The item
   */
  public getItem(key: string): any {
    return localStorage.getItem(key);
  }

  /**
   * Clear all data in local storage
   */
  public clearLocalStorage(): any {
    return localStorage.clear();
  };


}
