<div class="appointments-container">
  <Sidebar></Sidebar>
  <div class="appointments-page padding">

    <!-- APPOINTMENT TITLE -->
    <div class="appointments-title">
      <TitleAtom size="large" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Créer un nouveau rendez-vous"></TitleAtom>
    </div>

    <!-- CREATE APPOINTMENT SECTION -->
    <!-- APPOINTMENTS HEADER -->
    <div class="create-header">
      <button nbButton (click)="returnToAppointment()"><nb-icon icon="arrow-ios-back-outline"></nb-icon>Retour</button>
    </div>

    <!-- CREATE FORM -->
    <div class="appointment-create-container">
      <TitleAtom size="medium" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Entrez les informations du nouveau rendez-vous"></TitleAtom>

      <ul>
        <li>
          <div>
            <ParagraphAtom text="Sélectionner des utilisateurs" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <nb-select placeholder="Sélectionner des utilisateurs" fullWidth [(selected)]="selectedUsers" multiple>
              <nb-select-label>
                {{ getSelectedUserEmails() }}
              </nb-select-label>

              <nb-option *ngFor="let user of userList" [value]="user">
                {{ user.user_email | titlecase }}
              </nb-option>
            </nb-select>
          </div>
        </li>
        <li>
          <div>
            <ParagraphAtom text="Sélectionner un client" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <nb-select (selectedChange)="onClientSelect($event)" placeholder="Sélectionner un client" fullWidth [(selected)]="selectedClient">
              <nb-select-label>
                {{ selectedClient?.client_name }}
              </nb-select-label>

            <nb-option *ngFor="let client of clientList" [value]="client">
              {{ client.client_name }}
            </nb-option>
            </nb-select>
          </div>
        </li>
        <li *ngIf="showAgency">
          <div>
            <ParagraphAtom text="Sélectionner une agence" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <nb-select (selectedChange)="onAgencySelect($event)" placeholder="Sélectionner une agence" fullWidth [(selected)]="selectedAgency">
              <nb-select-label>
                {{ selectedAgency?.agency_name }}
              </nb-select-label>

              <nb-option *ngFor="let agency of agencyList" [value]="agency">
                {{ agency.agency_name }}
              </nb-option>
            </nb-select>
          </div>
        </li>
        <li *ngIf="showBuilding">
          <div>
            <ParagraphAtom text="Sélectionner un immeuble" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <nb-select placeholder="Sélectionner un immeuble" fullWidth [(selected)]="selectedBuilding">
              <nb-select-label>
                {{ selectedBuilding?.building_name }}
              </nb-select-label>

            <nb-option *ngFor="let building of buildingList" [value]="building">
              {{ building.building_name }}
            </nb-option>
            </nb-select>
          </div>
        </li>
        <ParagraphAtom *ngIf="!foundAgency" text="Aucune agence n'a été trouvée" color="red" size="nano" weight="semi-bold"></ParagraphAtom>
        <ParagraphAtom *ngIf="!foundBuilding" text="Aucun immeuble n'a été trouvé" color="red" size="nano" weight="semi-bold"></ParagraphAtom>
        <li>
          <div>
            <ParagraphAtom text="Type d'intervention" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <nb-select fullWidth [(selected)]="selectedDiagnosisType">
              <nb-select-label>
                {{ selectedDiagnosisType?.label }}
              </nb-select-label>

              <nb-option *ngFor="let type of diagnosisTypes" [value]="type">
                {{ type.label }}
              </nb-option>
            </nb-select>
          </div>
        </li>
        <li>
          <div>
            <ParagraphAtom text="Date du rendez-vous" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <input nbInput placeholder="Date du rendez-vous..." [nbDatepicker]="datepickerStart" [(ngModel)]="this.appointment_day" readOnly>
            <nb-datepicker #datepickerStart [min]="minDate"></nb-datepicker>
          </div>
        </li>
        <li>
          <div>
            <ParagraphAtom text="État d'avancement" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <nb-select fullWidth [(selected)]="selectedStatus">
              <nb-select-label>
                {{ selectedStatus }}
              </nb-select-label>
              <nb-option *ngFor="let status of statusOptions" [value]="status">
                {{ status }}
              </nb-option>
            </nb-select>
          </div>
        </li>
        <li>
          <button nbButton fullWidth status="primary" [disabled]="!isFormValid() || isLoading" (click)="createAppointment()">Sauvegarder</button>
        </li>
      </ul>
    </div>

  </div>
</div>
