import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  /* REGEX */
  public emailRegex = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');

  // For tel ihput
  public telRegex = /^[0-9]{10}$/;

  constructor(
  ) { }


}
