<div class="notifications-container">
    <Sidebar></Sidebar>
    <div class="notifications-page padding">

      <!-- NOTIFICATIONS PAGE TITLE -->
      <div class="notifications-title">
        <Breadcrumb [links]="[{title: 'Notifications', link: NOTIFICATION_URLS.list, icon: darkmodeService.getDarkModeState() ? 'notification-light' : 'notification-dark'}]"></Breadcrumb>
      </div>

      <ul class="notifications-filter-container">
        <li>
          <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par client"></ParagraphAtom>
          <input [(ngModel)]="clientFilter" nbInput placeholder="Client" />
        </li>
        <li>
          <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par immeuble"></ParagraphAtom>
          <input [(ngModel)]="buildingFilter" nbInput placeholder="Immeuble" />
        </li>
        <li>
          <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par date d'envoi"></ParagraphAtom>
          <input nbInput [nbDatepicker]="sendingDatePicker" placeholder="Date d'envoi" [(ngModel)]="sendingDateFilter">
          <nb-datepicker #sendingDatePicker></nb-datepicker>
        </li>
        <li>
          <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par date de création"></ParagraphAtom>
          <input nbInput [nbDatepicker]="creationDatePicker" placeholder="Date de création" [(ngModel)]="creationDateFilter">
          <nb-datepicker #creationDatePicker></nb-datepicker>
        </li>
      </ul>

      <ul class="notifications-filters-btn">
        <li>
          <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Entre une première date"></ParagraphAtom>
          <input nbInput [nbDatepicker]="startDatePicker" placeholder="Date de début..." [(ngModel)]="startDateFilter">
          <nb-datepicker #startDatePicker></nb-datepicker>
        </li>
        <li>
          <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Et une seconde date"></ParagraphAtom>
          <input nbInput [nbDatepicker]="endDatePicker" placeholder="Date de fin..." [(ngModel)]="endDateFilter">
          <nb-datepicker #endDatePicker></nb-datepicker>
        </li>
        <li>
          <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par année précise"></ParagraphAtom>
          <input type="number" nbInput placeholder="Année (ex. 2023)" [(ngModel)]="yearFilter">
        </li>
        <li>
          <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par statut"></ParagraphAtom>
          <nb-select placeholder="Statut" [(selected)]="statusFilter">
            <nb-option *ngFor="let status of notificationStatusArray" [value]="status.label">
              {{ status.label }}
            </nb-option>
          </nb-select>
        </li>
      </ul>

      <ul class="notifications-filters-btn">
        <li>
          <button nbButton outline status="warning" (click)="resetFilters()">Réinitialiser</button>
        </li>
        <li>
          <button nbButton outline status="primary" (click)="applyFilters()">Filtrer</button>
        </li>
      </ul>

      <!-- NOTIFICATIONS LIST -->
      <div class="notifications-list-container">
          <table-header [searchBar]="false" tableTitle="Notifications" [headers]="NOTIFICATION_HEADERS.permission" [dataList]="filteredNotificationsList" [params]="['client_name', 'building_name', 'sending_at', 'created_at', 'last_sending_date', 'notification_status', 'actions']" (resendClicked)="resendEmail($event)" (deleteClicked)="deleteNotification($event)" (searchInputEvent)="onNotificationSearch($event)"></table-header>
      </div>


    </div>
  </div>

