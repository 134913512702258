<div class="sidebar-container">
  <div class="sidebar-logo">
    <TitleAtom color="light" size="intermediate" weight="semi-bold" type="h1" text="PRODIAG"></TitleAtom>
  </div>
  <ul>
    <!--<li>
      <ButtonAtom text="Accueil" [style]="'clear'" color="{{isHomePage ? 'light' : 'grey'}}" (click)="this.router.navigate([HOME_URLS.home])"></ButtonAtom>
    </li>-->
    <li *ngIf="hasPermission('users')" [ngClass]="isUserPage ? 'active' : ''">
      <ImageAtom type="icon" image="user-light" width="20px"></ImageAtom>
      <ButtonAtom text="Utilisateurs" [style]="'clear'" color="{{isUserPage ? 'light' : 'light'}}" (click)="this.router.navigate([USER_URLS.list])" ></ButtonAtom>
    </li>
    <li [ngClass]="isClientPage ? 'active' : ''">
      <ImageAtom type="icon" image="case" width="20px"></ImageAtom>
      <ButtonAtom text="Clients" [style]="'clear'" color="{{isClientPage ? 'light' : 'light'}}" (click)="redirectToClients()"></ButtonAtom>
    </li>
    <li *ngIf="hasPermission('addresses')" [ngClass]="isAddressPage ? 'active' : ''">
      <ImageAtom type="icon" image="building-light" width="18px"></ImageAtom>
      <ButtonAtom text="Adresses" [style]="'clear'" color="{{isClientPage ? 'light' : 'light'}}" (click)="this.router.navigate([ADDRESSES_URLS.list])"></ButtonAtom>
    </li>
    <li *ngIf="hasPermission('notifications')" [ngClass]="isNotificationPage ? 'active' : ''">
      <ImageAtom type="icon" image="notification" width="18px"></ImageAtom>
      <ButtonAtom text="Notifications" [style]="'clear'" color="{{isNotificationPage ? 'light' : 'light'}}" (click)="this.router.navigate([NOTIFICATION_URLS.list])"></ButtonAtom>
    </li>
    <li *ngIf="hasPermission('appointments')" [ngClass]="isAppointmentPage ? 'active' : ''">
      <ImageAtom type="icon" image="calendar" width="18px"></ImageAtom>
      <ButtonAtom text="Mes rendez-vous" [style]="'clear'" color="{{isAppointmentPage ? 'light' : 'light'}}" (click)="this.router.navigate([APPOINTMENT_URLS.list])"></ButtonAtom>
    </li>
  </ul>
  <div class="account-section">
    <div (click)="this.router.navigate([ACCOUNT_URLS.account])">
      <TitleAtom color="light" size="tiny" weight="semi-bold" type="p" text="{{this.user_firstName}} {{this.user_lastName}}"></TitleAtom>
      <ParagraphAtom text="Mon compte" [style]="'clear'" color="{{isAccountPage ? 'light' : 'light'}}"></ParagraphAtom>
    </div>
  </div>
  <div class="sidebar-darkmode">
    <nb-toggle [checked]="isDarkMode()" (checkedChange)="toggleDarkMode()"></nb-toggle>
    <ParagraphAtom size="medium" weight="regular" color="light" text="Mode sombre"></ParagraphAtom>
  </div>
</div>
