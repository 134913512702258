<div class="buildings-container">
  <Sidebar></Sidebar>
  <div class="buildings-page padding">

    <!-- BUILDINGS PAGE TITLE -->
    <div class="buildings-title">
      <TitleAtom size="large" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Ajouter un nouvel immeuble"></TitleAtom>
    </div>

    <!-- CREATE BUILDING SECTION -->
    <!-- CLIENTS HEADER -->
    <div class="create-header">
        <button nbButton (click)="returnToBuildings()"><nb-icon icon="arrow-ios-back-outline"></nb-icon>Retour</button>
    </div>

    <!-- CREATE FORM -->
    <div class="buildings-create-container">
      <TitleAtom size="medium" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Entrez les informations de l'immeuble"></TitleAtom>

      <ul>
        <li>
          <div>
            <ParagraphAtom text="Nom de l'immeuble" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <input type="text" nbInput [(ngModel)]="formData.name" placeholder="Nom de l'immeuble...">
          </div>
        </li>
<!--        <li class="form-email">
          <ParagraphAtom text="Email" color="dark" size="medium" weight="semi-bold"></ParagraphAtom>
          <input type="text" nbInput fullWidth [(ngModel)]="formData.email" placeholder="Email...">
          <ParagraphAtom *ngIf="!isEmailValid" text="L'email n'est pas valide" color="red" size="nano" weight="semi-bold"></ParagraphAtom>
        </li>-->
        <li class="form-email">
          <ParagraphAtom text="Adresse" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
          <input type="text" nbInput fullWidth [(ngModel)]="formData.address" placeholder="Adresse...">
        </li>
        <li>
          <div>
            <ParagraphAtom text="Code Postal" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <input type="text" nbInput fullWidth [(ngModel)]="formData.postalCode" placeholder="Code Postal...">
          </div>
          <div>
            <ParagraphAtom text="Ville" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <input type="text" nbInput fullWidth [(ngModel)]="formData.city" placeholder="Ville...">
          </div>
        </li>
        <li>
          <div>
            <ParagraphAtom text="Nom du gardien ou employé d'immeuble" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <input type="text" nbInput fullWidth [(ngModel)]="formData.employeeName" placeholder="Nom Prénom...">
          </div>
        </li>
        <li class="form-tel">
          <ParagraphAtom text="Numéro de téléphone du gardien ou employé d'immeuble" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
          <input type="tel" nbInput fullWidth [(ngModel)]="formData.tel" placeholder="Tel..." (ngModelChange)="verifyPhoneNumber()">
          <span *ngIf="!isPhoneNumberValid" class="error-message">Numéro de téléphone non valide</span>
        </li>
        <li *ngIf="this.selectedAgency">
          <div>
            <ParagraphAtom text="Sélectionnez le gestionnaire" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <nb-select fullWidth placeholder="Gestionnaire..." [(selected)]="selectedUser">
              <nb-select-label>
                {{ selectedUser?.user_firstName }} {{ selectedUser?.user_lastName }}
              </nb-select-label>

              <nb-option *ngFor="let user of managerList" [value]="user">
                {{ user.user_firstName }} {{ user.user_lastName }}
              </nb-option>
            </nb-select>
          </div>
        </li>
        <li>
          <div>
            <ParagraphAtom text="Statut de progression" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <nb-select fullWidth placeholder="Statut..." [(ngModel)]="formData.progress_status">
              <nb-option value="non défini">Non défini</nb-option>
              <nb-option value="message">Message</nb-option>
              <nb-option value="rdv pris">RDV pris</nb-option>
              <nb-option value="en rédaction">En rédaction</nb-option>
              <nb-option value="litige">Litige</nb-option>
              <nb-option value="validé">Validé</nb-option>
            </nb-select>
          </div>
        </li>        
        <li class="form-email">
          <button nbButton fullWidth status="primary" [disabled]="!isFormEmpty() || isLoading" (click)="verifyForm()">Sauvegarder</button>
        </li>
      </ul>
    </div>
  </div>
</div>
