import {Component, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BUILDING_URLS } from "../../constants/constants";
import { Client } from 'src/app/types/client.type';
import { Agency } from 'src/app/types/agency.type'
import { Building } from 'src/app/types/building.type';
import { ApiService } from "../../services/api.service";
import { HelperService } from "../../services/helper.service";
import { User } from "../../types/user.type";
import { DarkModeService } from "../../services/darkmode.service";

@Component({
  selector: 'app-building-edit',
  templateUrl: './building-edit.component.html',
  styleUrls: ['./building-edit.component.scss']
})
export class BuildingEditComponent implements OnInit {

  // To know which client is selected
  public selectedClient: Client | null = null;

  // If loading
  public isLoading: boolean = false;

  // To know which agency is selected
  public selectedAgency: Agency | null = null;

  // To know which building is selected
  public selectedBuilding: Building | null = null;

  // Variable to know which user is selected in the list
  public selectedUser: User | null = null;

  // List of user
  public managerList: User[] = [];

  // Stores an empty user
  public defaultUser: User = {
    user_firstName: "AUCUN",
    user_lastName: "",
    user_email: "",
  }

  // Boolean to know if the email follow the regex norms
  public isEmailValid: boolean = true;

  // Boolean to check if phone is valid
  public isPhoneNumberValid: boolean = true;

  constructor(public router: Router,
              private route: ActivatedRoute,
              private apiService: ApiService,
              private helperService: HelperService,
              public darkmodeService: DarkModeService,
  ) { }

  ngOnInit(): void {
    // Get the client and building selected
    this.route.queryParams.subscribe(params => {
      if (params['client']) {
        this.selectedClient = JSON.parse(params['client']);
      }
      if (params['building']) {
        this.selectedBuilding = JSON.parse(params['building']);
        // If the building_tel is null, set it to an empty string
        if (!this.selectedBuilding?.building_tel) {
          this.selectedBuilding!.building_tel = '';
        }
      }
      if (params['agency']) {
        this.selectedAgency = JSON.parse(params['agency']);
      }
    });

    if (this.selectedAgency) {
      this.apiService.get('users').subscribe((userList) => {
        this.managerList = userList.data;
        this.managerList.unshift(this.defaultUser);
        this.selectedUser = this.managerList.find(user => user.user_pid === this.selectedBuilding?.gestionnaire_pid) || this.managerList[0];
      })
    }
  }

  /**
   * Function to check if the form is empty
   */
  public isFormEmpty(): boolean {
    return !!(
      this.selectedBuilding?.building_name &&
      this.selectedBuilding?.building_address &&
      this.selectedBuilding?.building_postalCode &&
      this.selectedBuilding?.building_city
    );
  }
  

  /**
   * Function to return to buildings page with the client selected
   */
  public returnToBuildings(): void {
    if (this.selectedAgency && this.selectedClient) {
      this.router.navigate([BUILDING_URLS.list], { queryParams: { client: this.selectedClient?.client_pid, agency: this.selectedAgency?.agency_pid } });
    } else if (this.selectedClient && this.selectedAgency === null) {
      this.router.navigate([BUILDING_URLS.list], { queryParams: { client: this.selectedClient?.client_pid } });
    }
  }

  /**
   * Function to send data to edit building
   * @private
   */
  private editBuilding(): void {
    this.isLoading = true;
    const updatedBody = {
      building_name: this.selectedBuilding?.building_name,
/*
      building_email: this.selectedBuilding?.building_email,
*/
      building_address: this.selectedBuilding?.building_address,
      building_postalCode: this.selectedBuilding?.building_postalCode,
      building_city: this.selectedBuilding?.building_city,
      gestionnaire_pid: this.selectedUser?.user_pid,
      gestionnaire_email: this.selectedUser?.user_email,
      building_tel: this.selectedBuilding?.building_tel,
      building_employeeName: this.selectedBuilding?.building_employeeName,
      progress_status: this.selectedBuilding?.progress_status
    }

    this.apiService.patch(`buildings/${this.selectedBuilding?.building_pid}`, updatedBody).subscribe((response) => {
      this.returnToBuildings()})
      this.isLoading = false;
  }

  /**
   * Function to verify phone
   */
  public verifyPhoneNumber(): void {
    this.isPhoneNumberValid = !this.selectedBuilding!.building_tel || this.helperService.telRegex.test(this.selectedBuilding!.building_tel);
  }

  /**
   * Function that check if building's email respect the regex, if it does update building's data
   */
  public verifyForm(): void {
    // Valid email if empty or valid
/*
    this.isEmailValid = !this.selectedBuilding!.building_email || this.helperService.emailRegex.test(this.selectedBuilding!.building_email);
*/

    this.verifyPhoneNumber();

    if (this.isPhoneNumberValid) {
      this.editBuilding();
    }
  }
}
