<div class="clients-container">
  <Sidebar></Sidebar>
  <div class="clients-page padding">

    <!-- CLIENTS PAGE TITLE -->
    <div class="clients-title">
      <TitleAtom size="large" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Modifier le client"></TitleAtom>
    </div>

    <!-- EDIT CLIENT SECTION -->

      <!-- CLIENTS HEADER -->
      <div class="create-header">
          <button nbButton (click)="this.router.navigate([CLIENT_URLS.list])"><nb-icon icon="arrow-ios-back-outline"></nb-icon>Retour</button>
      </div>

      <!-- EDIT FORM -->
      <div class="clients-create-container">
        <TitleAtom size="medium" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Modifiez les informations du client"></TitleAtom>

        <ul>
          <li>
            <div>
              <ParagraphAtom text="Nom du client" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
              <input type="text" nbInput placeholder="Nom..." [(ngModel)]="selectedClient!.client_name">
            </div>
          </li>
          <li class="form-email">
            <ParagraphAtom text="Téléphone" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
            <input type="tel" nbInput fullWidth maxlength="10" [(ngModel)]="selectedClient!.client_phone" placeholder="Téléphone...">
          </li>
          <li>
            <div>
              <ParagraphAtom text="Sélectionnez un utilisateur" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
              <nb-select fullWidth [(selected)]="selectedUser">
                <nb-select-label>
                  {{ selectedUser?.user_firstName | titlecase }} {{ selectedUser?.user_lastName | titlecase }}
                </nb-select-label>

                <nb-option *ngFor="let user of userList" [value]="user">
                  {{ user.user_firstName | titlecase }} {{ user.user_lastName | titlecase }}
                </nb-option>
              </nb-select>
            </div>
          </li>
          <li class="form-email">
            <button nbButton fullWidth status="primary" [disabled]="!isFormEmpty() || isLoading" (click)="editClient()">Sauvegarder</button>
          </li>
        </ul>
      </div>

  </div>
</div>
