import { Component, OnInit } from '@angular/core';
import {CLIENT_TYPES, CLIENT_URLS} from '../../constants/constants';
import { Router } from '@angular/router';
import { ApiService } from "../../services/api.service";
import { HelperService } from "../../services/helper.service";
import { User } from "../../types/user.type";
import { DarkModeService } from "../../services/darkmode.service";

interface FormData {
  name: string,
  phone: string,
  clientType: string,
}

@Component({
  selector: 'app-client-create',
  templateUrl: './client-create.component.html',
  styleUrls: ['./client-create.component.scss']
})
export class ClientCreateComponent implements OnInit {

  public readonly CLIENT_URLS = CLIENT_URLS;

  // If loading
  public isLoading: boolean = false;

  // Client datas
  public formData: FormData = {
    name: '',
    phone: '',
    clientType: CLIENT_TYPES.SYNDIC,
  };

  // Get the client types
  public clientTypes = Object.values(CLIENT_TYPES);

  // Boolean to know if the email follow the regex norms
  public isEmailValid: boolean = true;

  // List that store every user
  public userList: User[] = [];

  // Value to store the selected user
  public selectedUser: User | null = null;

  // Stores an empty user
  public defaultUser: User = {
    user_firstName: "AUCUN",
    user_lastName: "",
    user_email: "",
  }

  constructor(public router: Router,
              private apiService: ApiService,
              private helperService: HelperService,
              public darkmodeService: DarkModeService,
  ) { }

  ngOnInit(): void {
    this.apiService.get('users').subscribe((userList) => {
      this.userList = userList.data;
      this.userList.unshift(this.defaultUser)
      this.selectedUser = this.userList[0];
    })
  }

  /**
   * Function to create a new client
   * @private
   */
  public createClient(): void {
    this.isLoading = true;
    const clientData = {
      client_name: this.formData.name,
      client_phone: this.formData.phone,
      client_type: this.formData.clientType,
      user_pid: this.selectedUser!.user_pid,
    };

    this.apiService.post('clients', clientData).subscribe(() => {
      this.router.navigate([CLIENT_URLS.list]);
      this.isLoading = false;
    })
  }

  /**
   * Function to check if the form is valid
   */
  public isFormEmpty(): boolean {
    return this.formData.name !== '';
  }

}
