<div class="edit-password-container">
  <Sidebar></Sidebar>
  <div class="edit-password-page padding">

    <!-- ACCOUNT PAGE TITLE -->
    <div>
      <TitleAtom size="large" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Mon compte"></TitleAtom>
    </div>

    <!-- Return button -->
    <div class="create-header">
      <button nbButton (click)="this.router.navigate([ACCOUNT_URLS.account])">Retour</button>
    </div>

    <div class="edit-password-create-container">
      <TitleAtom size="medium" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Modifier mes informations"></TitleAtom>

      <ul>
        <li>
          <ParagraphAtom text="Nouveau mot de passe" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
          <input type="text" nbInput fullWidth placeholder="Mot de passe..." [(ngModel)]="formData.password">
        </li>
        <li>
          <ParagraphAtom text="Confirmation du mot de passe" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" size="medium" weight="semi-bold"></ParagraphAtom>
          <input type="text" nbInput fullWidth placeholder="Confirmer le mot de passe..." [(ngModel)]="formData.confirmPassword">
        </li>
        <li>
          <button nbButton fullWidth status="primary" [disabled]="!isSamePassword()" (click)="editPassword()">Sauvegarder</button>
        </li>
      </ul>
    </div>
  </div>
</div>
